<template>
  <section class="container-fluid">
    <div class="row vh-100">
      <div
        class="d-none d-md-block col-md-7 banner"
        :style="{ backgroundImage: `url(${bgImg})` }"
      ></div>
      <div class="col-12 col-md-5 d-flex align-items-center">
        <div class="w-100">
          <div class="row">
            <div class="col-11 col-md-8 login">
              <div class="row">
                <app-img
                  class="img-responsive logo mb-3"
                  :src="loadAssetWhiteLabel('logo.svg')"
                  alt="logo"
                />
              </div>

              <div class="text-center mt-4">
                <h6>Recuperação de senha de acesso:</h6>
              </div>
              <form-input
                class="col-12 form-input mb-3"
                label="E-mail"
                v-model="email"
                id="email"
                name="email"
                type="email"
                icon="bi bi-envelope-fill"
              />
              <div class="form-input">
                <button class="secondary" :disabled="loading" @click="doRecoveryAction">
                  {{ !loading ? 'ENVIAR' : '' }}
                  <app-loading v-if="loading" :color="'#fff'" :duration="'2.3s'" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { loadAssetWhiteLabel } from '@composables/utils/loadAsset'
import { useForm } from 'vee-validate'
import { onMounted, ref, provide } from 'vue'
import { useMeta } from 'vue-meta'
import { toast } from 'vue3-toastify'
import * as yup from 'yup'

import formInput from '@/components/form/input.vue'
import appImg from '@/components/shared/img.vue'
import appLoading from '@/components/shared/loading.vue'
import router from '@/router'
import { recoverPassword } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'

export default {
  name: 'ResetPassword',

  components: {
    formInput,
    appLoading,
    appImg
  },

  setup() {
    useMeta({ title: 'Recuperação de senha' })

    const { handleSubmit } = useForm({
      validationSchema: yup.object({
        email: yup.string().required().email()
      })
    })

    const email = ref('')
    const loading = ref(false)

    // Start Load images
    const images = Object.values(
      import.meta.glob('@assets/img/login/\*.jpg', {
        eager: true,
        query: '?url',
        import: 'default'
      })
    )

    const currentImageIndex = ref(0)
    const bgImg = ref('')

    const changeBackgroundImage = () => {
      bgImg.value = images[currentImageIndex.value]
      currentImageIndex.value = (currentImageIndex.value + 1) % images.length
    }
    // End Load images

    const alreadySubmittedOnce = ref(false)
    provide('alreadySubmittedOnce', alreadySubmittedOnce)

    const doRecoveryAction = handleSubmit(() => {
      alreadySubmittedOnce.value = true
      return doRecovery()
    })

    const doRecovery = async () => {
      loading.value = true
      try {
        const data = {
          origin: 'ADMIN',
          email: email.value.trim()
        }
        await recoverPassword(data)
        toast('E-mail de recuperação enviado com sucesso', {
          theme: 'auto',
          type: 'success'
        })
        router.push({ name: 'Login' })
      } catch (e) {
        handleGlobalError(e, 'ERR_RECOVER_PASSWORD')
      } finally {
        loading.value = false
      }
    }

    onMounted(() => {
      changeBackgroundImage()
      currentImageIndex.value + 1
      setInterval(changeBackgroundImage, 10000)
    })

    return {
      email,
      loadAssetWhiteLabel,
      loading,
      bgImg,
      doRecoveryAction
    }
  }
}
</script>
