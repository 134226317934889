<template>
  <div :class="{ valid: isValid, invalid: !isValid }" class="password-rule">
    <i :class="iconClass"></i>
    <span>{{ label }}</span>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'

export default {
  name: 'PasswordRule',
  props: {
    regex: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    password: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const isValid = ref(false)

    const regex = computed(() => new RegExp(props.regex))

    watch(
      () => props.password,
      (newPassword) => {
        isValid.value = regex.value.test(newPassword)
      },
      { immediate: true }
    )

    const iconClass = computed(() =>
      isValid.value ? 'bi bi-check-circle-fill text-success' : 'bi bi-x-circle-fill text-danger'
    )

    return {
      isValid,
      iconClass
    }
  }
}
</script>

<style scoped>
.password-rule {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.password-rule i {
  margin-right: 8px;
}

.password-rule.valid i {
  color: green;
}

.password-rule.invalid i {
  color: red;
}
</style>
