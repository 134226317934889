<template>
  <div>
    <app-title v-if="editModeEnabled" label="DOCUMENTAÇÃO" />

    <app-import-document
      v-if="hasInputAllowed() && editModeEnabled"
      id="docs"
      name="docus"
      class="col-12"
      @fileSelected="handleFileSelected"
      accept="image/*, .pdf, .word"
      :loading="loadingUpload"
    />

    <app-list-document
      v-if="editModeEnabled"
      class="col-12"
      :uploadedFileUrls="uploadedFileUrls"
      :typeFile="typeFile"
      @update-documents="handleDocumentUpdate"
      @file-removed="removeFile"
    />

    <app-title v-if="editModeEnabled && principalAmountValue > 10000" label="CONTRATO DE CÂMBIO" />

    <app-docusign-list :contract="docusignExchangeContract" title="Contrato de câmbio" />

    <app-import-document
      v-if="hasInputAllowed() && editModeEnabled && principalAmountValue > 10000"
      id="docusign"
      name="docusign"
      class="col-12"
      @fileSelected="handleDocusign"
      accept=".pdf"
      :loading="loadingUpload"
    />
  </div>
</template>

<script>
import appDocusignList from '@/components/shared/docusignList.vue'
import appImportDocument from '@/components/shared/importDocument.vue'
import appListDocument from '@/components/shared/listDocument.vue'
import appTitle from '@/components/shared/title.vue'

export default {
  components: {
    appTitle,
    appImportDocument,
    appListDocument,
    appDocusignList
  },
  props: {
    editModeEnabled: {
      type: Boolean,
      default: false
    },
    hasInputAllowed: {
      type: Function,
      required: true
    },
    loadingUpload: {
      type: Boolean,
      default: false
    },
    principalAmountValue: {
      type: [Number, String],
      default: 0
    },
    docusignExchangeContract: {
      type: Object,
      default: () => ({})
    },
    uploadedFileUrls: {
      type: Array,
      default: () => []
    },
    typeFile: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleFileSelected(files) {
      this.$emit('file-selected', files)
    },
    handleDocusign(files) {
      this.$emit('docusign-selected', files)
    },
    handleDocumentUpdate(documents) {
      this.$emit('document-updated', documents)
    },
    removeFile(index) {
      this.$emit('file-removed', index)
    }
  }
}
</script>
