<script>
import { onMounted, ref, watch } from 'vue'
import { useMeta } from 'vue-meta'

import formModalPos from '@/components/modalPos/formModalPos.vue'
import appFilterSection from '@/components/shared/filterSection.vue'
import appModal from '@/components/shared/modal.vue'
import appTableSection from '@/components/shared/tableSection.vue'
import { useDynamicFilters, getPosOperationFilters } from '@/composables/utils/useFilters'
import { useNoScroll } from '@/composables/utils/useNoScroll'
import { usePaginatedResult } from '@/composables/utils/usePaginatedResult'
import { usePermissions } from '@/composables/utils/usePermissions'
import { useSearchThrottle } from '@/composables/utils/useSearch'
import router from '@/router'
import {
  fetchPosOperations,
  createPosOperation,
  updatePosOperation,
  fetchPosOperation,
  deletePosOperation
} from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'
import { useWss } from '@/stores/wss'

export default {
  name: 'PosOperations',

  components: {
    appFilterSection,
    appTableSection,
    formModalPos,
    appModal
  },

  props: {
    search: String,
    status: String,
    type: String,
    closedAt: String,
    id: String
  },

  setup(props) {
    useMeta({ title: 'Pós-operação' })
    const modalFormIsShown = ref(false)
    const modalConfig = ref({})

    const searchValue = ref(props.search)
    const natureTypeValue = ref(props.type)
    const pendencyValue = ref(props.status)
    const closingValue = ref(props.closedAt)
    const idEditOperation = ref(props.id)

    const valuesToWatch = [natureTypeValue, pendencyValue, closingValue]

    const permissions = usePermissions()

    useSearchThrottle(searchValue, () => {
      resetAndUpdateResult(true)
    })

    const { setNoScroll } = useNoScroll()

    const tableHeaders = [
      { key: 'clientName', label: 'Cliente', type: 'text' },
      { key: 'backOfficeUserId', label: 'Back Office', type: 'backOfficeUserId' },
      {
        key: 'principalAmount',
        label: 'Valor',
        type: 'currency',
        locale: 'pt-BR',
        maximumFractionDigits: 2
      },
      { key: 'type', label: 'Tipo', type: 'operationType' },
      { key: 'status', label: 'Pendência', type: 'pendencyType' },
      {
        key: 'commercialQuote',
        label: 'COT. COM.',
        type: 'quotation',
        locale: 'pt-BR',
        maximumFractionDigits: 4
      },
      { key: 'spread', label: 'Spread', type: 'spread' },
      {
        key: 'clientQuote',
        label: 'COT. CLIEN.',
        type: 'quotation',
        locale: 'pt-BR',
        maximumFractionDigits: 4
      },
      {
        key: 'createdAt',
        label: 'Fechamento',
        type: 'date',
        format: 'dd/MM/yyyy HH:mm',
        timeZoneOffset: -3
      },
      {
        key: 'actions',
        label: 'Ações',
        type: 'actions',
        options: [
          { action: 'edit', label: 'Editar', class: 'edit', emitEvent: 'edit' },
          {
            action: 'delete',
            label: 'Deletar',
            class: 'delete',
            emitEvent: 'delete',
            isVisible: permissions.canDeleteData()
          }
        ]
      }
    ]

    const fetchFunction = (config) => fetchPosOperations(config)

    const resetAndUpdateResult = (shouldReset) => {
      if (shouldReset) resetResult()

      const config = {
        page: currentPage.value,
        search: searchValue.value,
        status: pendencyValue.value,
        type: natureTypeValue.value,
        closedAt: closingValue.value
      }

      router.push({ name: 'PosOperations', query: { ...config, page: undefined } })
      fetchResult(config)
    }

    const {
      resultItems: operations,
      loading,
      isLastPage,
      fetchResult,
      resetResult,
      currentPage
    } = usePaginatedResult(fetchFunction, resetAndUpdateResult, valuesToWatch)

    const wssStore = useWss()

    watch(
      [() => wssStore.getNewPreOperation.id, () => wssStore.getNewPosOperation.id],
      ([newPreVal, newPostVal], [oldPreVal, oldPostVal]) => {
        if (newPreVal !== oldPreVal || newPostVal !== oldPostVal) {
          resetAndUpdateResult(true)
        }
      }
    )

    onMounted(() => {
      if (idEditOperation.value) {
        let item = {
          id: idEditOperation.value
        }
        handleEdit(item)
      }
    })

    const handleDelete = async (item) => {
      modalConfig.value = {
        origin: 'delete-target-tax',
        title: 'Você tem certeza?',
        icon: 'bi bi-exclamation-square-fill',
        description: 'Se você remover esta oferta, ela ficará como pós-operação para o cliente',
        showButtons: true,
        type: 'warning',
        submitButtonText: 'Confirmar',
        backButtonText: 'Cancelar',
        id: item.id
      }
      modalFormIsShown.value = true
    }

    const loadingModal = ref(false)

    const handleDeletePosOperation = async (item) => {
      try {
        loadingModal.value = true
        const body = [item.id]
        await deletePosOperation(body)
        operations.value = operations.value.filter((op) => op.id !== item.id)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        modalFormIsShown.value = false
        loadingModal.value = false
        setNoScroll(false)
      }
    }

    const handleConfirm = (item) => {
      if (item.origin === 'delete-target-tax') {
        handleDeletePosOperation(item)
      }
    }

    const title = ref('')
    const modalEnabled = ref(false)
    const editModeEnabled = ref(false)
    const loadingButton = ref(false)
    const initialFormData = ref({})

    const newPosOperation = () => {
      title.value = 'Nova Pós-operação'
      modalEnabled.value = true
    }

    const handleFormCreation = async (formData) => {
      if (!editModeEnabled.value) {
        handleSubmitOperation(formData)
      } else {
        handleUpdateOperation(formData)
      }
    }

    const handleSubmitOperation = async (formData) => {
      try {
        loadingButton.value = true
        await createPosOperation(formData)
        handleFormClose()
        resetAndUpdateResult(true)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loadingButton.value = false
        setNoScroll(false)
      }
    }

    const handleUpdateOperation = async (updatedForm) => {
      try {
        loadingButton.value = true
        await updatePosOperation(updatedForm)
        handleFormClose()
        resetAndUpdateResult(true)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loadingButton.value = false
        setNoScroll(false)
      }
    }

    const handleFormClose = () => {
      initialFormData.value = {}
      editModeEnabled.value = false
      modalEnabled.value = false

      const queryParams = {}
      router.push({ name: 'PosOperations', query: queryParams })
    }

    const handleEdit = async (item) => {
      try {
        let response = await fetchPosOperation(item.id)
        title.value = 'Editar Pós-operação'
        initialFormData.value = {
          ...response.data.payload
        }

        editModeEnabled.value = true
        modalEnabled.value = true

        const config = {
          id: item.id
        }

        const queryParams = { ...config }
        router.push({ name: 'PosOperations', query: queryParams })
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const filterSchema = getPosOperationFilters()
    const externalRefs = { searchValue, pendencyValue, natureTypeValue, closingValue }
    const { formValues, updateFormValues } = useDynamicFilters(filterSchema, externalRefs)

    return {
      permissions,

      tableHeaders,
      operations,
      loading,

      modalFormIsShown,
      loadingModal,
      modalConfig,
      handleDelete,
      handleConfirm,

      isLastPage,

      newPosOperation,
      modalEnabled,
      title,
      initialFormData,
      loadingButton,

      handleEdit,

      handleFormClose,
      handleFormCreation,

      filterSchema,
      formValues,
      updateFormValues
    }
  }
}
</script>

<template>
  <div>
    <app-modal
      v-if="modalFormIsShown"
      :modalConfig="modalConfig"
      :loading="loadingModal"
      @submit="handleConfirm"
      @onClose="modalFormIsShown = false"
    />

    <form-modal-pos
      v-if="modalEnabled"
      :title="title"
      @onClose="handleFormClose"
      :loading="loadingButton"
      :initialFormData="initialFormData"
      @onSubmitted="handleFormCreation"
    />

    <div class="row title">
      <h2 class="col">Pós-operação</h2>
      <div class="col-12 col-md-3 form-input">
        <button v-if="permissions.canCreatedData()" class="secondary" @click="newPosOperation()">
          <i class="bi bi-node-plus-fill"></i>
          Criar Pós-operação
        </button>
      </div>
    </div>

    <app-filter-section
      :filterSchema="filterSchema"
      :formValues="formValues"
      @update:formValues="updateFormValues"
    />

    <app-table-section
      :items="operations"
      :headers="tableHeaders"
      :loading="loading"
      :isLastPage="isLastPage && !loading"
      :eventHandlers="{
        edit: handleEdit,
        delete: handleDelete
      }"
    />
  </div>
</template>
