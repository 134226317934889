<script>
import { onMounted, ref, watch } from 'vue'
import { useMeta } from 'vue-meta'

import formModalClient from '@/components/modalClient/formModalClient.vue'
import formModalOnboarding from '@/components/modalClient/formModalOnboarding.vue'
import appFilterSection from '@/components/shared/filterSection.vue'
import appModal from '@/components/shared/modal.vue'
import appTableSection from '@/components/shared/tableSection.vue'
import { useDynamicFilters, getClientFilters } from '@/composables/utils/useFilters'
import { useNoScroll } from '@/composables/utils/useNoScroll'
import { usePaginatedResult } from '@/composables/utils/usePaginatedResult'
import { usePermissions } from '@/composables/utils/usePermissions'
import { useSearchThrottle } from '@/composables/utils/useSearch'
import router from '@/router'
import {
  fetchClients,
  fetchClient,
  updateClient,
  deleteClient,
  fetchClientOnboarding,
  updateClientOnboarding,
  updateDocsOnboarding,
  updateRegistrationForm
} from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'
import { useWss } from '@/stores/wss'

export default {
  name: 'appClients',

  components: {
    appFilterSection,
    appTableSection,
    formModalClient,
    formModalOnboarding,
    appModal
  },

  props: {
    search: String,
    clientId: String,
    documentId: String,
    onboardingStatus: String,
    onboardedAt: String,
    orderBy: String,
    id: String,
    idOnboarding: String
  },

  setup(props) {
    useMeta({ title: 'Clients' })
    const modalFormIsShown = ref(false)
    const modalConfig = ref({})

    const searchValue = ref(props.search)
    const clientIdValue = ref(props.clientId)
    const documentIdValue = ref(props.documentId)
    const onboardingStatusValue = ref(props.onboardingStatus)
    const openingValue = ref(props.onboardedAt)

    const idEditClient = ref(props.id)
    const idEditOnboarding = ref(props.idOnboarding)

    const permissions = usePermissions()

    const orderValue = ref(props.orderBy ? props.orderBy : 'NAME')
    const lastClosedOperationNewerThanValue = ref(props.lastClosedOperationNewerThan)
    const institutionValue = ref(props.institution)
    const clientTypeValue = ref(props.clientType)

    const valuesToWatch = [
      onboardingStatusValue,
      openingValue,
      orderValue,
      lastClosedOperationNewerThanValue,
      institutionValue,
      clientTypeValue
    ]

    useSearchThrottle(searchValue, () => {
      resetAndUpdateResult(true)
    })

    useSearchThrottle(documentIdValue, () => {
      resetAndUpdateResult(true)
    })

    const { setNoScroll } = useNoScroll()

    const tableHeaders = [
      { key: 'clientName', label: 'Cliente', type: 'text' },
      { key: 'clientType', label: 'Tipo do cliente', type: 'text' },
      { key: 'onboardedAt', label: 'Data de abertura', type: 'date', format: 'dd/MM/yyyy HH:mm', timeZoneOffset: -3 },
      { key: 'onboardingStatus', label: 'Status', type: 'onboardingStatusType' },
      {
        key: 'actions',
        label: 'Ações',
        type: 'actions',
        options: [
          {
            action: 'editOnboarding',
            label: 'Editar Onboarding',
            color: '#d0af00',
            emitEvent: 'emitEditOnboarding'
          },
          { action: 'edit', label: 'Editar', class: 'edit', emitEvent: 'edit' },
          {
            action: 'delete',
            label: 'Deletar',
            class: 'delete',
            emitEvent: 'delete',
            isVisible: permissions.canDeleteData()
          }
        ]
      }
    ]

    const fetchFunction = (config) => fetchClients(config)

    const resetAndUpdateResult = (shouldReset) => {
      if (shouldReset) resetResult()

      const config = {
        page: currentPage.value,
        search: searchValue.value,
        documentId: documentIdValue.value ? documentIdValue.value.replace(/[.\s/-]/g, '') : null,
        onboardingStatus: onboardingStatusValue.value,
        onboardedAt: openingValue.value,
        orderBy: orderValue.value,
        lastClosedOperationNewerThan: lastClosedOperationNewerThanValue.value,
        institution: institutionValue.value,
        clientType: clientTypeValue.value
      }

      router.push({ name: 'Clients', query: { ...config, page: undefined } })
      fetchResult(config)
    }

    const {
      resultItems: clients,
      loading,
      isLastPage,
      fetchResult,
      resetResult,
      currentPage,
      totalCount
    } = usePaginatedResult(fetchFunction, resetAndUpdateResult, valuesToWatch)

    const wssStore = useWss()

    watch(
      () => wssStore.getNewClient.id,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          resetAndUpdateResult(true)
        }
      }
    )

    onMounted(() => {
      if (idEditClient.value) {
        let item = {
          clientId: idEditClient.value
        }
        handleEdit(item)
      } else if (idEditOnboarding.value) {
        let item = {
          clientId: idEditOnboarding.value
        }
        handleEditOnboarding(item)
      }
    })

    const title = ref('')
    const modalEnabled = ref(false)
    const modalEnabledOnboarding = ref(false)

    const editModeEnabled = ref(false)
    const loadingButton = ref(false)
    const initialFormData = ref({})

    const newClient = () => {
      title.value = 'Novo Cliente'
      modalEnabled.value = true
    }

    const handleFormCreation = async (formData) => {
      if (!editModeEnabled.value) {
        if (modalEnabled.value) handleSubmitClient(formData)
      } else {
        if (modalEnabled.value) handleUpdateClient(formData)
        if (modalEnabledOnboarding.value) handleUpdateClientOnboarding(formData)
      }
    }

    const handleSubmitClient = async (formData) => {
      try {
        loadingButton.value = true
        await updateClient(formData)
        handleFormClose()
        resetAndUpdateResult(true)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loadingButton.value = false
        setNoScroll(false)
      }
    }

    const handleUpdateClient = async (updatedForm) => {
      try {
        loadingButton.value = true
        await updateClient(updatedForm)
        handleFormClose()
        resetAndUpdateResult(true)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loadingButton.value = false
        setNoScroll(false)
      }
    }

    const handleUpdateClientOnboarding = async (updatedForm) => {
      try {
        loadingButton.value = true
        const id = updatedForm.clientId
        await updateClientOnboarding(id, updatedForm)

        if (updatedForm?.docs?.length > 0) {
          await handleUpdateDocs(id, updatedForm.docs)
        }

        if (updatedForm?.registrationForm != null) {
          await handleUpdateRegistrationForm(id, updatedForm.registrationForm)
        }

        handleFormClose()
        resetAndUpdateResult(true)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loadingButton.value = false
        setNoScroll(false)
      }
    }

    const handleUpdateDocs = async (id, docs) => {
      try {
        await updateDocsOnboarding(id, docs)
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const handleUpdateRegistrationForm = async (id, registrationForm) => {
      try {
        await updateRegistrationForm(id, registrationForm)
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const handleFormClose = () => {
      initialFormData.value = {}
      editModeEnabled.value = false
      modalEnabled.value = false
      modalEnabledOnboarding.value = false

      const queryParams = {}
      router.push({ name: 'Clients', query: queryParams })
    }

    const handleEdit = async (item) => {
      try {
        let response = await fetchClient(item.clientId)
        title.value = 'Editar Cliente'
        initialFormData.value = {
          ...response.data.payload
        }

        editModeEnabled.value = true
        modalEnabled.value = true

        const config = {
          id: item.clientId
        }

        const queryParams = { ...config }
        router.push({ name: 'Clients', query: queryParams })
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const handleEditOnboarding = async (item) => {
      try {
        let response = await fetchClientOnboarding(item.clientId)
        title.value = 'Editar Onboarding'
        initialFormData.value = {
          ...response.data.payload
        }

        editModeEnabled.value = true
        modalEnabledOnboarding.value = true

        const config = {
          idOnboarding: item.clientId
        }

        const queryParams = { ...config }
        router.push({ name: 'Clients', query: queryParams })
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const handleDelete = async (item) => {
      modalConfig.value = {
        origin: 'delete-client',
        title: 'Você tem certeza?',
        icon: 'bi bi-exclamation-square-fill',
        description: 'Após essa ação, você ira remover todos os dados relacionados ao cliente',
        showButtons: true,
        type: 'warning',
        submitButtonText: 'Confirmar',
        backButtonText: 'Cancelar',
        id: item.clientId
      }
      modalFormIsShown.value = true
    }

    const loadingModal = ref(false)

    const handleDeleteClient = async (item) => {
      try {
        loadingModal.value = true
        await deleteClient(item.id)
        clients.value = clients.value.filter((op) => op.clientId !== item.id)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        modalFormIsShown.value = false
        loadingModal.value = false
      }
    }

    const handleConfirm = (item) => {
      if (item.origin === 'delete-client') {
        handleDeleteClient(item)
      }
    }

    const filterSchema = getClientFilters()
    const externalRefs = {
      searchValue,
      clientIdValue,
      documentIdValue,
      lastClosedOperationNewerThanValue,
      institutionValue,
      clientTypeValue,
      onboardingStatusValue,
      openingValue,
      orderValue
    }
    const { formValues, updateFormValues } = useDynamicFilters(filterSchema, externalRefs)

    return {
      permissions,

      tableHeaders,
      clients,
      loading,

      isLastPage,

      newClient,
      modalEnabled,
      modalEnabledOnboarding,
      title,
      initialFormData,
      totalCount,
      loadingButton,

      handleEdit,
      handleEditOnboarding,

      modalFormIsShown,
      loadingModal,
      modalConfig,
      handleDelete,
      handleConfirm,

      handleFormClose,
      handleFormCreation,

      filterSchema,
      formValues,
      updateFormValues
    }
  }
}
</script>

<template>
  <div>
    <app-modal
      v-if="modalFormIsShown"
      :modalConfig="modalConfig"
      :loading="loadingModal"
      @submit="handleConfirm"
      @onClose="modalFormIsShown = false"
    />

    <form-modal-client
      v-if="modalEnabled"
      :title="title"
      @onClose="handleFormClose"
      :loading="loadingButton"
      :initialFormData="initialFormData"
      @onSubmitted="handleFormCreation"
    />

    <form-modal-onboarding
      v-if="modalEnabledOnboarding"
      :title="title"
      @onClose="handleFormClose"
      :loading="loadingButton"
      :initialFormData="initialFormData"
      @onSubmitted="handleFormCreation"
    />

    <div class="row title">
      <h2 class="col">Clientes</h2>
      <div class="col-12 col-md-3">
        <p class="result"><span>Resultado:</span> {{ totalCount }} <span>clientes</span></p>
      </div>
    </div>

    <app-filter-section
      :filterSchema="filterSchema"
      :formValues="formValues"
      @update:formValues="updateFormValues"
    />

    <app-table-section
      :items="clients"
      :headers="tableHeaders"
      :loading="loading"
      :isLastPage="isLastPage && !loading"
      :eventHandlers="{
        edit: handleEdit,
        emitEditOnboarding: handleEditOnboarding,
        delete: handleDelete
      }"
    />
  </div>
</template>
