import { ref } from 'vue'

import { useInstitution } from './useInstitution'

export const useTopicNotifications = () => {
  const { fetchInstitutionOptions } = useInstitution()
  const institutionOptions = fetchInstitutionOptions({ includeAll: false })

  const topicNotificationsType = ref({
    default: 'Production',
    whitelabel: 'Whitelabel',
    staging: 'Staging'
  })

  institutionOptions.forEach((option) => {
    if (option.key && option.isApp) {
      topicNotificationsType.value[option.key.toLowerCase()] = option.name
    }
  })

  const fetchTopicNotificationsOptions = () => {
    return Object.keys(topicNotificationsType.value).map((key) => ({
      name: topicNotificationsType.value[key],
      key: key
    }))
  }

  return {
    topicNotificationsType,
    fetchTopicNotificationsOptions
  }
}
