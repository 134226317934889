<script>
import { ref, onMounted } from 'vue'
import { toast } from 'vue3-toastify'

import appLoading from '@/components/shared/loading.vue'

export default {
  name: 'appImportDocument',

  components: {
    appLoading
  },

  props: {
    className: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: 'image/*'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const fileInputRef = ref(null)
    const selectedFiles = ref([])
    const active = ref(false)

    const browseFile = () => {
      if (fileInputRef.value) {
        fileInputRef.value.click()
      }
    }

    const toggleActive = () => {
      active.value = !active.value
    }

    const handleFileChange = (event) => {
      toggleActive()
      const files = event.target.files || event.dataTransfer.files

      const acceptedTypes = props.accept.split(',').map((type) => type.trim())

      const validFiles = Array.from(files).filter((file) => {
        return acceptedTypes.some((type) => {
          if (type === 'image/*') {
            return file.type.startsWith('image/')
          }
          return file.type === type || file.name.toLowerCase().endsWith(type.toLowerCase())
        })
      })

      if (validFiles.length > 0) {
        selectedFiles.value.push(...validFiles)
        emit('file-selected', validFiles)
      } else {
        toast('Formato de arquivo não permitido. Por favor, selecione um arquivo válido.', {
          theme: 'auto',
          type: 'warning',
          dangerouslyHTMLString: true
        })
      }
    }

    onMounted(() => {
      fileInputRef.value = document.querySelector(`input[type="file"]#${props.id}`)
    })

    return {
      selectedFiles,
      browseFile,
      handleFileChange,
      fileInputRef,
      active,
      toggleActive
    }
  }
}
</script>

<template>
  <div>
    <div
      @dragenter.prevent="toggleActive"
      @dragleave.prevent="toggleActive"
      @dragover.prevent
      @drop.prevent="handleFileChange"
      :class="{ 'active-dropzone': active }"
      class="drag-drop"
    >
      <div class="d-flex justify-content-center align-items-center">
        <app-loading v-if="loading" :color="'#fff'" :duration="'2.3s'" />

        <div v-else class="box">
          <i class="bi bi-cloud-arrow-up-fill"></i>
          <p>Arraste e solte os documentos aqui</p>
          <span>OU</span>
          <input
            type="file"
            :id="id"
            :name="name"
            ref="fileInput"
            @change="handleFileChange"
            :accept="accept"
            :multiple="multiple"
            style="display: none"
          />
          <label @click="browseFile">Escolha arquivos</label>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.drag-drop {
  > div {
    margin-top: 10px;
    border: solid 2px var(--fifth-color);
    border-radius: $base-radius-input;
    height: 240px;
    text-align: center;

    .box {
      position: relative;

      i {
        font-size: 62px;
        color: var(--thead-tr-color);
      }

      p {
        font-size: 14px;
        color: var(--thead-tr-color);
      }

      span {
        font-size: 14px;
        color: var(--thead-tr-color);
        display: block;
        margin-bottom: 14px;

        &::before {
          content: '';
          background-color: var(--fifth-color);
          display: block;
          height: 2px;
          width: 40%;
          position: absolute;
          right: 0;
          margin-top: 9px;
        }

        &::after {
          content: '';
          background-color: var(--fifth-color);
          display: block;
          height: 2px;
          width: 40%;
          position: absolute;
          margin-top: -9px;
        }
      }

      label {
        cursor: pointer;
        font-size: 12px;
        padding: 6px 20px;
        border: solid 2px var(--fifth-color);
        border-radius: $base-radius-input;
        color: var(--thead-tr-color);
      }
    }
  }
}

.active-dropzone {
  > div {
    margin-top: 10px;
    border: solid 2px var(--secondary-color);
  }
}
</style>
