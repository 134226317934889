<script>
import { reactive, watch } from 'vue'
import { toast } from 'vue3-toastify'

import formInput from '@/components/form/input.vue'
import appTitle from '@/components/shared/title.vue'
import { handleGlobalError } from '@/services/errorHandler'

export default {
  name: 'appAddress',

  components: {
    appTitle,
    formInput
  },
  props: {
    address: {
      type: Object,
      default: () => ({})
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const addressState = reactive({ ...props.address })

    watch(
      addressState,
      (newAddress) => {
        emit('address', newAddress)
      },
      { deep: true }
    )

    watch(
      () => props.address,
      (newAddress) => {
        Object.assign(addressState, newAddress)
      },
      { immediate: true, deep: true }
    )

    const searchForZIP = async (cepValue) => {
      try {
        const response = await fetch(`https://opencep.com/v1/${cepValue}`)
        const data = await response.json()

        if (data.error) {
          toast('Não foi possível encontrar o endereço pelo CEP, verifique ou digite manualmente', {
            theme: 'auto',
            type: 'warning',
            dangerouslyHTMLString: true
          })
          return
        }

        addressState.street = data.logradouro
        addressState.city = data.localidade
        addressState.neighborhood = data.bairro
        addressState.state = data.uf
      } catch (e) {
        handleGlobalError(e)
      }
    }

    return {
      addressState,
      searchForZIP
    }
  }
}
</script>

<template>
  <div class="row g-2">
    <app-title label=" ENDEREÇO" />

    <form-input
      className="col-12 form-input"
      label="CEP"
      v-model="addressState.cep"
      id="cep"
      name="cep"
      type="text"
      @focusout="searchForZIP(addressState.cep)"
      :isDisabled="isDisabled"
    />

    <form-input
      className="col-12 col-md-8 form-input"
      label="Endereço"
      v-model="addressState.street"
      id="street"
      name="street"
      type="text"
      :isDisabled="isDisabled"
    />

    <form-input
      className="col-12 col-md-4 form-input"
      label="Número"
      v-model="addressState.number"
      id="number"
      name="number"
      type="text"
      :isDisabled="isDisabled"
    />

    <form-input
      className="col-12 col-md-4 form-input"
      label="Cidade"
      v-model="addressState.city"
      id="city"
      name="city"
      type="text"
      :isDisabled="isDisabled"
    />

    <form-input
      className="col-12 col-md-4 form-input"
      label="Bairro"
      v-model="addressState.neighborhood"
      id="neighborhood"
      name="neighborhood"
      type="text"
      :isDisabled="isDisabled"
    />

    <form-input
      className="col-12 col-md-4 form-input"
      label="Estado"
      v-model="addressState.state"
      id="state"
      name="state"
      type="text"
      :isDisabled="isDisabled"
    />

    <form-input
      className="col-12 form-input"
      label="Complemento"
      v-model="addressState.complement"
      id="complement"
      name="complement"
      type="text"
      :isDisabled="isDisabled"
    />
  </div>
</template>
