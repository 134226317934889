<script>
import { onMounted, ref } from 'vue'
import { useMeta } from 'vue-meta'

import formModalPos from '@/components/modalPos/formModalPos.vue'
import appFilterSection from '@/components/shared/filterSection.vue'
import appTableSection from '@/components/shared/tableSection.vue'
import { useDynamicFilters, getCompletedOperationFilters } from '@/composables/utils/useFilters'
import { useNoScroll } from '@/composables/utils/useNoScroll'
import { usePaginatedResult } from '@/composables/utils/usePaginatedResult'
import { useSearchThrottle } from '@/composables/utils/useSearch'
import router from '@/router'
import { fetchCompletedOperations, fetchPosOperation, updatePosOperation } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'

export default {
  name: 'appFinished',

  components: {
    appFilterSection,
    appTableSection,
    formModalPos
  },

  props: {
    search: String,
    type: String,
    closedAt: String,
    id: String
  },

  setup(props) {
    useMeta({ title: 'Finalizadas' })

    const searchValue = ref(props.search)
    const natureTypeValue = ref(props.type)
    const closingValue = ref(props.closedAt)
    const idEditOperation = ref(props.id)

    const valuesToWatch = [natureTypeValue, closingValue]

    useSearchThrottle(searchValue, () => {
      resetAndUpdateResult(true)
    })

    const { setNoScroll } = useNoScroll()

    const tableHeaders = [
      { key: 'clientName', label: 'Cliente', type: 'text' },
      {
        key: 'closedAt',
        label: 'Fechamento',
        type: 'date',
        format: 'dd/MM/yyyy HH:mm',
        timeZoneOffset: -3
      },
      {
        key: 'principalAmount',
        label: 'Valor',
        type: 'currency',
        locale: 'pt-BR',
        maximumFractionDigits: 2
      },
      { key: 'type', label: 'Tipo', type: 'operationType' },
      {
        key: 'commercialQuote',
        label: 'DOL COM',
        type: 'quotation',
        locale: 'pt-BR',
        maximumFractionDigits: 4
      },
      {
        key: 'clientQuote',
        label: 'COT TH',
        type: 'quotation',
        locale: 'pt-BR',
        maximumFractionDigits: 4
      },
      {
        key: 'actions',
        label: 'Ações',
        type: 'actions',
        options: [{ action: 'edit', label: 'Editar', class: 'edit', emitEvent: 'edit' }]
      }
    ]

    const fetchFunction = (config) => fetchCompletedOperations(config)

    const resetAndUpdateResult = (shouldReset) => {
      if (shouldReset) resetResult()

      const config = {
        page: currentPage.value,
        search: searchValue.value,
        type: natureTypeValue.value,
        closedAt: closingValue.value
      }

      router.push({ name: 'CompletedOperations', query: { ...config, page: undefined } })
      fetchResult(config)
    }

    const {
      resultItems: operations,
      loading,
      isLastPage,
      fetchResult,
      resetResult,
      currentPage
    } = usePaginatedResult(fetchFunction, resetAndUpdateResult, valuesToWatch)

    onMounted(() => {
      if (idEditOperation.value) {
        let item = {
          id: idEditOperation.value
        }
        handleEdit(item)
      }
    })

    const title = ref('')
    const modalEnabled = ref(false)
    const editModeEnabled = ref(false)
    const loadingButton = ref(false)
    const initialFormData = ref({})

    const handleFormCreation = async (formData) => {
      handleUpdateOperation(formData)
    }

    const handleUpdateOperation = async (updatedForm) => {
      try {
        loadingButton.value = true
        await updatePosOperation(updatedForm)
        handleFormClose()
        resetAndUpdateResult(true)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loadingButton.value = false
        setNoScroll(false)
      }
    }

    const handleFormClose = () => {
      initialFormData.value = {}
      editModeEnabled.value = false
      modalEnabled.value = false

      const queryParams = {}
      router.push({ name: 'CompletedOperations', query: queryParams })
    }

    const handleEdit = async (item) => {
      try {
        let response = await fetchPosOperation(item.id)
        title.value = 'Editar Pós-operação'
        initialFormData.value = {
          ...response.data.payload
        }

        editModeEnabled.value = true
        modalEnabled.value = true

        const config = {
          id: item.id
        }

        const queryParams = { ...config }
        router.push({ name: 'CompletedOperations', query: queryParams })
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const filterSchema = getCompletedOperationFilters()
    const externalRefs = { searchValue, natureTypeValue, closingValue }
    const { formValues, updateFormValues } = useDynamicFilters(filterSchema, externalRefs)

    return {
      tableHeaders,
      operations,
      loading,

      isLastPage,

      modalEnabled,
      title,
      initialFormData,
      loadingButton,

      handleEdit,

      handleFormClose,
      handleFormCreation,

      filterSchema,
      formValues,
      updateFormValues
    }
  }
}
</script>

<template>
  <div>
    <form-modal-pos
      v-if="modalEnabled"
      :title="title"
      @onClose="handleFormClose"
      :loading="loadingButton"
      :initialFormData="initialFormData"
      @onSubmitted="handleFormCreation"
    />

    <div class="row title">
      <h2 class="col">Finalizadas</h2>
    </div>

    <app-filter-section
      :filterSchema="filterSchema"
      :formValues="formValues"
      @update:formValues="updateFormValues"
    />

    <app-table-section
      :items="operations"
      :headers="tableHeaders"
      :loading="loading"
      :isLastPage="isLastPage && !loading"
      :eventHandlers="{
        edit: handleEdit
      }"
    />
  </div>
</template>
