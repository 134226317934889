<template>
  <svg
    width="97.57"
    height="18"
    viewBox="0 0 170 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M162.145 21.3005C159.665 21.3005 157.855 20.6705 156.715 19.4105C155.595 18.1305 155.035 16.2905 155.035 13.8905C155.035 11.5105 155.605 9.68047 156.745 8.40047C157.885 7.12047 159.685 6.48047 162.145 6.48047C164.605 6.48047 166.405 7.12047 167.545 8.40047C168.685 9.68047 169.255 11.5105 169.255 13.8905C169.255 16.2905 168.695 18.1305 167.575 19.4105C166.455 20.6705 164.645 21.3005 162.145 21.3005ZM162.145 19.2005C163.845 19.2005 165.045 18.7805 165.745 17.9405C166.445 17.0805 166.795 15.7305 166.795 13.8905C166.795 12.0505 166.435 10.7105 165.715 9.87047C165.015 9.01047 163.825 8.58047 162.145 8.58047C160.465 8.58047 159.265 9.01047 158.545 9.87047C157.845 10.7105 157.495 12.0505 157.495 13.8905C157.495 15.7305 157.845 17.0805 158.545 17.9405C159.245 18.7805 160.445 19.2005 162.145 19.2005Z"
      fill="#39E980"
    />
    <path
      d="M147.493 3.4502V1.2002H150.223V3.4502H147.493ZM147.733 21.0002V8.7302H145.543L145.783 6.7802H150.193V21.0002H147.733Z"
      fill="#39E980"
    />
    <path
      d="M135.503 21.3003C134.443 21.3003 133.513 21.1603 132.713 20.8803C131.933 20.5803 131.213 20.1203 130.553 19.5003L130.373 21.0003H128.273V0.780273H130.733V7.83027C132.033 6.93027 133.613 6.48027 135.473 6.48027C137.493 6.48027 139.073 7.06027 140.213 8.22027C141.353 9.36027 141.923 11.2103 141.923 13.7703C141.923 16.3503 141.353 18.2503 140.213 19.4703C139.073 20.6903 137.503 21.3003 135.503 21.3003ZM134.873 19.3203C136.533 19.3203 137.713 18.8603 138.413 17.9403C139.113 17.0203 139.463 15.6303 139.463 13.7703C139.463 11.8303 139.083 10.4603 138.323 9.66027C137.583 8.86027 136.413 8.46027 134.813 8.46027C133.953 8.46027 133.183 8.59027 132.503 8.85027C131.823 9.09027 131.233 9.43027 130.733 9.87027V17.6103C131.273 18.1903 131.893 18.6203 132.593 18.9003C133.293 19.1803 134.053 19.3203 134.873 19.3203Z"
      fill="#39E980"
    />
    <path
      d="M101.41 21.0005V6.78047H103.63L103.78 8.40047C104.54 7.80047 105.34 7.33047 106.18 6.99047C107.04 6.65047 107.96 6.48047 108.94 6.48047C110 6.48047 110.82 6.65047 111.4 6.99047C112 7.33047 112.44 7.81047 112.72 8.43047C113.4 7.87047 114.18 7.41047 115.06 7.05047C115.94 6.67047 116.99 6.48047 118.21 6.48047C119.83 6.48047 121.03 6.90047 121.81 7.74047C122.61 8.56047 123.01 9.83047 123.01 11.5505V21.0005H120.58V11.7905C120.58 10.5305 120.31 9.66047 119.77 9.18047C119.25 8.70047 118.4 8.46047 117.22 8.46047C116.44 8.46047 115.69 8.59047 114.97 8.85047C114.27 9.11047 113.67 9.51047 113.17 10.0505C113.29 10.5505 113.35 11.1305 113.35 11.7905V21.0005H111.04V11.8505C111.04 10.6505 110.84 9.79047 110.44 9.27047C110.04 8.73047 109.25 8.46047 108.07 8.46047C107.27 8.46047 106.5 8.64047 105.76 9.00047C105.04 9.34047 104.4 9.77047 103.84 10.2905V21.0005H101.41Z"
      fill="#39E980"
    />
    <path
      d="M85.8989 5.34039L89.3489 0.900391H91.3289L94.7789 5.34039H92.6489L90.3389 2.28039L88.0289 5.34039H85.8989ZM88.3889 21.3004C87.4889 21.3004 86.6589 21.1404 85.8989 20.8204C85.1389 20.5004 84.5289 20.0304 84.0689 19.4104C83.6089 18.7704 83.3789 17.9704 83.3789 17.0104C83.3789 15.7104 83.8089 14.6504 84.6689 13.8304C85.5289 12.9904 86.9289 12.5704 88.8689 12.5704H93.8189V11.7004C93.8189 10.9204 93.7089 10.3004 93.4889 9.84039C93.2889 9.38039 92.8989 9.05039 92.3189 8.85039C91.7389 8.65039 90.8889 8.55039 89.7689 8.55039C88.9089 8.55039 88.0789 8.62039 87.2789 8.76039C86.4789 8.90039 85.7289 9.09039 85.0289 9.33039V7.29039C85.6689 7.05039 86.4189 6.86039 87.2789 6.72039C88.1589 6.56039 89.0989 6.48039 90.0989 6.48039C92.1189 6.48039 93.6289 6.89039 94.6289 7.71039C95.6489 8.53039 96.1589 9.86039 96.1589 11.7004V21.0004H94.0889L93.9089 19.3804C93.2889 20.0204 92.5389 20.5004 91.6589 20.8204C90.7789 21.1404 89.6889 21.3004 88.3889 21.3004ZM89.0189 19.2904C90.0589 19.2904 90.9789 19.1104 91.7789 18.7504C92.5789 18.3704 93.2589 17.8604 93.8189 17.2204V14.4604H88.9289C87.8089 14.4604 87.0089 14.6704 86.5289 15.0904C86.0489 15.5104 85.8089 16.1504 85.8089 17.0104C85.8089 17.8704 86.0989 18.4704 86.6789 18.8104C87.2589 19.1304 88.0389 19.2904 89.0189 19.2904Z"
      fill="#39E980"
    />
    <path
      d="M75.6403 21.3005C73.1603 21.3005 71.3203 20.6605 70.1203 19.3805C68.9203 18.0805 68.3203 16.2505 68.3203 13.8905C68.3203 11.3905 69.0003 9.53047 70.3603 8.31047C71.7203 7.09047 73.5503 6.48047 75.8503 6.48047C76.7903 6.48047 77.5703 6.55047 78.1903 6.69047C78.8103 6.81047 79.4103 7.01047 79.9903 7.29047V9.24047C78.9103 8.72047 77.6803 8.46047 76.3003 8.46047C74.5403 8.46047 73.1803 8.88047 72.2203 9.72047C71.2603 10.5405 70.7803 11.9305 70.7803 13.8905C70.7803 15.7705 71.2003 17.1505 72.0403 18.0305C72.8803 18.8905 74.2703 19.3205 76.2103 19.3205C77.5903 19.3205 78.8603 19.0605 80.0203 18.5405V20.5205C79.4003 20.7805 78.7303 20.9705 78.0103 21.0905C77.3103 21.2305 76.5203 21.3005 75.6403 21.3005Z"
      fill="#39E980"
    />
    <path
      d="M54.3603 21.3005C53.4603 21.3005 52.6203 21.1405 51.8403 20.8205C51.0803 20.5005 50.4703 20.0005 50.0103 19.3205C49.5503 18.6405 49.3203 17.7805 49.3203 16.7405C49.3203 15.2405 49.8203 14.0805 50.8203 13.2605C51.8203 12.4205 53.3303 12.0005 55.3503 12.0005H59.0403V11.7305C59.0403 11.1705 58.8103 10.7605 58.3503 10.5005C57.8903 10.2405 57.0803 10.1105 55.9203 10.1105C54.2803 10.1105 52.6603 10.3605 51.0603 10.8605V7.47047C51.7803 7.19047 52.6803 6.96047 53.7603 6.78047C54.8403 6.58047 55.9403 6.48047 57.0603 6.48047C59.3203 6.48047 61.1103 6.94047 62.4303 7.86047C63.7503 8.78047 64.4103 10.2405 64.4103 12.2405V21.0005H59.4903L59.2203 19.8605C58.7203 20.3005 58.0703 20.6505 57.2703 20.9105C56.4903 21.1705 55.5203 21.3005 54.3603 21.3005ZM56.2803 18.0005C56.9203 18.0005 57.4603 17.8905 57.9003 17.6705C58.3603 17.4505 58.7403 17.1805 59.0403 16.8605V15.0905H56.3103C55.0703 15.0905 54.4503 15.5805 54.4503 16.5605C54.4503 17.5205 55.0603 18.0005 56.2803 18.0005Z"
      :fill="logoColor"
    />
    <path
      d="M39.9247 4.88957V0.80957H46.0147V4.88957H39.9247ZM40.3747 20.9996V10.8896H38.3047L38.7847 6.77957H46.0147V20.9996H40.3747Z"
      :fill="logoColor"
    />
    <path
      d="M24.7905 21.3003C23.1305 21.3003 21.8305 20.8303 20.8905 19.8903C19.9505 18.9503 19.4805 17.5703 19.4805 15.7503V6.78027H25.1205V15.3003C25.1205 16.5403 25.7505 17.1603 27.0105 17.1603C27.4705 17.1603 27.9205 17.0603 28.3605 16.8603C28.8005 16.6603 29.1505 16.4103 29.4105 16.1103V6.78027H35.0505V21.0003H29.7705L29.6205 19.9203C29.0605 20.3203 28.3705 20.6503 27.5505 20.9103C26.7505 21.1703 25.8305 21.3003 24.7905 21.3003Z"
      :fill="logoColor"
    />
    <path
      d="M7.59 26.2805C6.89 26.2805 6.13 26.2505 5.31 26.1905C4.49 26.1505 3.71 26.0705 2.97 25.9505C2.25 25.8305 1.65 25.6905 1.17 25.5305V21.9305C1.71 22.1105 2.33 22.2605 3.03 22.3805C3.75 22.5005 4.48 22.5905 5.22 22.6505C5.96 22.7105 6.64 22.7405 7.26 22.7405C8.32 22.7405 9.15 22.6805 9.75 22.5605C10.37 22.4605 10.68 22.2705 10.68 21.9905C10.68 21.7505 10.58 21.5905 10.38 21.5105C10.18 21.4505 9.8 21.4205 9.24 21.4205H5.49C4.03 21.4205 2.9 21.1905 2.1 20.7305C1.32 20.2505 0.93 19.5405 0.93 18.6005C0.93 17.2805 1.66 16.3605 3.12 15.8405C2.3 15.4205 1.7 14.8905 1.32 14.2505C0.94 13.6105 0.75 12.8005 0.75 11.8205C0.75 9.98047 1.37 8.63047 2.61 7.77047C3.87 6.91047 5.68 6.48047 8.04 6.48047C8.54 6.48047 9.1 6.52047 9.72 6.60047C10.34 6.66047 10.79 6.72047 11.07 6.78047H16.65L16.53 10.2005H14.64C15 10.6605 15.18 11.2205 15.18 11.8805C15.18 13.3405 14.75 14.5005 13.89 15.3605C13.05 16.2005 11.72 16.6205 9.9 16.6205C9.64 16.6205 9.36 16.6105 9.06 16.5905C8.76 16.5505 8.48 16.5205 8.22 16.5005C7.78 16.5405 7.4 16.6205 7.08 16.7405C6.76 16.8605 6.6 17.0605 6.6 17.3405C6.6 17.5405 6.69 17.6805 6.87 17.7605C7.05 17.8205 7.3 17.8505 7.62 17.8505H10.89C12.35 17.8505 13.5 18.1905 14.34 18.8705C15.18 19.5505 15.6 20.5505 15.6 21.8705C15.6 23.2705 14.95 24.3505 13.65 25.1105C12.35 25.8905 10.33 26.2805 7.59 26.2805ZM8.07 13.5305C8.95 13.5305 9.56 13.3805 9.9 13.0805C10.24 12.7805 10.41 12.2805 10.41 11.5805C10.41 10.8805 10.24 10.3705 9.9 10.0505C9.56 9.73047 8.95 9.57047 8.07 9.57047C7.25 9.57047 6.65 9.73047 6.27 10.0505C5.91 10.3505 5.73 10.8605 5.73 11.5805C5.73 12.2605 5.9 12.7605 6.24 13.0805C6.6 13.3805 7.21 13.5305 8.07 13.5305Z"
      :fill="logoColor"
    />
  </svg>
</template>

<script>
import { computed } from 'vue'

import { theme } from '@/composables/utils/useGlobalConfig'
import { useConfig } from '@/stores/config.js'

export default {
  name: 'logoGuiaCambio',
  setup() {
    const config = useConfig()

    const currentTheme = computed(() => config.theme)
    const logoColor = computed(() => (currentTheme.value === theme.DARK ? '#FFFFFF' : '#000000'))

    return {
      currentTheme,
      logoColor
    }
  }
}
</script>
