<template>
  <div class="box-content d-flex justify-content-end">
    <div :class="['back', { active: contentActive }]" @click="close()"></div>
    <div :class="['content', { active: contentActive }]">
      <h4>{{ title }}</h4>

      <div class="col-12">
        <div class="row">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
import { useMeta } from 'vue-meta'

import { useNoScroll } from '@/composables/utils/useNoScroll'

export default {
  name: 'formModal',

  props: {
    title: {
      type: String,
      default: ''
    }
  },

  setup(props, { emit }) {
    useMeta({ title: props.title })

    const contentActive = ref(false)
    const scroll = ref(false)
    const { setNoScroll } = useNoScroll()

    watch(scroll, (newValue) => {
      setNoScroll(newValue)
    })

    const close = () => {
      contentActive.value = false
      scroll.value = false
      setTimeout(() => {
        emit('onClose', false)
      }, 300)
    }

    const openModal = () => {
      scroll.value = true
      setTimeout(() => {
        contentActive.value = true
      }, 100)
    }

    onMounted(() => {
      openModal()
    })

    return {
      close,
      contentActive
    }
  }
}
</script>
