<script>
import { useField } from 'vee-validate'
import { inject, ref } from 'vue'

export default {
  name: 'appTextarea',
  props: {
    className: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 5
    },
    rules: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const { value, errorMessage, handleChange } = useField(() => props.name, props.rules, {
      syncVModel: true,
      validateOnValueUpdate: false
    })

    const alreadySubmittedOnce = inject('alreadySubmittedOnce', ref(false))

    const handleChangeValidation = (value) => {
      handleChange(value, alreadySubmittedOnce.value)
    }

    return {
      value,
      errorMessage,
      handleChangeValidation
    }
  }
}
</script>

<template>
  <div :class="className">
    <label :for="id">{{ label }}</label>
    <i v-if="icon" :class="icon"></i>
    <textarea
      :id="id"
      :name="name"
      :rows="rows"
      v-model="value"
      @input="handleChangeValidation"
      :placeholder="placeholder"
      :disabled="isDisabled"
      :class="{ invalid: isInvalid || !!errorMessage }"
    ></textarea>
  </div>
</template>
