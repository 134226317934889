<script>
import { onMounted, ref } from 'vue'
import { useMeta } from 'vue-meta'

import formModalBroker from '@/components/modalBroker/formModalBroker.vue'
import appTableSection from '@/components/shared/tableSection.vue'
import { useNoScroll } from '@/composables/utils/useNoScroll'
import { fetchBrokers, putUpdateBroker } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'

export default {
  name: 'appBrokers',

  components: {
    appTableSection,
    formModalBroker
  },

  setup() {
    useMeta({ title: 'Brokers' })

    const brokers = ref([])
    const loading = ref(false)

    const { setNoScroll } = useNoScroll()

    const tableHeaders = [
      { key: 'name', label: 'Nome', type: 'text' },
      {
        key: 'actions',
        label: 'Ações',
        type: 'actions',
        options: [{ action: 'edit', label: 'Editar', class: 'edit', emitEvent: 'edit' }]
      }
    ]

    const fetchOperations = async () => {
      loading.value = true
      try {
        const response = await fetchBrokers()
        brokers.value = response.data.payload
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loading.value = false
      }
    }

    const resetOperations = () => {
      brokers.value = []
    }

    const updateOperationsWithFilters = () => {
      fetchOperations()
    }

    const resetAndUpdateOperations = () => {
      resetOperations()
      updateOperationsWithFilters()
    }

    onMounted(() => {
      fetchOperations()
    })

    const title = ref('')
    const initialFormData = ref({})
    const modalEnabled = ref(false)
    const editModeEnabled = ref(false)
    const loadingButton = ref(false)

    const handleEdit = (item) => {
      title.value = 'Editar broker'
      initialFormData.value = { ...item }

      editModeEnabled.value = true
      modalEnabled.value = true
    }

    const handleUpdateBroker = async (updatedForm) => {
      loadingButton.value = true

      try {
        await putUpdateBroker(updatedForm.id, updatedForm)
        modalEnabled.value = false
        resetAndUpdateOperations()
      } catch (e) {
        handleGlobalError(e)
      } finally {
        initialFormData.value = {}
        loadingButton.value = false
        editModeEnabled.value = false
        setNoScroll(false)
      }
    }

    const handleFormClose = () => {
      initialFormData.value = {}
      editModeEnabled.value = false
      modalEnabled.value = false
    }

    return {
      tableHeaders,
      brokers,
      loading,

      modalEnabled,
      title,
      handleUpdateBroker,
      handleFormClose,
      initialFormData,
      loadingButton,

      handleEdit
    }
  }
}
</script>

<template>
  <div>
    <form-modal-broker
      v-if="modalEnabled"
      :title="title"
      @onClose="handleFormClose"
      :loading="loadingButton"
      :initialFormData="initialFormData"
      @onSubmitted="handleUpdateBroker"
    />

    <div class="row title">
      <h2 class="col">Instituição financeira</h2>
    </div>

    <app-table-section
      :items="brokers"
      :headers="tableHeaders"
      :loading="loading"
      :eventHandlers="{
        edit: handleEdit
      }"
    />
  </div>
</template>
