<template>
  <template v-if="item.label || item.submenu">
    <div v-if="item.label" class="row separator mt-5">
      <label>{{ item.label }}</label>
    </div>
    <div :class="item.isAnchorToBase ? `row mt-auto` : `row mt-3`">
      <ul>
        <router-link
          v-for="subItem in item.submenu"
          :key="subItem.label"
          v-slot="{ navigate }"
          custom
          :to="subItem.path"
        >
          <li
            v-if="!subItem.isAccordion && (subItem.isVisible === undefined || subItem.isVisible)"
            @click="navigate"
            @keypress.enter="navigate"
            :class="{ active: $route.path === subItem.path }"
          >
            <i :class="subItem.icon"></i>
            {{ subItem.label }}
          </li>

          <div
            v-else-if="
              (subItem.isVisible === undefined || subItem.isVisible) &&
              hasVisibleSubmenu(subItem.submenu)
            "
            class="accordion"
          >
            <div class="accordion-item">
              <li
                class="accordion-click collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseOne"
              >
                <i :class="subItem.icon"></i>
                {{ subItem.label }}
              </li>
              <div
                id="panelsStayOpen-collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <router-link
                  v-for="subItemAccordion in subItem.submenu"
                  :key="subItemAccordion.label"
                  v-slot="{ navigate }"
                  custom
                  :to="subItemAccordion.path"
                >
                  <li
                    v-if="subItemAccordion.isVisible === undefined || subItemAccordion.isVisible"
                    @click="navigate"
                    @keypress.enter="navigate"
                    :class="{ active: $route.path === subItemAccordion.path }"
                  >
                    <i :class="subItemAccordion.icon"></i>
                    {{ subItemAccordion.label }}
                  </li>
                </router-link>
              </div>
            </div>
          </div>
        </router-link>
      </ul>
    </div>
  </template>
</template>

<script>
export default {
  name: 'menuItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  setup() {
    const toggle = (item) => {
      item.expanded = !item.expanded
    }

    const hasVisibleSubmenu = (submenu) => {
      return submenu.some((subItem) => subItem.isVisible === undefined || subItem.isVisible)
    }

    return {
      toggle,
      hasVisibleSubmenu
    }
  }
}
</script>

<style lang="scss" scoped>
li {
  list-style-type: none;
}
</style>
