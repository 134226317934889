<template>
  <img :class="className" :src="src" :alt="alt" />
</template>
<script>
export default {
  name: 'appImg',
  props: {
    className: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped></style>
