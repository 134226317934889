<template>
  <div class="row pb-5">
    <div class="col-12 form-table">
      <app-table
        v-if="items.length > 0"
        :headers="headers"
        :items="items"
        v-bind="extraProps"
        v-on="eventHandlers"
      />

      <div v-if="isLastPage && !loading" class="d-flex align-items-center mt-5">
        <h6 class="col mb-0 text-center">Isso é tudo!</h6>
      </div>

      <div v-if="items.length === 0 && !loading" class="d-flex align-items-center placeholder">
        <h4 class="col mb-0 text-center">Nada encontrado, tente pesquisar novamente.</h4>
      </div>

      <app-loading v-if="loading" class="pt-5 mt-5 mx-auto" :duration="'2.3s'" />
    </div>
  </div>
</template>

<script>
import appLoading from '@/components/shared/loading.vue'
import appTable from '@/components/shared/table.vue'

export default {
  name: 'tableSection',

  components: {
    appTable,
    appLoading
  },
  props: {
    items: { type: Array, required: true },
    headers: { type: Array, required: true },
    loading: { type: Boolean, default: false },
    isLastPage: { type: Boolean, default: false },
    extraProps: { type: Object, default: () => ({}) },
    eventHandlers: { type: Object, default: () => ({}) }
  }
}
</script>
