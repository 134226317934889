<script>
import formMoney from '@/components/form/money.vue'
import formSwitch from '@/components/form/switch.vue'
import appTitle from '@/components/shared/title.vue'
import { moneyFormat } from '@/composables/utils/moneyConfig'

export default {
  name: 'appFinancialInstitution',

  components: {
    appTitle,
    formSwitch,
    formMoney
  },
  props: {
    financialInstitution: {
      type: Object,
      default: () => {}
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const updateFinancialInstitution = () => {
      emit('financialInstitution', props.financialInstitution)
    }

    return {
      updateFinancialInstitution,
      moneyFormat
    }
  }
}
</script>
<template>
  <div>
    <div class="row g-2 mb-4">
      <app-title label="INSTITUIÇÕES FINANCEIRAS" />
      <div class="col-12 financial-institution">
        <div class="row g-1" v-for="(item, index) in financialInstitution" :key="index">
          <form-switch
            className="col-12 form-input"
            :description="item.institution"
            v-model="item.allowed"
            :id="item.institution"
            :name="item.institution"
            @change="updateFinancialInstitution"
            :isDisabled="isDisabled"
          />

          <form-money
            v-if="item.allowed"
            className="col-12 col-md-6 form-input"
            v-model="item.limitUSD"
            label="Limite anual disponível"
            :id="'limitUSD_' + index"
            :name="'limitUSD_' + index"
            :config="moneyFormat('USD ', '.', ',', 2)"
            @input="updateFinancialInstitution"
            :isDisabled="isDisabled"
          />

          <form-money
            v-if="item.allowed"
            className="col-12 col-md-6 form-input"
            v-model="item.usedLimitUSD"
            label="Limite anual utilizado"
            :id="'usedLimitUSD_' + index"
            :name="'usedLimitUSD_' + index"
            :config="moneyFormat('USD ', '.', ',', 2)"
            :isDisabled="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.financial-institution {
  > div {
    background-color: hsla(0, 0%, 0%, 0.03);
    margin-top: 5px;
    border-radius: 9px;
  }
}
</style>
