<template>
  <div>
    <div v-for="(bank, index) in banks" :key="index" class="row g-2">
      <form-select
        class="col-12 form-input"
        label="Moeda"
        v-model="bank.currencyType"
        :items="currencyOptions"
        :id="`banks[${index}].currencyType`"
        :name="`banks[${index}].currencyType`"
        :isDisabled="isDisabled"
      />
      <form-input
        className="col-12 form-input"
        label="Banco intermediário"
        v-model="bank.intermediaryBankName"
        :id="`banks[${index}].intermediaryBankName`"
        :name="`banks[${index}].intermediaryBankName`"
        type="text"
        :isDisabled="isDisabled"
      />
      <form-input
        className="col-12 form-input"
        label="Cidade do banco intermediário"
        v-model="bank.intermediaryBankCityCountry"
        :id="`banks[${index}].intermediaryBankCityCountry`"
        :name="`banks[${index}].intermediaryBankCityCountry`"
        type="text"
        :isDisabled="isDisabled"
      />
      <form-input
        className="col-12 form-input"
        label="SWIFT do banco intermediário"
        v-model="bank.intermediaryBankSwift"
        :id="`banks[${index}].intermediaryBankSwift`"
        :name="`banks[${index}].intermediaryBankSwift`"
        type="text"
        :isDisabled="isDisabled"
      />
      <form-input
        className="col-12 form-input"
        label="Banco beneficiário"
        v-model="bank.beneficiaryBankName"
        :id="`banks[${index}].beneficiaryBankName`"
        :name="`banks[${index}].beneficiaryBankName`"
        type="text"
        :isDisabled="isDisabled"
      />
      <form-input
        className="col-12 form-input"
        label="País beneficiário"
        v-model="bank.beneficiaryBankCityCountry"
        :id="`banks[${index}].beneficiaryBankCityCountry`"
        :name="`banks[${index}].beneficiaryBankCityCountry`"
        type="text"
        :isDisabled="isDisabled"
      />
      <form-input
        className="col-12 form-input"
        label="SWIFT do banco beneficiário"
        v-model="bank.beneficiaryBankSwift"
        :id="`banks[${index}].beneficiaryBankSwift`"
        :name="`banks[${index}].beneficiaryBankSwift`"
        type="text"
        :isDisabled="isDisabled"
      />
      <form-input
        className="col-12 form-input"
        label="Nome do banco beneficiário"
        v-model="bank.beneficiaryBankSwiftName"
        :id="`banks[${index}].beneficiaryBankSwiftName`"
        :name="`banks[${index}].beneficiaryBankSwiftName`"
        type="text"
        :isDisabled="isDisabled"
      />
      <form-input
        className="col-12 form-input"
        label="Conta do banco beneficiário"
        v-model="bank.beneficiaryBankAccount"
        :id="`banks[${index}].beneficiaryBankAccount`"
        :name="`banks[${index}].beneficiaryBankAccount`"
        type="text"
        :isDisabled="isDisabled"
      />

      <form-button
        class="col-12 form-input mt-0"
        buttonColor="fifth mt-0 mb-4"
        title="Remover Banco"
        :isDisabled="isDisabled"
        @click.prevent="removeBank(index)"
      />
    </div>

    <div v-if="banks.length > 0" class="row">
      <div class="text-center">
        <img class="line-svg" :src="loadAsset('icon/svg/line_add.svg')" />
      </div>
    </div>
    <form-button
      class="col-12 form-input mt-0"
      buttonColor="secondary mt-1"
      title="Adicionar Banco intermediário"
      :isDisabled="isDisabled"
      @click.prevent="addNewBank"
    />
  </div>
</template>

<script>
import { loadAsset } from '@composables/utils/loadAsset.ts'
import { reactive } from 'vue'

import formButton from '@/components/form/button.vue'
import formInput from '@/components/form/input.vue'
import formSelect from '@/components/form/select.vue'
import { useCurrencyOptions } from '@/composables/utils/useCurrencyOptions'

export default {
  name: 'UserBankAccounts',
  components: {
    formInput,
    formSelect,
    formButton
  },
  props: {
    bankAccounts: {
      type: Array,
      default: () => []
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const banks = reactive([...props.bankAccounts])
    const { fetchCurrencyOptions } = useCurrencyOptions()
    const currencyOptions = fetchCurrencyOptions()

    function addNewBank() {
      banks.push({
        currencyType: '',
        intermediaryBankName: '',
        intermediaryBankCityCountry: '',
        intermediaryBankSwift: '',
        beneficiaryBankName: '',
        beneficiaryBankCityCountry: '',
        beneficiaryBankSwift: '',
        beneficiaryBankSwiftName: '',
        beneficiaryBankAccount: ''
      })
      emit('bankAccounts', banks)
    }

    function removeBank(index) {
      banks.splice(index, 1)
      emit('bankAccounts', banks)
    }

    return {
      banks,
      addNewBank,
      removeBank,
      loadAsset,
      currencyOptions
    }
  }
}
</script>

<style lang="scss">
.form-input {
  button {
    &.fifth {
      color: var(--sixth-color);
      border-color: var(--fifth-color);
      background-color: var(--fifth-color);

      &:hover,
      &:focus {
        color: var(--sixth-color);
        border-color: var(--fifth-color);
        background-color: var(--fifth-color);
      }
    }
  }
}
</style>
