import { createRouter, createWebHistory } from 'vue-router'

import routes from './routes'

import { useAuthToken } from '@/stores/authToken.js'

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = useAuthToken().getToken
  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ path: '/login', query: { redirect: to.fullPath } })
  } else if (to.path === '/login' && isAuthenticated) {
    next('dashboard')
  } else {
    next()
  }
})

export default router
