import { ref } from 'vue'

export const useOnboardingStatus = () => {
  const onboardingStatusType = ref({
    '': { name: 'Todos', color: '' },
    APPROVED: { name: 'Aprovado', color: '#48cf93' },
    PENDING: { name: 'Pendente', color: '#e24949' },
    WAITING_APPROVED: { name: 'Atualizado pelo cliente', color: '#FD7132' },
    WAITING_DOCS: { name: 'Aguardando Docs.', color: '#fd7132' }
  })

  const fetchOnboardingStatusOptions = () => {
    return Object.keys(onboardingStatusType.value).map((key) => ({
      name: onboardingStatusType.value[key].name,
      key: key,
      color: onboardingStatusType.value[key].color
    }))
  }

  return {
    onboardingStatusType,
    fetchOnboardingStatusOptions
  }
}
