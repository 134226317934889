<script>
import { hasInputAllowed } from '@composables/utils/useWhitelabelService.js'
import { Collapse } from 'bootstrap'
import { format, parseISO, getDate, getMonth, getYear, formatISO } from 'date-fns'
import { useForm } from 'vee-validate'
import { onMounted, ref, provide, watch, computed } from 'vue'
import { useMeta } from 'vue-meta'
import { toast } from 'vue3-toastify'
import * as yup from 'yup'

import formButton from '@/components/form/button.vue'
import formDate from '@/components/form/date.vue'
import formInput from '@/components/form/input.vue'
import formMoney from '@/components/form/money.vue'
import formSelect from '@/components/form/select.vue'
import formSwitch from '@/components/form/switch.vue'
import appAddress from '@/components/modalClient/_address.vue'
import appBanks from '@/components/modalClient/_banks.vue'
import documentsManager from '@/components/modalClient/_documentsManager.vue'
import appPartners from '@/components/modalClient/_partners.vue'
import appDocusignList from '@/components/shared/docusignList.vue'
import appLoading from '@/components/shared/loading.vue'
import appModal from '@/components/shared/modal.vue'
import appTitle from '@/components/shared/title.vue'
import { moneyFormat } from '@/composables/utils/moneyConfig'
import {
  clientTypes,
  maritalStatusOptions,
  legalNatureOptions,
  documentTypeOptions,
  institutionTypeOptions,
  genderOptions
} from '@/composables/utils/useGlobalConfig'
import { useNoScroll } from '@/composables/utils/useNoScroll'
import { useOnboardingStatus } from '@/composables/utils/useOnboardingStatus'
import { fetchRegistrationForm, createDocsSignature } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'
import { fetchExtractExatoCompany } from '@/services/ms'

export default {
  name: 'formModalOnboarding',

  components: {
    appPartners,
    appBanks,
    appTitle,
    formInput,
    formSwitch,
    formMoney,
    formSelect,
    formButton,
    documentsManager,
    formDate,
    appAddress,
    appModal,
    appDocusignList,
    appLoading
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    initialFormData: {
      type: Object,
      default: () => ({})
    }
  },

  setup(props, { emit }) {
    useMeta({ title: props.title })

    const contentActive = ref(false)
    const scroll = ref(false)
    const { setNoScroll } = useNoScroll()
    const docusignRegistrationForms = ref([])

    const { onboardingStatusType } = useOnboardingStatus()

    watch(scroll, (newValue) => {
      setNoScroll(newValue)
    })

    const { handleSubmit } = useForm({
      validationSchema: yup.object({
        clientName: yup.string().required(),
        name: yup.string().test(function (value) {
          if (clientType.value === 'PJ') {
            return !!value
          }
          return true
        }),
        email: yup.string().email().required(),
        banks: yup.array().of(
          yup.object().shape({
            code: yup.string().required(),
            agency: yup.string().required(),
            account: yup.string().required()
          })
        ),
        partners: yup
          .array()
          .of(
            yup.object().shape({
              name: yup.string().required(),
              phone: yup.string().required(),
              email: yup.string().email().required(),
              percentage: yup.string().required().min(0).max(100)
            })
          )
          .test((partners = []) => {
            if (clientType.value === 'PJ') {
              const totalPercentage = partners.reduce(
                (total, partner) => total + parseFloat(partner.percentage || 0),
                0
              )
              return totalPercentage === 100
            }
            return true
          })
      })
    })

    const alreadySubmittedOnce = ref(false)
    provide('alreadySubmittedOnce', alreadySubmittedOnce)

    const onSubmitAction = () => {
      alreadySubmittedOnce.value = true
      return onSubmit()
    }

    const onSubmit = handleSubmit(async () => {
      if (editModeEnabled.value !== true) {
        const body = {}
        emit('onSubmitted', body)
      } else {
        let registrationForm = undefined
        if (isOpenedSignature.value === true) {
          registrationForm = {
            documentType: documentTypeValue.value,
            identificationDocumentNumber: identificationDocumentNumberValue.value,

            fantasyName: fantasyNameValue.value,
            socialCapital: socialCapitalValue.value,
            netWorth: netWorthValue.value,
            annualBilling: annualBillingValue.value,
            legalNature: legalNatureValue.value,
            companyOpening: companyOpeningValue.value,
            cnae: cnaeValue.value,
            lineOfActivity: lineOfActivityValue.value,
            activitySector: activitySectorValue.value,
            observation: observationValue.value,
            isAddressDeclaration: isAddressDeclarationValue.value
          }
        }

        const body = {
          ...props.initialFormData,
          clientName: clientNameValue.value,
          phone: phoneValue.value,
          canOperate: canOperateValue.value,

          name: nameValue.value,
          cityOfBirth: cityOfBirthValue.value,
          stateOfBirth: stateOfBirthValue.value,
          maritalStatus: maritalStatusValue.value,
          profession: professionValue.value,
          pfCompanyName: pfCompanyNameValue.value,
          totalAssets: totalAssetsValue.value,
          averageMonthlyIncome: averageMonthlyIncomeValue.value,
          annualTargetVolume: annualTargetVolumeValue.value,
          spouseName: spouseNameValue.value,
          spouseCpf: spouseCpfValue.value,
          hasOtherCitizenship: hasOtherCitizenshipValue.value,
          otherCitizenship: otherCitizenshipValue.value,

          isPep: isPepValue.value,
          pepName: pepNameValue.value,
          pepCpf: pepCpfValue.value,

          isEuaResidentialTaxCitizen: isEuaResidentialTaxCitizenValue.value,
          businessPartners: businessPartnersValue.value,
          banks: bankAccountsValue.value,

          docs: docs.value,

          registrationForm,

          rg: rgValue.value,
          documentIssueDate: documentIssueDateValue.value,
          cpf: cpfValue.value,
          dateOfBirth: dateOfBirthValue.value,
          gender: genderValue.value,
          affiliation: affiliationValue.value,
          documentIssuingBody: documentIssuingBody.value,
          documentStateIssuer: documentStateIssuerValue.value,
          documentValidity: documentValidityValue.value,

          cep: addressValue.value.cep,
          street: addressValue.value.street,
          number: addressValue.value.number,
          complement: addressValue.value.complement,
          city: addressValue.value.city,
          neighborhood: addressValue.value.neighborhood,
          state: addressValue.value.state
        }
        emit('onSubmitted', body)
      }
    })

    const close = () => {
      contentActive.value = false
      scroll.value = false
      setTimeout(() => {
        emit('onClose', false)
      }, 300)
    }

    const openModal = () => {
      scroll.value = true
      setTimeout(() => {
        contentActive.value = true
      }, 100)
    }

    onMounted(() => {
      openModal()
    })

    const editModeEnabled = ref(false)

    const clientNameValue = ref('')
    const clientIdValue = ref('')
    const documentIdValue = ref('')
    const phoneValue = ref('')
    const demandValue = ref('')

    const nameValue = ref('')

    const clientType = ref('')
    const emailValue = ref('')

    const cityOfBirthValue = ref('')
    const stateOfBirthValue = ref('')
    const maritalStatusValue = ref('')
    const professionValue = ref('')
    const totalAssetsValue = ref('')
    const hasOtherCitizenshipValue = ref('')
    const otherCitizenshipValue = ref('')
    const pfCompanyNameValue = ref('')
    const averageMonthlyIncomeValue = ref('')
    const annualTargetVolumeValue = ref('')
    const spouseNameValue = ref('')
    const spouseCpfValue = ref('')
    const isPepValue = ref(false)
    const pepNameValue = ref('')
    const pepCpfValue = ref('')
    const isEuaResidentialTaxCitizenValue = ref('')

    const canOperateValue = ref(false)
    const institution = ref('')
    const status = ref('')

    const businessPartnersValue = ref([])
    const bankAccountsValue = ref([])

    const docs = ref([])

    const documentTypeValue = ref('')
    const identificationDocumentNumberValue = ref('')
    const documentIssueDateValue = ref('')
    const documentIssuingBody = ref('')
    const documentStateIssuerValue = ref('')
    const documentValidityValue = ref('')
    const fantasyNameValue = ref('')
    const socialCapitalValue = ref('')
    const netWorthValue = ref('')
    const annualBillingValue = ref('')
    const legalNatureValue = ref('')
    const companyOpeningValue = ref('')
    const cnaeValue = ref('')
    const lineOfActivityValue = ref('')
    const activitySectorValue = ref('')
    const observationValue = ref('')
    const rgValue = ref('')
    const cpfValue = ref('')
    const dateOfBirthValue = ref('')
    const affiliationValue = ref('')
    const isAddressDeclarationValue = ref(false)
    const addressValue = ref({})
    const genderValue = ref('')

    watch(
      () => props.initialFormData,
      async (newFormData) => {
        if (Object.keys(newFormData).length === 0) return
        editModeEnabled.value = true

        clientNameValue.value = newFormData.clientName
        clientIdValue.value = newFormData.clientId
        documentIdValue.value = newFormData.documentId
        clientType.value = newFormData.clientType

        phoneValue.value = newFormData.phone
        demandValue.value = newFormData.demand
        nameValue.value = newFormData.name
        emailValue.value = newFormData.email

        cityOfBirthValue.value = newFormData.cityOfBirth
        stateOfBirthValue.value = newFormData.stateOfBirth
        maritalStatusValue.value = newFormData.maritalStatus
        professionValue.value = newFormData.profession
        totalAssetsValue.value = newFormData.totalAssets
        pfCompanyNameValue.value = newFormData.pfCompanyName
        docusignRegistrationForms.value = newFormData.docusignRegistrationForms

        hasOtherCitizenshipValue.value = newFormData.hasOtherCitizenship
        otherCitizenshipValue.value = newFormData.otherCitizenship

        averageMonthlyIncomeValue.value = Number(newFormData.averageMonthlyIncome).toFixed(2)
        annualTargetVolumeValue.value = Number(newFormData.annualTargetVolume).toFixed(2)
        spouseNameValue.value = newFormData.spouseName
        spouseCpfValue.value = newFormData.spouseCpf

        isPepValue.value = newFormData.isPep
        pepNameValue.value = newFormData.pepName
        pepCpfValue.value = newFormData.pepCpf

        isEuaResidentialTaxCitizenValue.value = newFormData.isEuaResidentialTaxCitizen

        canOperateValue.value = newFormData.canOperate
        socialCapitalValue.value = Number(newFormData.capitalSocial).toFixed(2)

        institution.value = newFormData.institution
        status.value = newFormData.status
        businessPartnersValue.value = newFormData.businessPartners
        bankAccountsValue.value = newFormData.banks

        docs.value = newFormData.docs

        rgValue.value = newFormData.rg
        documentIssueDateValue.value = newFormData.documentIssueDate
        cpfValue.value = newFormData.cpf
        dateOfBirthValue.value = newFormData.dateOfBirth
        genderValue.value = newFormData.gender
        affiliationValue.value = newFormData.affiliation
        documentIssuingBody.value = newFormData.documentIssuingBody
        documentStateIssuerValue.value = newFormData.documentStateIssuer
        documentValidityValue.value = newFormData.documentValidity

        addressValue.value = {
          cep: newFormData.cep,
          street: newFormData.street,
          number: newFormData.number,
          complement: newFormData.complement,
          city: newFormData.city,
          neighborhood: newFormData.neighborhood,
          state: newFormData.state
        }
      },
      { immediate: true }
    )

    const loadingSignature = ref(false)
    const collapseDocsPreview = ref(null)
    const isOpenedSignature = ref(false)

    const openSignature = async () => {
      if (isOpenedSignature.value) {
        return
      }
      loadingSignature.value = true
      try {
        const result = await fetchRegistrationForm(clientIdValue.value)
        const registrationForm = result.data.payload
        documentTypeValue.value = registrationForm.documentType
        identificationDocumentNumberValue.value = registrationForm.identificationDocumentNumber
        fantasyNameValue.value = registrationForm.fantasyName
        socialCapitalValue.value = socialCapitalValue.value
          ? socialCapitalValue.value
          : registrationForm.socialCapital
        netWorthValue.value = registrationForm.netWorth
        annualBillingValue.value = registrationForm.annualBilling
        legalNatureValue.value = registrationForm.legalNature
        companyOpeningValue.value = registrationForm.companyOpening
        cnaeValue.value = registrationForm.cnae
        lineOfActivityValue.value = registrationForm.lineOfActivity
        activitySectorValue.value = registrationForm.activitySector
        observationValue.value = registrationForm.observation
        isAddressDeclarationValue.value = registrationForm.isAddressDeclaration

        const collapseInstance = new Collapse(collapseDocsPreview.value, {
          toggle: false
        })
        collapseInstance.show()
        isOpenedSignature.value = true
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loadingSignature.value = false
      }
    }

    const filteredInstitutionTypeOptions = computed(() => {
      if (clientType.value === 'PJ') {
        return institutionTypeOptions.value.filter((option) => option.key === 'BS2')
      } else {
        return institutionTypeOptions.value
      }
    })

    const modalFormIsShown = ref(false)
    const loadingModal = ref(false)
    const modalConfig = ref({})

    const onSubmitSignature = () => {
      modalConfig.value = {
        origin: 'signature-client',
        title: 'Você tem certeza?',
        description:
          'Após essa ação, você enviará um email com documentos para assinatura ao cliente',
        showButtons: true,
        type: 'warning',
        submitButtonText: 'Confirmar',
        backButtonText: 'Cancelar',
        formSchema: [
          {
            type: 'input',
            label: 'Nome',
            model: 'name',
            value: nameValue.value ? nameValue.value : clientNameValue.value
          },
          {
            type: 'input',
            label: 'E-mail',
            model: 'email',
            value: emailValue.value
          },
          {
            type: 'select',
            label: 'Instituição',
            model: 'institution',
            items: filteredInstitutionTypeOptions.value,
            value: ''
          }
        ]
      }
      modalFormIsShown.value = true
    }

    const sendSignature = async (item) => {
      loadingModal.value = true
      const companyOpeningDate = parseISO(companyOpeningValue.value)
      const documentIssueDate = parseISO(documentIssueDateValue.value)
      const dateOfBirthDate = parseISO(dateOfBirthValue.value)
      const partners = Array.isArray(businessPartnersValue.value) ? businessPartnersValue.value : []

      let templateData = {}
      if (item.formData.institution === 'BS2') {
        templateData = {
          // BS2 PJ
          text: {
            nome_fantasia: fantasyNameValue.value,
            razao_social: clientNameValue.value,
            forma_constituicao: legalNatureValue.value,

            observacao: observationValue.value,

            rua_empresa: addressValue.value.street,
            numero_empresa: addressValue.value.number,
            complemento_empresa: addressValue.value.complement,
            bairro_empresa: addressValue.value.neighborhood,
            cidade_empresa: addressValue.value.city,
            uf_empresa: addressValue.value.state,
            cep_empresa: addressValue.value.cep,

            setor_atividade: activitySectorValue.value,
            cnpj: documentIdValue.value.toString(),
            telefone: phoneValue.value,
            email: emailValue.value,
            atividade_principal: lineOfActivityValue.value,
            classificacao_cnae: cnaeValue.value,
            faturamento_bruto_anual: annualBillingValue.value,
            capital_social_integralizado: socialCapitalValue.value,

            data_fundacao_dia: getDate(companyOpeningDate) || '',
            data_fundacao_mes: getMonth(companyOpeningDate) + 1 || '',
            data_fundacao_ano: getYear(companyOpeningDate) || '',

            data_ultima_alteracao_dia: getDate(companyOpeningDate) || '',
            data_ultima_alteracao_mes: getMonth(companyOpeningDate) + 1 || '',
            data_ultima_alteracao_ano: getYear(companyOpeningDate) || '',

            data_eleicao_diretoria_dia: '',
            data_eleicao_diretoria_mes: '',
            data_eleicao_diretoria_ano: '',

            ...Array.from({ length: 5 }).reduce((acc, _, index) => {
              acc[`socio_${index + 1}_nome`] = partners[index]?.name || ''
              acc[`socio_${index + 1}_id`] = partners[index]?.cpf || ''
              acc[`socio_${index + 1}_participacao`] = partners[index]?.percentage || ''
              return acc
            }, {}),

            ...Array.from({ length: 5 }).reduce((acc, _, index) => {
              acc[`diretor_${index + 1}_nome`] = partners[index]?.name || ''
              acc[`diretor_${index + 1}_cpf`] = partners[index]?.cpf || ''
              acc[`diretor_${index + 1}_cargo`] = partners[index]?.name ? 'Sócio' : ''
              return acc
            }, {}),

            // BS2 PF
            nome: nameValue.value,
            cpf:
              (cpfValue.value ? cpfValue.value.toString() : '') ||
              (documentIdValue.value ? documentIdValue.value.toString() : ''),

            rg: rgValue.value,
            data_rg_emissao_dia: getDate(documentIssueDate) || '',
            data_rg_emissao_mes: getMonth(documentIssueDate) || '',
            data_rg_emissao_ano: getYear(documentIssueDate) || '',
            rg_orgao_exp: documentIssuingBody.value,

            data_nascimento_dia: getDate(dateOfBirthDate) || '',
            data_nascimento_mes: getMonth(dateOfBirthDate) + 1 || '',
            data_nascimento_ano: getYear(dateOfBirthDate) || '',

            naturalidade: `${cityOfBirthValue.value}/${stateOfBirthValue.value}`,
            filiacao: affiliationValue.value,

            profissao: professionValue.value,
            renda_mensal: averageMonthlyIncomeValue.value,

            endereco_residencia: `${addressValue.value.street}, ${addressValue.value.number}`,
            bairro_residencia: addressValue.value.neighborhood,
            cidade_residencia: addressValue.value.city,
            uf_residencia: addressValue.value.state,
            cep_residencia: addressValue.value.cep,

            telefone_fixo: '',
            telefone_celular: phoneValue.value,

            conjuge_nome: spouseNameValue.value,
            conjuge_cpf: spouseCpfValue.value
          },
          radio: {
            sexo: genderValue.value,
            estado_civil: maritalStatusOptions.value.find(
              (option) => option.key === maritalStatusValue.value
            ).docusign
          }
        }
      } else {
        templateData = {
          // BRAZA PF
          dropdown: {
            estado_civil: maritalStatusOptions.value.find(
              (option) => option.key === maritalStatusValue.value
            ).docusign,
            tipo_documento: 'Carteira de identidade (RG)'
          },
          text: {
            nome: clientNameValue.value,
            telefone_celular: phoneValue.value,
            email: emailValue.value,

            cpf: documentIdValue.value.toString(),
            numero_documento: rgValue.value,
            rg_uf: documentStateIssuerValue.value,
            naturalidade: `${cityOfBirthValue.value} - ${stateOfBirthValue.value}`,

            data_rg_emissao_dia: getDate(documentIssueDate) || '',
            data_rg_emissao_mes: getMonth(documentIssueDate) || '',
            data_rg_emissao_ano: getYear(documentIssueDate) || '',
            rg_orgao_exp: documentIssuingBody.value,

            rg_validade: documentValidityValue.value
              ? format(parseISO(documentValidityValue.value), 'dd/MM/yyyy')
              : '',

            data_nascimento_dia: getDate(dateOfBirthDate) || '',
            data_nascimento_mes: getMonth(dateOfBirthDate) + 1 || '',
            data_nascimento_ano: getYear(dateOfBirthDate) || '',

            endereco_residencia: addressValue.value.street,
            numero_residencia: addressValue.value.number,
            bairro_residencia: addressValue.value.neighborhood,
            cidade_residencia: addressValue.value.city,
            uf_residencia: addressValue.value.state,
            cep_residencia: addressValue.value.cep,
            complemento_residencia: addressValue.value.complement,
            pais_residencia: 'Brasil',

            profissao: professionValue.value,
            renda_mensal: averageMonthlyIncomeValue.value,
            nome_empresa: pfCompanyNameValue.value,
            patrimonio_total: totalAssetsValue.value,
            outra_cidadania: otherCitizenshipValue.value,

            conjuge_nome: spouseNameValue.value,
            conjuge_cpf: spouseCpfValue.value
          }
        }
      }

      const body = {
        signerName: item.formData.name,
        signerEmail: item.formData.email,
        financialInstitution: item.formData.institution,

        templateData,
        metadata: {
          financialInstitution: item.formData.institution,
          date: formatISO(new Date())
        }
      }

      try {
        const response = await createDocsSignature(body, clientIdValue.value)
        toast('Documento enviado para assinatura', {
          theme: 'auto',
          type: 'success',
          dangerouslyHTMLString: true
        })
        docusignRegistrationForms.value.push({ ...response.data.payload })
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loadingModal.value = false
        modalFormIsShown.value = false
      }
    }

    const handleConfirm = async (item) => {
      if (item.origin === 'signature-client') {
        sendSignature(item)
      } else if (item.origin === 'exato-client') {
        onSubmitExtractExato()
      }
    }

    const mapLegalNature = (nature) => {
      const normalizedNature = nature.toLowerCase()

      const mapping = {
        'sociedade empresária limitada': 'LTDA',
        'sociedade limitada unipessoal': 'SLU',
        'empresário individual': 'EI',
        mei: 'MEI',
        'sociedade anônima': 'SA',
        'sociedade simples': 'Sociedade Simples'
      }

      for (const [key, value] of Object.entries(mapping)) {
        if (normalizedNature.includes(key)) {
          return value
        }
      }

      return null
    }

    const formatCpf = (cpf) => {
      return cpf.padStart(11, '0')
    }

    const mergeBusinessPartners = (existingPartners, newPartners) => {
      const existingPartnersMap = new Map(existingPartners.map((partner) => [partner.cpf, partner]))

      newPartners.forEach((newPartner) => {
        const document = formatCpf(newPartner.document)

        if (existingPartnersMap.has(document)) {
          const existingPartner = existingPartnersMap.get(document)

          existingPartner.name = newPartner.name || existingPartner.name
          existingPartner.cpf = document || existingPartner.cpf
        } else {
          existingPartners.push({
            name: newPartner.name,
            cpf: document,
            phone: '',
            email: '',
            percentage: ''
          })
        }
      })

      return existingPartners
    }

    const onSubmitExato = () => {
      modalConfig.value = {
        origin: 'exato-client',
        title: 'Você tem certeza?',
        description: 'Esta solicitação terá custos, deseja prosseguir?',
        showButtons: true,
        type: 'warning',
        submitButtonText: 'Seguir',
        backButtonText: 'Cancelar'
      }
      modalFormIsShown.value = true
    }

    const onSubmitExtractExato = async () => {
      loadingModal.value = true
      await openSignature()
      try {
        const response = await fetchExtractExatoCompany(clientIdValue.value)
        const responseExtractExato = response.data.payload

        fantasyNameValue.value = responseExtractExato.nomeFantasia
        companyOpeningValue.value = responseExtractExato.createdAt

        addressValue.value = {
          cep: responseExtractExato.address.cep,
          street: responseExtractExato.address.street,
          number: responseExtractExato.address.number,
          complement: responseExtractExato.address.complement,
          city: responseExtractExato.address.city,
          neighborhood: responseExtractExato.address.neighborhood,
          state: responseExtractExato.address.uf
        }

        cnaeValue.value = responseExtractExato.cnae
        activitySectorValue.value = responseExtractExato.cnae_sector
        lineOfActivityValue.value = responseExtractExato.mainActivity

        const mappedNature = mapLegalNature(responseExtractExato.nature)
        if (mappedNature) {
          legalNatureValue.value = mappedNature
        }

        businessPartnersValue.value = mergeBusinessPartners(
          businessPartnersValue.value,
          responseExtractExato.businessPartners
        )

        socialCapitalValue.value = responseExtractExato.capitalSocial
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loadingModal.value = false
        modalFormIsShown.value = false
      }
    }

    return {
      contentActive,

      clientNameValue,
      clientIdValue,
      documentIdValue,
      phoneValue,
      demandValue,

      nameValue,
      emailValue,

      clientType,
      clientTypes,

      canOperateValue,

      cityOfBirthValue,
      stateOfBirthValue,

      maritalStatusValue,
      maritalStatusOptions,
      spouseNameValue,
      spouseCpfValue,

      isPepValue,
      pepNameValue,
      pepCpfValue,

      professionValue,
      totalAssetsValue,
      pfCompanyNameValue,
      averageMonthlyIncomeValue,

      hasOtherCitizenshipValue,
      otherCitizenshipValue,

      annualTargetVolumeValue,
      isEuaResidentialTaxCitizenValue,

      institution,
      status,

      onboardingStatusType,

      moneyFormat,

      businessPartnersValue,
      bankAccountsValue,

      docs,

      editModeEnabled,
      onSubmitAction,
      close,
      hasInputAllowed,

      fantasyNameValue,
      socialCapitalValue,

      documentTypeValue,
      identificationDocumentNumberValue,
      documentIssueDateValue,
      documentIssuingBody,
      documentStateIssuerValue,
      documentValidityValue,

      netWorthValue,
      annualBillingValue,
      legalNatureValue,
      companyOpeningValue,
      cnaeValue,
      lineOfActivityValue,
      activitySectorValue,
      observationValue,
      cpfValue,
      rgValue,
      dateOfBirthValue,
      affiliationValue,
      isAddressDeclarationValue,

      legalNatureOptions,
      documentTypeOptions,
      addressValue,
      onSubmitSignature,

      modalFormIsShown,
      modalConfig,
      loadingModal,
      handleConfirm,

      loadingSignature,
      openSignature,
      collapseDocsPreview,
      isOpenedSignature,
      genderValue,
      genderOptions,

      docusignRegistrationForms,
      onSubmitExato
    }
  }
}
</script>

<template>
  <div class="box-content d-flex justify-content-end">
    <div :class="['back', { active: contentActive }]" @click="close()"></div>
    <div :class="['content', { active: contentActive }]">
      <div class="row">
        <h4 class="col-8">{{ title }}</h4>
        <div v-if="editModeEnabled" class="col-4 text-end">
          <h5>
            <span class="cod">
              <b :style="{ color: onboardingStatusType[status].color }">{{
                onboardingStatusType[status].name
              }}</b>
            </span>
          </h5>
          <p>{{ institution }}</p>
        </div>
      </div>

      <div class="col-12">
        <div class="row g-2">
          <form-input
            className="col-12 form-input"
            label="Nome do cliente"
            v-model="clientNameValue"
            id="clientName"
            name="clientName"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            className="col-12 form-input"
            label="CPF/CNPJ"
            v-model="documentIdValue"
            id="documentId"
            name="documentId"
            type="text"
            :isDisabled="!hasInputAllowed() || editModeEnabled"
          />

          <form-input
            className="col-12 form-input"
            label="Telefone"
            v-model="phoneValue"
            id="phone"
            name="phone"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            className="col-12 form-input"
            label="Demanda de Câmbio informada pelo cliente:"
            v-model="demandValue"
            id="demand"
            name="demand"
            type="text"
            :isDisabled="!hasInputAllowed() || editModeEnabled"
          />

          <form-input
            v-if="clientType === clientTypes.PJ"
            className="col-12 form-input"
            label="Administrador da conta"
            v-model="nameValue"
            id="name"
            name="name"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            v-if="clientType === clientTypes.PJ"
            className="col-12 form-input"
            label="CPF"
            v-model="cpfValue"
            id="cpf"
            name="cpf"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            className="col-12 form-input"
            label="Documento RG"
            v-model="rgValue"
            id="rg"
            name="rg"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-date
            class="col form-input"
            label="Data de emissão do documento"
            v-model="documentIssueDateValue"
            id="documentIssueDate"
            name="documentIssueDate"
            icon="bi bi-calendar-week"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            className="col-12 form-input"
            label="Órgão Emissor"
            v-model="documentIssuingBody"
            id="issuingBody"
            name="issuingBody"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            className="col-12 form-input"
            label="UF de Emissão"
            v-model="documentStateIssuerValue"
            id="documentStateIssuer"
            name="documentStateIssuer"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-date
            class="col-12 form-input"
            label="Validade"
            v-model="documentValidityValue"
            id="documentValidity"
            name="documentValidity"
            icon="bi bi-calendar-week"
            :isDisabled="!hasInputAllowed()"
          />

          <form-date
            class="col-12 form-input"
            label="Data de nascimento"
            v-model="dateOfBirthValue"
            id="dateOfBirth"
            name="dateOfBirth"
            icon="bi bi-calendar-week"
            :isDisabled="!hasInputAllowed()"
          />

          <form-select
            class="col-12 form-input"
            label="Sexo"
            v-model="genderValue"
            :items="genderOptions"
            id="gender"
            name="gender"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            className="col-12 form-input"
            label="Filiação"
            v-model="affiliationValue"
            id="affiliation"
            name="affiliation"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            className="col-12 form-input"
            label="E-mail"
            v-model="emailValue"
            id="email"
            name="email"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            className="col-12 form-input"
            label="Naturalidade - Cidade"
            v-model="cityOfBirthValue"
            id="cityOfBirth"
            name="cityOfBirth"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            className="col-12 form-input"
            label="Naturalidade - Estado"
            v-model="stateOfBirthValue"
            id="stateOfBirth"
            name="stateOfBirth"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-switch
            className="col-12 form-input"
            description="Operar acima de USD 300 mil"
            v-model="canOperateValue"
            id="canOperate"
            name="canOperate"
            :isDisabled="!hasInputAllowed()"
          />

          <form-select
            class="col-12 form-input"
            label="Estado civil"
            v-model="maritalStatusValue"
            :items="maritalStatusOptions"
            id="maritalStatus"
            name="maritalStatus"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            v-if="maritalStatusValue === 'MARRIED' || 'STABLE_UNION'"
            className="col-12 form-input"
            label="Nome - conjugue"
            v-model="spouseNameValue"
            id="spouseName"
            name="spouseName"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            v-if="maritalStatusValue === 'MARRIED' || 'STABLE_UNION'"
            className="col-12 form-input"
            label="CPF - conjugue"
            v-model="spouseCpfValue"
            id="spouseCpf"
            name="spouseCpf"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-switch
            className="col-12 form-input"
            description="Parente politicamente exposto"
            v-model="isPepValue"
            id="isPep"
            name="isPep"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            v-if="isPepValue"
            className="col-12 form-input"
            label="Nome - parente"
            v-model="pepNameValue"
            id="pepName"
            name="pepName"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            v-if="isPepValue"
            className="col-12 form-input"
            label="CPF - parente"
            v-model="pepCpfValue"
            id="pepCpf"
            name="pepCpf"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            className="col-12 form-input"
            label="Profissão"
            v-model="professionValue"
            id="profession"
            name="profession"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            className="col-12 form-input"
            v-model="totalAssetsValue"
            label="Patrimônio total"
            id="totalAssets"
            name="totalAssets"
            :config="moneyFormat('R$ ', '.', ',', 2)"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            className="col-12 form-input"
            label="Empresa em que trabalha"
            v-model="pfCompanyNameValue"
            id="pfCompanyName"
            name="pfCompanyName"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            className="col-12 form-input"
            v-model="averageMonthlyIncomeValue"
            label="Renda média mensal"
            id="averageMonthlyIncome"
            name="averageMonthlyIncome"
            :config="moneyFormat('R$ ', '.', ',', 2)"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            className="col-12 form-input"
            v-model="annualTargetVolumeValue"
            label="Pretensão de câmbio anual"
            id="annualTargetVolume"
            name="annualTargetVolume"
            :config="moneyFormat('USD ', '.', ',', 2)"
            :isDisabled="!hasInputAllowed()"
          />

          <form-switch
            className="col-12 form-input"
            description="Cidadão ou residente fiscal nos EUA"
            v-model="isEuaResidentialTaxCitizenValue"
            id="isEuaResidentialTaxCitizen"
            name="isEuaResidentialTaxCitizen"
            :isDisabled="!hasInputAllowed()"
          />

          <form-switch
            className="col-12 form-input"
            description="Você possui outra cidadania?"
            v-model="hasOtherCitizenshipValue"
            id="hasOtherCitizenship"
            name="hasOtherCitizenship"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            className="col-12 form-input"
            label="Qual cidadania?"
            v-model="otherCitizenshipValue"
            id="otherCitizenship"
            name="otherCitizenship"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <div v-if="docusignRegistrationForms" class="col-12">
            <app-docusign-list
              v-for="form in docusignRegistrationForms"
              :key="form.id"
              :contract="form"
              title="Contrato de câmbio"
            />
          </div>

          <form-button
            v-if="hasInputAllowed()"
            class="col-12 form-input"
            title="Solicitar dados Exato Digital"
            @clicked="onSubmitExato"
          />

          <div class="docs-preview-item">
            <div class="placeholder-results">
              <p @click="openSignature">
                <i class="bi bi-file-earmark-text-fill"></i> Formulário de registro da instituição
                financeira
                <span class="float-end text-end" v-if="!loadingSignature">
                  <i v-if="!isOpenedSignature" class="bi bi-arrow-down-square-fill"></i>
                </span>
                <span class="float-end text-end pe-2" v-else>
                  <app-loading class="mx-auto" :duration="'2.3s'" />
                </span>
              </p>

              <div class="collapse" ref="collapseDocsPreview">
                <div class="row">
                  <form-input
                    v-if="clientType === clientTypes.PJ"
                    className="col-12 form-input"
                    label="Nome Fantasia"
                    v-model="fantasyNameValue"
                    id="fantasyName"
                    name="fantasyName"
                    type="text"
                    :isDisabled="!hasInputAllowed()"
                  />

                  <form-money
                    v-if="clientType === clientTypes.PJ"
                    className="col-12 form-input"
                    v-model="socialCapitalValue"
                    label="Capital Social Integralizado"
                    id="socialCapital"
                    name="socialCapital"
                    :config="moneyFormat('R$ ', '.', ',', 2)"
                    :isDisabled="!hasInputAllowed()"
                  />

                  <form-money
                    v-if="clientType === clientTypes.PJ"
                    className="col-12 form-input"
                    v-model="netWorthValue"
                    label="Patrimônio Líquido Contábil"
                    id="netWorth"
                    name="netWorth"
                    :config="moneyFormat('R$ ', '.', ',', 2)"
                    :isDisabled="!hasInputAllowed()"
                  />

                  <form-money
                    v-if="clientType === clientTypes.PJ"
                    className="col-12 form-input"
                    v-model="annualBillingValue"
                    label="Faturamento Anual"
                    id="annualBilling"
                    name="annualBilling"
                    :config="moneyFormat('R$ ', '.', ',', 2)"
                    :isDisabled="!hasInputAllowed()"
                  />

                  <form-select
                    v-if="clientType === clientTypes.PJ"
                    class="col-12 form-input"
                    label="Natureza Jurídica"
                    v-model="legalNatureValue"
                    :items="legalNatureOptions"
                    id="legalNature"
                    name="legalNature"
                    :isDisabled="!hasInputAllowed()"
                  />

                  <form-date
                    v-if="clientType === clientTypes.PJ"
                    class="col form-input"
                    label="Data de abertura da empresa"
                    v-model="companyOpeningValue"
                    id="companyOpening"
                    name="companyOpening"
                    icon="bi bi-calendar-week"
                    :isDisabled="!hasInputAllowed()"
                  />

                  <form-input
                    v-if="clientType === clientTypes.PJ"
                    className="col-12 form-input"
                    label="N° CNAE"
                    v-model="cnaeValue"
                    id="cnae"
                    name="cnae"
                    type="text"
                    :isDisabled="!hasInputAllowed()"
                  />

                  <form-input
                    v-if="clientType === clientTypes.PJ"
                    className="col-12 form-input"
                    label="Setor de Atividade"
                    v-model="activitySectorValue"
                    id="activitySector"
                    name="activitySector"
                    type="text"
                    :isDisabled="!hasInputAllowed()"
                  />

                  <form-input
                    v-if="clientType === clientTypes.PJ"
                    className="col-12 form-input"
                    label="Ramo de Atividade Principal"
                    v-model="lineOfActivityValue"
                    id="lineOfActivity"
                    name="lineOfActivity"
                    type="text"
                    :isDisabled="!hasInputAllowed()"
                  />

                  <form-input
                    className="col-12 form-input"
                    label="Observação"
                    v-model="observationValue"
                    id="observation"
                    name="observation"
                    type="text"
                    :isDisabled="!hasInputAllowed()"
                  />

                  <div class="col-12">
                    <app-address
                      :address="addressValue"
                      @address="addressValue = $event"
                      :isDisabled="!hasInputAllowed()"
                    />
                  </div>

                  <!-- <form-switch
                    className="col-12 form-input"
                    description="Pedir declaração de endereço"
                    v-model="isAddressDeclarationValue"
                    id="isAddressDeclaration"
                    name="isAddressDeclaration"
                    :isDisabled="!hasInputAllowed()"
                  /> -->

                  <form-button
                    class="col-12 form-input"
                    title="Enviar para assinatura"
                    :loading="loading"
                    :isDisabled="!hasInputAllowed() || loading"
                    @clicked="onSubmitSignature"
                  />
                </div>
              </div>
            </div>
          </div>

          <app-title v-if="clientType === clientTypes.PJ" label="QUADRO SOCIETÁRIO" />
          <app-partners
            v-if="clientType === clientTypes.PJ"
            :businessPartners="businessPartnersValue"
            @businessPartners="businessPartnersValue = $event"
            :isDisabled="!hasInputAllowed()"
          />

          <app-title label="CONTAS BANCÁRIAS" />
          <app-banks
            :bankAccounts="bankAccountsValue"
            @bankAccounts="bankAccountsValue = $event"
            :isDisabled="!hasInputAllowed()"
          />

          <documents-manager
            :clientType="clientType"
            :docs="docs"
            @docs="docs = $event"
            :isDisabled="!hasInputAllowed()"
          />

          <form-button
            class="col-12 form-input"
            title="Enviar"
            :loading="loading"
            :isDisabled="!hasInputAllowed() || loading"
            @clicked="onSubmitAction"
          />
        </div>
      </div>
    </div>
  </div>
  <app-modal
    v-if="modalFormIsShown"
    :modalConfig="modalConfig"
    :loading="loadingModal"
    @submit="handleConfirm"
    @onClose="modalFormIsShown = false"
  />
</template>

<style lang="scss">
.docs-preview-item {
  .placeholder-results {
    color: var(--sixth-color);
    background-color: rgba(225, 225, 225, 0.04);
    border-radius: 9px;
    padding: 15px;
    font-size: 14px;
    line-height: 28px;
    cursor: pointer;

    p {
      color: var(--sixth-color);
      margin-bottom: 0;
    }
  }
}

.box-content {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 999;
  padding: 0;

  .back {
    background-color: rgba(0, 0, 0, 0);
    transition: background 0.36s cubic-bezier(0.32, 0.08, 0.55, 1);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.3s;

    &.active {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  .content {
    background-color: var(--body-background);
    transition: right 0.3s;
    overflow: auto;
    height: 100%;
    width: 44%;
    z-index: 1;
    position: fixed;
    top: 0;
    right: -44%;
    padding: 44px 38px;

    &.active {
      right: 0;
    }

    h4 {
      font-size: 36px;
      font-weight: 500;
    }
  }
}
</style>
