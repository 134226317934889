import { ref } from 'vue'

export const useOperationStatus = () => {
  const statusType = ref({
    '': 'Todos',
    ALLOW: 'Liberado',
    EDITING: 'Em edição',
    UNDER_ANALYSIS: 'Em análise',
    REFUSED: 'Recusado',
    TED_CLIENT: 'Aguardando TED Cliente',
    OUTSTANDING_DOCUMENTS: 'Aguardando Documentação',
    RECEIVED: 'Aguardando Documentação',
    CANCELED: 'Cancelado',
    SIGNED: 'Assinado',
    WAITING_RECEIVEMENT: 'Aguardando Recebimento do Exterior',
    WAITING_ONBOARDING: 'Cadastro em andamento',
    OPEN: 'Aberto',
    CLOSED: 'Fechado'
  })

  const fetchStatusOptions = () => {
    return Object.keys(statusType.value).map((key) => ({
      name: statusType.value[key],
      key: key
    }))
  }

  return {
    statusType,
    fetchStatusOptions
  }
}
