<template>
  <section class="container-fluid">
    <div class="row vh-100">
      <div
        class="d-none d-md-block col-md-7 banner"
        :style="{ backgroundImage: `url(${bgImg})` }"
      ></div>
      <div class="col-12 col-md-5 d-flex align-items-center">
        <div class="w-100">
          <div class="row">
            <div class="col-11 col-md-8 login">
              <div class="row">
                <app-img
                  class="img-responsive logo mb-3"
                  :src="loadAssetWhiteLabel('logo.svg')"
                  alt="logo"
                />
              </div>

              <div class="text-center mt-4">
                <h6>Cadastre sua nova senha de acesso:</h6>
              </div>
              <form-input
                class="col-12 form-input mb-3"
                label="Nova senha"
                id="newPassword"
                name="newPassword"
                type="password"
                icon="bi bi-lock-fill"
                v-model="newPassword"
              />
              <form-input
                class="col-12 form-input mb-3"
                label="Confirmar senha"
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                icon="bi bi-lock-fill"
                v-model="confirmPassword"
              />
              <div class="col-12">
                <div class="py-3 px-4 password-rules">
                  <p>Sua senha precisa ter no mínimo:</p>
                  <password-rule regex=".{8,}" label="8 Caracteres" :password="newPassword" />
                  <password-rule
                    regex="[A-Z]{1,}"
                    label="1 letra maiúscula"
                    :password="newPassword"
                  />
                  <password-rule
                    regex="[a-z]{1,}"
                    label="1 letra minúscula"
                    :password="newPassword"
                  />
                  <password-rule regex="\d{1,}" label="1 Número" :password="newPassword" />
                  <password-rule
                    regex='[!@#$%^&*(),.?":{}|<>]{1,}'
                    label="1 Símbolo"
                    :password="newPassword"
                  />
                </div>
              </div>
              <div class="form-input">
                <button class="secondary" :disabled="loading" @click="doRecoveryAction">
                  {{ !loading ? 'CONFIRMAR' : '' }}
                  <app-loading v-if="loading" :color="'#fff'" :duration="'2.3s'" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { loadAssetWhiteLabel } from '@composables/utils/loadAsset'
import { useForm } from 'vee-validate'
import { onMounted, ref, provide } from 'vue'
import { useMeta } from 'vue-meta'
import { toast } from 'vue3-toastify'
import * as yup from 'yup'

import formInput from '@/components/form/input.vue'
import appImg from '@/components/shared/img.vue'
import appLoading from '@/components/shared/loading.vue'
import router from '@/router'
import { resetPassword } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'
import PasswordRule from '@/views/auth/password/_passwordRule.vue'

export default {
  name: 'ResetPassword',

  components: {
    formInput,
    appLoading,
    appImg,
    PasswordRule
  },

  props: {
    user: String,
    code: String
  },

  setup(props) {
    useMeta({ title: 'Criação de senha' })

    const userId = ref(props.user)
    const verifyCode = ref(props.code)

    const { handleSubmit } = useForm({
      validationSchema: yup.object({
        newPassword: yup
          .string()
          .required()
          .min(8)
          .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/),
        confirmPassword: yup
          .string()
          .oneOf([yup.ref('newPassword'), null])
          .required()
      })
    })

    const newPassword = ref('')
    const confirmPassword = ref('')
    const loading = ref(false)

    // Start Load images
    const images = Object.values(
      import.meta.glob('@assets/img/login/*.jpg', {
        eager: true,
        query: '?url',
        import: 'default'
      })
    )

    const currentImageIndex = ref(0)
    const bgImg = ref('')

    const changeBackgroundImage = () => {
      bgImg.value = images[currentImageIndex.value]
      currentImageIndex.value = (currentImageIndex.value + 1) % images.length
    }
    // End Load images

    const alreadySubmittedOnce = ref(false)
    provide('alreadySubmittedOnce', alreadySubmittedOnce)

    const doRecoveryAction = handleSubmit(() => {
      alreadySubmittedOnce.value = true
      return doRecovery()
    })

    const doRecovery = async () => {
      loading.value = true
      try {
        const data = {
          password: confirmPassword.value,
          userId: userId.value,
          verifyCode: verifyCode.value
        }
        await resetPassword(data)
        toast('Senha criada com sucesso', {
          theme: 'auto',
          type: 'success'
        })
        router.push({ name: 'Login' })
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loading.value = false
      }
    }

    const checkUser = async () => {
      if (!userId.value || !verifyCode.value) {
        router.push({ name: 'Login' })
      }
    }

    onMounted(() => {
      checkUser()
      changeBackgroundImage()
      currentImageIndex.value + 1
      setInterval(changeBackgroundImage, 10000)
    })

    return {
      newPassword,
      confirmPassword,
      loadAssetWhiteLabel,
      loading,
      bgImg,
      doRecoveryAction
    }
  }
}
</script>

<style lang="scss">
.password-rules {
  background-color: var(--fifth-color);
  // background-color: #0aa0921f;
  width: 100%;
  border-radius: 9px;
  margin-top: 10px;

  span {
    font-size: 15px;
    color: var(--label-color);
  }
}
</style>
