import { watch } from 'vue'

export const useSearchThrottle = (searchValue, callback, debounceTime = 600) => {
  let timerId = null

  const handlerTrigger = () => {
    const newSearch = searchValue.value
    if (newSearch.length >= 3 || newSearch.length === 0) {
      callback(newSearch)
    }
  }

  const debounceSearch = () => {
    clearTimeout(timerId)
    timerId = setTimeout(() => {
      handlerTrigger()
    }, debounceTime)
  }

  watch(searchValue, () => {
    debounceSearch()
  })
}
