<script>
import { Tooltip } from 'bootstrap'
import { useField } from 'vee-validate'
import { ref, onMounted, inject } from 'vue'

export default {
  name: 'appInput',
  props: {
    className: {
      type: String,
      default: 'form-input'
    },
    label: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => ({})
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    rules: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const { value, errorMessage, handleChange } = useField(() => props.name, props.rules, {
      syncVModel: true,
      validateOnValueUpdate: false
    })

    const alreadySubmittedOnce = inject('alreadySubmittedOnce', ref(false))

    const handleChangeValidation = (value) => {
      handleChange(value, alreadySubmittedOnce.value)
    }

    onMounted(() => {
      new Tooltip(document.body, {
        selector: '[data-bs-toggle="tooltip"]'
      })
    })

    return {
      value,
      handleChangeValidation,
      errorMessage
    }
  }
}
</script>

<template>
  <div :class="className">
    <label :for="id">
      {{ label }}
      <span v-if="tooltip" data-bs-toggle="tooltip" data-bs-placement="left" :title="tooltip">
        <i class="bi bi-info-square-fill"></i>
      </span>
    </label>

    <money3
      v-bind="config"
      :id="id"
      :name="name"
      v-model="value"
      @input="handleChangeValidation(value)"
      :disabled="isDisabled"
      :class="{ invalid: isInvalid || !!errorMessage }"
      :autocomplete="autocomplete"
    />
  </div>
</template>

<style scoped></style>
