<script>
import { ref } from 'vue'
import { useMeta } from 'vue-meta'

import formCheckbox from '@/components/form/checkbox.vue'
import formInput from '@/components/form/input.vue'
import formMoney from '@/components/form/money.vue'
import formRadio from '@/components/form/radio.vue'
import formSelect from '@/components/form/select.vue'
import formSwitch from '@/components/form/switch.vue'
import formTextarea from '@/components/form/textarea.vue'
import appBankList from '@/components/shared/bankList.vue'
import appImportDocument from '@/components/shared/importDocument.vue'
import appTitle from '@/components/shared/title.vue'

export default {
  name: 'DesignSystem',

  components: {
    formInput,
    formMoney,
    formSelect,
    appTitle,
    formTextarea,
    formRadio,
    formCheckbox,
    formSwitch,
    appImportDocument,
    appBankList
  },

  setup() {
    useMeta({ title: 'DesignSystem' })

    const statusValue = ref(0)
    const uploadedFileUrls = ref([])
    const loading = ref(false)

    const status = ref([
      { key: '0', name: 'Selecione uma opção' },
      { key: '1', name: 'Primeira opção' },
      { key: '2', name: 'Segunda opção' },
      { key: '3', name: 'Terceira opção' },
      { key: '4', name: 'Quarta opção' }
    ])
    const radio = ref([
      { key: 'radio1', name: 'Radio 1' },
      { key: 'radio2', name: 'Radio 2' },
      { key: 'radio3', name: 'Radio 3' },
      { key: 'radio4', name: 'Radio 4' },
      { key: 'radio5', name: 'Radio 5' }
    ])
    const checkbox = ref([
      { key: 'checkbox1', name: 'Checkbox 1' },
      { key: 'checkbox2', name: 'Checkbox 2' },
      { key: 'checkbox3', name: 'Checkbox 3' },
      { key: 'checkbox4', name: 'Checkbox 4' },
      { key: 'checkbox5', name: 'Checkbox 5' }
    ])
    const switchOp = ref([
      { key: 'switch1', name: 'Switch 1' },
      { key: 'switch2', name: 'Switch 2' },
      { key: 'switch3', name: 'Switch 3' },
      { key: 'switch4', name: 'Switch 4' },
      { key: 'switch5', name: 'Switch 5' }
    ])
    const fileTypes = ref([
      { key: '0', name: 'Selecione uma opção' },
      { key: '1', name: 'Primeira opção' },
      { key: '2', name: 'Segunda opção' },
      { key: '3', name: 'Terceira opção' },
      { key: '4', name: 'Quarta opção' }
    ])

    const bankOptions = ref([
      {
        key: '0',
        name: 'Banco 1',
        country: 'Brasil',
        bank: '001',
        account: '123456',
        swift: 'ABC123'
      },
      { key: '1', name: 'Banco 2', country: 'EUA', bank: '002', account: '654321', swift: 'XYZ789' }
    ])

    const configMoney = {
      debug: false,
      masked: false,
      prefix: 'R$',
      suffix: '',
      thousands: ',',
      decimal: '.',
      precision: 2,
      disableNegative: false,
      disabled: false,
      min: null,
      max: null,
      allowBlank: false,
      minimumNumberOfCharacters: 0,
      modelModifiers: {
        number: false
      },
      shouldRound: true,
      focusOnRight: false
    }

    const toggleDarkMode = () => {
      const htmlElement = document.documentElement
      htmlElement.classList.toggle('dark')
    }

    const handleFileSelected = async (files) => {
      for (const file of files) {
        loading.value = true
        const fileUrl = await uploadFileToS3(file)
        uploadedFileUrls.value.push(fileUrl)
        loading.value = false
      }
    }

    // TODO: Exemplo para chamada de API
    const uploadFileToS3 = async (file) => {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      return `https://example.com/${file.name}`
    }

    const removeFile = (index) => {
      if (index >= 0 && index < uploadedFileUrls.value.length) {
        uploadedFileUrls.value.splice(index, 1)
      }
    }

    return {
      statusValue,
      status,
      radio,
      checkbox,
      switchOp,
      toggleDarkMode,
      handleFileSelected,
      uploadFileToS3,
      bankOptions,
      uploadedFileUrls,
      fileTypes,
      removeFile,
      configMoney,
      loading
    }
  }
}
</script>

<template>
  <div>
    <section>
      <div class="container mt-5">
        <div class="row">
          <h1 class="col-12">Design System</h1>
          <p class="col-12 col-md-7">
            Uma coleção de componentes reutilizáveis, guiados por padrões claros, que podem ser
            montados juntos para construir qualquer número de aplicações. Os sistemas de design
            auxiliam no design e desenvolvimento de produtos digitais, como aplicativos ou sites.
          </p>
        </div>
      </div>
    </section>

    <section>
      <div class="container mt-5 pt-5 mb-5 pb-5">
        <div class="row g-2">
          <div class="col-12 col-md-6 text-center">
            <span style="font-size: 30px">Inter</span>
            <h1 style="font-size: 170px">Aa</h1>
          </div>
          <div class="col-12 col-md-6">
            <div class="row mb-3">
              <p class="col-12 col-md-4 f-regular">Regular</p>
              <p class="col-12 col-md-8 f-regular">
                Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm Nn Oo Pp Qq Rr Ss Tt Uu Vv Ww Xx Yy Zz
              </p>
            </div>
            <div class="row mb-3">
              <p class="col-12 col-md-4 f-medium">Medium</p>
              <p class="col-12 col-md-8 f-medium">
                Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm Nn Oo Pp Qq Rr Ss Tt Uu Vv Ww Xx Yy Zz
              </p>
            </div>
            <div class="row mb-3">
              <p class="col-12 col-md-4 f-semi-bold">Semi Bold</p>
              <p class="col-12 col-md-8 f-semi-bold">
                Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm Nn Oo Pp Qq Rr Ss Tt Uu Vv Ww Xx Yy Zz
              </p>
            </div>
            <div class="row mb-3">
              <p class="col-12 col-md-4 f-bold">Bold</p>
              <p class="col-12 col-md-8 f-bold">
                Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm Nn Oo Pp Qq Rr Ss Tt Uu Vv Ww Xx Yy Zz
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container mt-5">
        <div class="row">
          <h4 class="col-12">Paleta de Cores</h4>
          <p class="col-12 col-md-6">
            Um conjunto de cores selecionadas e organizadas de forma agradável e harmoniosa. Essa
            seleção de cores é usada em diferentes contextos, com o objetivo de criar uma aparência
            visual coerente e atraente.
          </p>
        </div>
      </div>
    </section>

    <section>
      <div class="container mt-5 pb-5">
        <div class="row g-0">
          <div class="col-12 col-md-4">
            <div class="box-aspect-ratio primary"></div>
          </div>
          <div class="col-12 col-md-8">
            <div class="row g-0">
              <div class="col-3">
                <div class="box-aspect-ratio secondary"></div>
              </div>
              <div class="col-3">
                <div class="box-aspect-ratio tertiary"></div>
              </div>
              <div class="col-3">
                <div class="box-aspect-ratio fourth"></div>
              </div>
              <div class="col-3">
                <div class="box-aspect-ratio fifth"></div>
              </div>
              <div class="col-3">
                <div class="box-aspect-ratio sixth"></div>
              </div>
              <div class="col-3">
                <div class="box-aspect-ratio seventh"></div>
              </div>
              <div class="col-3">
                <div class="box-aspect-ratio eighth"></div>
              </div>
              <div class="col-3">
                <div class="box-aspect-ratio ninth"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container mt-5">
        <div class="row">
          <h4 class="col-12">Biblioteca de Componentes</h4>
          <p class="col-12 col-md-6">
            Um conjunto de elementos de design pré-fabricados e reutilizáveis que são criados para
            facilitar o desenvolvimento de interfaces e a criação de produtos digitais.
          </p>
        </div>
      </div>
    </section>

    <section>
      <div class="container-fluid mod-color">
        <div class="row float-end me-3">
          <button @click="toggleDarkMode" type="button" class="btn btn-secondary">
            <i class="bi bi-moon"></i>
          </button>
        </div>
      </div>
    </section>

    <section class="container pt-5 pb-5">
      <div class="row">
        <div class="col-12 col-md-3">
          <div class="row">
            <app-title label="Input Search" />
            <form-input
              class="col-12 form-input"
              label="Label search"
              id="search"
              name="search"
              type="search"
              icon="bi bi-search"
            />
            <form-input
              class="col-12 form-input"
              label="Label search"
              id="search"
              name="search"
              type="search"
              icon="bi bi-search"
              :is-invalid="true"
            />
            <form-money
              class="col-12 form-input"
              label="Money"
              id="money"
              name="money"
              :config="configMoney"
              :is-invalid="true"
            />
          </div>
        </div>

        <div class="col-12 col-md-3">
          <div class="row">
            <app-title label="Input" />

            <form-input
              class="col-12 form-input"
              label="Label Input Text"
              id="text"
              name="text"
              type="text"
              placeholder="Nome Completo"
            />

            <form-input
              class="col-12 form-input"
              label="Label Input Text"
              id="text"
              name="text"
              type="text"
              placeholder="Nome Completo"
              :is-invalid="true"
            />

            <form-input
              class="col-12 form-input"
              icon="bi bi-bookmark-plus"
              label="Label Input Text 222"
              tooltip="Hooray!"
              id="text"
              name="text"
              type="text"
              placeholder="Nome Completo"
            />

            <div class="col-12 form-input">
              <label for="name_txt_1">
                Label Input Text
                <span data-bs-toggle="tooltip" data-bs-placement="left" title="Hooray!">
                  o que?
                </span>
              </label>
              <i class="bi bi-bookmark-plus"></i>
              <input id="name_txt_1" placeholder="Nome Completo" />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-3">
          <div class="row">
            <app-title label="Select" />

            <form-select
              class="col-12 form-input"
              label="Label Select"
              v-model="statusValue"
              :items="status"
              id="status"
              name="status"
            />

            <form-select
              class="col-12 form-input"
              label="Label Select"
              v-model="statusValue"
              :items="status"
              id="status"
              name="status"
              :is-invalid="true"
            />

            <form-select
              class="col-12 form-input"
              icon="bi bi-bookmark-plus"
              label="Label Select"
              tooltip="Hooray!"
              v-model="statusValue"
              :items="status"
              id="status"
              name="status"
            />

            <div class="col-12 form-input">
              <label for="select_icon">
                Label Select
                <span data-bs-toggle="tooltip" data-bs-placement="left" title="Hooray!">
                  o que?
                </span>
              </label>
              <i class="bi bi-bookmark-plus"></i>
              <select id="select_icon">
                <option disabled selected>Selecione uma opção</option>
                <option>Primeira opção</option>
                <option>Segunda opção</option>
                <option>Terceira opção</option>
                <option>Quarta opção</option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-3">
          <div class="row">
            <app-title label="Button" />

            <div class="col-12 form-input">
              <button>ENVIAR</button>
            </div>

            <div class="col-12 form-input">
              <button class="secondary">ENVIAR</button>
            </div>

            <div class="col-12 form-input">
              <button disabled>ENVIAR</button>
            </div>

            <div class="col-12 form-input">
              <button disabled class="secondary">ENVIAR</button>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-3">
          <div class="row">
            <app-title label="Textarea" />

            <form-textarea class="col-12 form-input" label="Label Textarea" />

            <form-textarea class="col-12 form-input" label="Label Textarea" :is-invalid="true" />
          </div>
        </div>

        <div class="col-12 col-md-3">
          <div class="row">
            <app-title label="Radio" />

            <form-radio
              class="col-12 form-input"
              label="Label Radio"
              :items="radio"
              id="radio"
              name="radio"
            />
          </div>
        </div>

        <div class="col-12 col-md-3">
          <div class="row">
            <app-title label="Checkbox" />

            <form-checkbox
              class="col-12 form-input"
              label="Label Checkbox"
              :items="checkbox"
              id="checkbox"
              name="checkbox"
            />
          </div>
        </div>

        <div class="col-12 col-md-3">
          <div class="row">
            <app-title label="Switch" />

            <form-switch
              class="col-12 form-input"
              label="Label Switch"
              :items="switchOp"
              id="switch"
              name="switch"
            />
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="row">
            <app-title label="DOCUMENTAÇÃO" />

            <app-import-document
              label="DOCUMENTAÇÃO"
              class="col"
              id="docs"
              name="docus"
              @fileSelected="handleFileSelected"
              @file-removed="removeFile"
              accept="image/*"
              :loading="loading"
              :uploadedFileUrls="uploadedFileUrls"
              :fileTypes="fileTypes"
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="row">
            <app-title label="DADOS BANCÁRIOS DO BENEFICIÁRIO" />

            <app-bank-list :initialBanks="bankOptions" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
