import { toast } from 'vue3-toastify'

export function handleGlobalError(error, origin = 'handleGlobalError:') {
  let message = null
  let isUnknownError = false
  if (error.message?.includes('Network Error')) {
    message = 'Não foi possível completar sua solicitação, verifique sua conexão e tente novamente.'
  } else {
    switch (error.response?.data?.type) {
      case 'urn:tb:error:invalid_credentials':
        message = 'Usuário ou senha estão incorretos.'
        break
      case 'urn:tb:error:entity_not_found':
        message = 'Usuário não encontrado.'
        break
      case 'urn:tb:error:user_not_active':
        message = 'Usuário desabilitado. Favor entrar em contato com o suporte.'
        break
      case 'urn:tb:error:user_password_unregistered':
        message = 'Aguardando cadastramento da senha. Por favor, verifique seu e-mail.'
        break
      case 'urn:tb:error:user_waiting_verification':
        message =
          'Aguardando verificação da conta. Por favor, verifique seu e-mail para concluir o cadastramento.'
        break
      case 'urn:tb:error:daily_limit_exceeded':
        message = 'Limite diário excedido.'
        break
      case 'urn:tb:error:limit_exceeded':
        message = 'Limite de operação excedido'
        break
      case 'urn:tb:error:user_code_expired':
        message = 'Código de validação do usuário inválido.'
        break

      default:
        message = error.response?.data?.detail
          ? error.response?.data?.detail
          : error.response?.data?.type || 'Ocorreu um erro desconhecido.'
        isUnknownError = true
        break
    }
  }

  if (message != null) {
    toast(message, {
      theme: 'auto',
      type: 'warning'
    })
  }

  if (isUnknownError) {
    if (error?.response) {
      const { status, statusText, data } = error.response
      console.error(
        origin,
        JSON.stringify(
          {
            status,
            statusText,
            data
          },
          null,
          2
        )
      )
    } else {
      console.error(origin, JSON.stringify(error, Object.getOwnPropertyNames(error), 2))
    }
  }
}
