import { getPlatformFromUrl } from '@composables/utils/useWhitelabelService'

/**
 * Obtém a URL raiz do site atual.
 * @returns {string} A URL raiz.
 */
function getBaseUrl(): string {
  return window.location.origin + '/'
}

/**
 * Carrega imagem de public/img/platform do cliente.
 * @param {string} file Nome da imagem a ser carregada.
 * @returns {string | undefined} O caminho completo da imagem carregada ou undefined se não for encontrada.
 */
function loadAssetWhiteLabel(file: string): string | undefined {
  const platform = getPlatformFromUrl()
  return `${getBaseUrl()}img/white-label/${platform}/${file}`
}

function loadAsset(filePath: string): string | undefined {
  return `${getBaseUrl()}img/${filePath}`
}

export { loadAssetWhiteLabel, loadAsset }
