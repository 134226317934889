<template>
  <div v-if="editModeEnabled && operationType === operationTypes.BUYING" class="col-12">
    <app-title label="TRANSFERÊNCIA PARA CONTA DO BENEFICIÁRIO NO EXTERIOR" />
    <div class="operation">
      <div class="row">
        <div class="col-12">
          <label>Nome de quem irá receber:</label>
          <p>{{ beneficiaryAbroadDetail.name }}</p>
        </div>
        <div class="col-6">
          <label>País:</label>
          <p>{{ beneficiaryAbroadDetail.country }}</p>
        </div>
        <div class="col-6">
          <label>Código SWIFT/BIC:</label>
          <p>{{ beneficiaryAbroadDetail.swift }}</p>
        </div>
        <div class="col-6">
          <label>Banco:</label>
          <p>{{ beneficiaryAbroadDetail.bank }}</p>
        </div>
        <div class="col-6">
          <label>Conta:</label>
          <p>{{ beneficiaryAbroadDetail.account }}</p>
        </div>

        <div class="col-6">
          <label>Liquidação em:</label>
          <p :class="operationType === operationTypes.SELLING ? 'color-sell' : 'color-buy'">
            ME: {{ liquidationTypeValue }}
          </p>
        </div>

        <div v-if="beneficiaryAbroadDetail?.intermediaryBankAccount?.name != null" class="row">
          <div class="col-12">
            <hr />
          </div>

          <div class="col-12">
            <label>Banco intermediário:</label>
            <p>{{ beneficiaryAbroadDetail.intermediaryBankAccount?.name || emptyPlaceholder }}</p>
          </div>

          <div class="col-6">
            <label>Conta:</label>
            <p>
              {{ beneficiaryAbroadDetail.intermediaryBankAccount?.account || emptyPlaceholder }}
            </p>
          </div>

          <div class="col-6">
            <label>SWIFT:</label>
            <p>{{ beneficiaryAbroadDetail.intermediaryBankAccount?.swift || emptyPlaceholder }}</p>
          </div>
        </div>

        <div v-if="beneficiaryAbroadDetail?.forFurtherCredit != null" class="row">
          <div class="col-12">
            <hr />
          </div>
          <div class="col-12">
            <label>For Further Credit ou demais referências</label>
            <p>{{ beneficiaryAbroadDetail?.forFurtherCredit || emptyPlaceholder }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appTitle from '@/components/shared/title.vue'

export default {
  name: 'TransferenciaBeneficiario',

  components: {
    appTitle
  },

  props: {
    editModeEnabled: {
      type: Boolean,
      default: false
    },
    operationType: {
      type: String,
      required: true
    },
    operationTypes: {
      type: Object,
      required: true
    },
    beneficiaryAbroadDetail: {
      type: Object,
      default: () => ({})
    },
    emptyPlaceholder: {
      type: String,
      default: '-'
    },
    liquidationTypeValue: {
      type: String,
      default: ''
    }
  }
}
</script>
