<script>
import { onMounted, ref, watch } from 'vue'
import { useMeta } from 'vue-meta'

import formModalPre from '@/components/modalPre/formModalPre.vue'
import appFilterSection from '@/components/shared/filterSection.vue'
import appModal from '@/components/shared/modal.vue'
import appTableSection from '@/components/shared/tableSection.vue'
import { useDynamicFilters, getPreOperationFilters } from '@/composables/utils/useFilters'
import { useNoScroll } from '@/composables/utils/useNoScroll'
import { usePaginatedResult } from '@/composables/utils/usePaginatedResult'
import { usePermissions } from '@/composables/utils/usePermissions'
import { useSearchThrottle } from '@/composables/utils/useSearch'
import router from '@/router'
import {
  fetchOpenOperations,
  createOpenOperation,
  editOpenOperation,
  fetchPreOperation,
  deletePreOperation
} from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'
import { useWss } from '@/stores/wss'

export default {
  name: 'PreOperations',

  components: {
    appFilterSection,
    appTableSection,
    formModalPre,
    appModal
  },

  props: {
    search: String,
    status: String,
    type: String,
    createdAt: String,
    id: String
  },

  setup(props) {
    useMeta({ title: 'Pré-operação' })
    const modalFormIsShown = ref(false)
    const modalConfig = ref({})

    const searchValue = ref(props.search)
    const natureTypeValue = ref(props.type)
    const statusValue = ref(props.status)
    const createdValue = ref(props.createdAt)

    const idEditOperation = ref(props.id)

    const valuesToWatch = [natureTypeValue, statusValue, createdValue]

    const permissions = usePermissions()

    useSearchThrottle(searchValue, () => {
      resetAndUpdateResult(true)
    })

    const { setNoScroll } = useNoScroll()

    const tableHeaders = [
      { key: 'clientName', label: 'Cliente', type: 'text' },
      { key: 'backOfficeUserId', label: 'Back Office', type: 'backOfficeUserId' },
      {
        key: 'principalAmount',
        label: 'Valor',
        type: 'currency',
        locale: 'pt-BR',
        maximumFractionDigits: 2
      },
      { key: 'type', label: 'Tipo', type: 'operationType' },
      { key: 'origin', label: 'Origem ou destino', type: 'text' },
      { key: 'status', label: 'Status', type: 'statusType' },
      {
        key: 'createdAt',
        label: 'Criação',
        type: 'date',
        format: 'dd/MM/yyyy HH:mm',
        timeZoneOffset: -3
      },
      {
        key: 'actions',
        label: 'Ações',
        type: 'actions',
        options: [
          {
            action: 'edit',
            label: 'Editar',
            class: 'edit',
            emitEvent: 'edit',
            isVisible: permissions.canEditData()
          },
          {
            action: 'delete',
            label: 'Deletar',
            class: 'delete',
            emitEvent: 'delete',
            isVisible: permissions.canDeleteData()
          }
        ]
      }
    ]

    const fetchFunction = (config) => fetchOpenOperations(config)

    const resetAndUpdateResult = (shouldReset) => {
      if (shouldReset) resetResult()

      const config = {
        page: currentPage.value,
        search: searchValue.value,
        status: statusValue.value,
        type: natureTypeValue.value,
        createdAt: createdValue.value
      }

      router.push({ name: 'PreOperations', query: { ...config, page: undefined } })
      fetchResult(config)
    }

    const {
      resultItems: operations,
      loading,
      isLastPage,
      fetchResult,
      resetResult,
      currentPage
    } = usePaginatedResult(fetchFunction, resetAndUpdateResult, valuesToWatch)

    const wssStore = useWss()

    watch(
      [() => wssStore.getNewPreOperation.id, () => wssStore.getNewPosOperation.id],
      ([newPreVal, newPostVal], [oldPreVal, oldPostVal]) => {
        if (newPreVal !== oldPreVal || newPostVal !== oldPostVal) {
          resetAndUpdateResult(true)
        }
      }
    )

    onMounted(() => {
      if (idEditOperation.value) {
        let item = {
          id: idEditOperation.value
        }
        handleEdit(item)
      }
    })

    const handleDelete = async (item) => {
      modalConfig.value = {
        origin: 'delete-target-tax',
        title: 'Você tem certeza?',
        icon: 'bi bi-exclamation-square-fill',
        description: 'Se você remover esta oferta, ela ficará como pré-operação para o cliente',
        showButtons: true,
        type: 'warning',
        submitButtonText: 'Confirmar',
        backButtonText: 'Cancelar',
        id: item.id
      }
      modalFormIsShown.value = true
    }

    const loadingModal = ref(false)

    const handleDeletePreOperation = async (item) => {
      try {
        loadingModal.value = true
        const body = [item.id]
        await deletePreOperation(body)
        operations.value = operations.value.filter((op) => op.id !== item.id)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        modalFormIsShown.value = false
        loadingModal.value = false
        setNoScroll(false)
      }
    }

    const handleConfirm = (item) => {
      if (item.origin === 'delete-target-tax') {
        handleDeletePreOperation(item)
      }
    }

    const title = ref('')
    const modalEnabled = ref(false)
    const editModeEnabled = ref(false)
    const loadingButton = ref(false)
    const initialFormData = ref({})

    const newPreOperation = () => {
      title.value = 'Nova Pré-operação'
      modalEnabled.value = true
    }

    const handleFormCreation = async (formData) => {
      if (!editModeEnabled.value) {
        handleSubmitOperation(formData)
      } else {
        handleUpdateOperation(formData)
      }
    }

    const handleSubmitOperation = async (formData) => {
      try {
        loadingButton.value = true
        await createOpenOperation(formData)
        handleFormClose()
        resetAndUpdateResult(true)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loadingButton.value = false
        setNoScroll(false)
      }
    }

    const handleUpdateOperation = async (updatedForm) => {
      try {
        loadingButton.value = true
        await editOpenOperation(updatedForm)
        handleFormClose()
        resetAndUpdateResult(true)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loadingButton.value = false
        setNoScroll(false)
      }
    }

    const handleFormClose = () => {
      initialFormData.value = {}
      editModeEnabled.value = false
      modalEnabled.value = false

      const queryParams = {}
      router.push({ name: 'PreOperations', query: queryParams })
    }

    const handleEdit = async (item) => {
      try {
        let response = await fetchPreOperation(item.id)
        title.value = 'Editar Pré-operação'
        initialFormData.value = {
          ...response.data.payload
        }

        editModeEnabled.value = true
        modalEnabled.value = true

        const config = {
          id: item.id
        }

        const queryParams = { ...config }
        router.push({ name: 'PreOperations', query: queryParams })
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const filterSchema = getPreOperationFilters()
    const externalRefs = { searchValue, statusValue, natureTypeValue, createdValue }
    const { formValues, updateFormValues } = useDynamicFilters(filterSchema, externalRefs)

    return {
      permissions,

      tableHeaders,
      operations,
      loading,

      modalFormIsShown,
      loadingModal,
      modalConfig,
      handleDelete,
      handleConfirm,

      isLastPage,

      newPreOperation,
      modalEnabled,
      title,
      initialFormData,
      loadingButton,

      handleEdit,

      handleFormClose,
      handleFormCreation,

      filterSchema,
      formValues,
      updateFormValues
    }
  }
}
</script>

<template>
  <div>
    <app-modal
      v-if="modalFormIsShown"
      :modalConfig="modalConfig"
      :loading="loadingModal"
      @submit="handleConfirm"
      @onClose="modalFormIsShown = false"
    />

    <form-modal-pre
      v-if="modalEnabled"
      :title="title"
      @onClose="handleFormClose"
      :loading="loadingButton"
      :initialFormData="initialFormData"
      @onSubmitted="handleFormCreation"
    />

    <div class="row title">
      <h2 class="col">Pré-operação</h2>
      <div class="col-12 col-md-3 form-input">
        <button v-if="permissions.canCreatedData()" class="secondary" @click="newPreOperation()">
          <i class="bi bi-node-plus-fill"></i>
          Nova Operação
        </button>
      </div>
    </div>

    <app-filter-section
      :filterSchema="filterSchema"
      :formValues="formValues"
      @update:formValues="updateFormValues"
    />

    <app-table-section
      :items="operations"
      :headers="tableHeaders"
      :loading="loading"
      :isLastPage="isLastPage && !loading"
      :eventHandlers="{
        edit: handleEdit,
        delete: handleDelete
      }"
    />
  </div>
</template>
