<script>
import { ref } from 'vue'

export default {
  name: 'appRadio',
  props: {
    className: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const selectedValue = ref(props.modelValue)

    const updateModel = (event) => {
      selectedValue.value = event.target.value
      emit('update:modelValue', selectedValue.value)
    }

    return {
      selectedValue,
      updateModel
    }
  }
}
</script>

<template>
  <div :class="className">
    <label>
      {{ label }}
    </label>
    <div class="radio">
      <label
        v-for="(item, i) in items"
        :key="i"
        :for="item.key"
        :class="{ invalid: isInvalid }"
        @change="updateModel"
      >
        <input type="radio" :id="item.key" name="radio" :value="item.key" :disabled="isDisabled" />
        {{ item.name }}
      </label>
    </div>
  </div>
</template>
