<script>
import lateralMenu from '@/components/menu/index.vue'
import statusBar from '@/components/statusBar.vue'

export default {
  name: 'AppMain',

  components: {
    lateralMenu,
    statusBar
  }
}
</script>

<template>
  <section class="container-fluid">
    <div class="row">
      <lateralMenu />
      <div class="col offset-lg-3 offset-xl-2">
        <statusBar />
        <router-view />
      </div>
    </div>
  </section>
</template>
