import router from '@/router'
import { useAuthToken } from '@/stores/authToken'

export const transformInterceptor = (responseInterceptor, targetEndpoint, transformContent) => {
  responseInterceptor.use((response) => {
    if (response.config.url.includes(targetEndpoint)) {
      if (Array.isArray(response.data.payload)) {
        response.data.payload = transformContent(response.data.payload)
      } else if (Array.isArray(response.data)) {
        response.data = transformContent(response.data)
      }
    }
    return response
  })
}

export const adminTransformInterceptor = (responseInterceptor) => {
  transformInterceptor(responseInterceptor, '/configs/banks', (content) =>
    content.map((item) => ({
      ...item,
      name: item.country,
      key: item.id
    }))
  ),
    transformInterceptor(responseInterceptor, '/admins/super/promoCodes', (content) =>
      content.map((item) => ({
        ...item,
        clientCount: item.clients ? item.clients.length : 0
      }))
    ),
    transformInterceptor(responseInterceptor, '/admins/bankers/promoCodes', (content) =>
      content.map((item) => ({
        ...item,
        clientCount: item.clients ? item.clients.length : 0
      }))
    )
  transformInterceptor(responseInterceptor, '/admins/client', (content) =>
    content.map((item) => ({
      ...item,
      isTpay: item.institution === 'TPAY',
      clientType: item.clientType === 'FOREIGN' ? 'ESTRANGEIRO' : item.clientType
    }))
  )
}

export const authorizationHeaderInterceptor = (requestInterceptor) => {
  requestInterceptor.use((config) => {
    const authToken = useAuthToken().getToken
    if (authToken) {
      config.headers['Authorization'] = `Bearer ${authToken}`
    }
    return config
  })
}

export const forbiddenOrTokenExpiredInterceptor = (responseInterceptor) => {
  responseInterceptor.use(
    (response) => response,
    (error) => {
      const authToken = useAuthToken()
      const isUnauthorized = error.response?.status === 401
      const isRedirecting = sessionStorage.getItem('isRedirecting')
      const hasToken = authToken.token != null

      if (isUnauthorized) {
        if (isRedirecting === null && hasToken) {
          sessionStorage.setItem('isRedirecting', 'true')
          authToken.token = null

          const originalPath = router.currentRoute.value.fullPath
          router.push({ path: '/login', query: { redirect: originalPath } })
          return new Promise(() => {})
        }

        if (isRedirecting && !hasToken) {
          return new Promise(() => {})
        }
      }

      return Promise.reject(error)
    }
  )
}
