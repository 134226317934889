<script>
import { hasInputAllowed } from '@composables/utils/useWhitelabelService.js'
import { useForm } from 'vee-validate'
import { onMounted, ref, provide, watch } from 'vue'
import { useMeta } from 'vue-meta'
import * as yup from 'yup'

import formButton from '@/components/form/button.vue'
import formInput from '@/components/form/input.vue'
import appBanks from '@/components/modalBroker/_banks.vue'
import appIntermediaryBanks from '@/components/modalBroker/_intermediaryBanks.vue'
import appTitle from '@/components/shared/title.vue'
import { useNoScroll } from '@/composables/utils/useNoScroll'

export default {
  name: 'formModalPre',

  components: {
    appTitle,
    formInput,
    appBanks,
    appIntermediaryBanks,
    formButton
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    initialFormData: {
      type: Object,
      default: () => ({})
    }
  },

  setup(props, { emit }) {
    useMeta({ title: props.title })

    const contentActive = ref(false)
    const scroll = ref(false)
    const { setNoScroll } = useNoScroll()

    watch(scroll, (newValue) => {
      setNoScroll(newValue)
    })

    const { handleSubmit } = useForm({
      validationSchema: yup.object({
        name: yup.string().required(),
        id: yup.string().required()
      })
    })

    const alreadySubmittedOnce = ref(false)
    provide('alreadySubmittedOnce', alreadySubmittedOnce)

    const onSubmitAction = () => {
      alreadySubmittedOnce.value = true
      return onSubmit()
    }

    const onSubmit = handleSubmit(async () => {
      const body = {
        ...props.initialFormData,
        name: nameValue.value,
        id: idFinancialInstitutionValue.value,
        natBankAccounts: natBankAccounts.value,
        intBankAccounts: intBankAccounts.value
      }
      emit('onSubmitted', body)
    })

    const close = () => {
      contentActive.value = false
      scroll.value = false
      setTimeout(() => {
        emit('onClose', false)
      }, 300)
    }

    const openModal = () => {
      scroll.value = true
      setTimeout(() => {
        contentActive.value = true
      }, 100)
    }

    onMounted(() => {
      openModal()
    })

    const editModeEnabled = ref(false)

    const nameValue = ref('')
    const idFinancialInstitutionValue = ref('')
    const natBankAccounts = ref([])
    const intBankAccounts = ref([])

    watch(
      () => props.initialFormData,
      async (newFormData) => {
        if (Object.keys(newFormData).length === 0) return
        editModeEnabled.value = true
        idFinancialInstitutionValue.value = newFormData.id
        nameValue.value = newFormData.name
        natBankAccounts.value = newFormData.natBankAccounts
        intBankAccounts.value = newFormData.intBankAccounts
      },
      { immediate: true }
    )

    return {
      contentActive,

      idFinancialInstitutionValue,
      nameValue,
      natBankAccounts,
      intBankAccounts,

      editModeEnabled,
      onSubmitAction,
      close,
      hasInputAllowed
    }
  }
}
</script>

<template>
  <div class="box-content d-flex justify-content-end">
    <div :class="['back', { active: contentActive }]" @click="close()"></div>
    <div :class="['content', { active: contentActive }]">
      <div class="row">
        <h4 class="col-8">{{ title }}</h4>
      </div>

      <div class="col-12">
        <div class="row g-2">
          <form-input
            className="col-12 form-input"
            label="ID da instituição financeira"
            v-model="idFinancialInstitutionValue"
            id="id"
            name="id"
            type="text"
            :isDisabled="editModeEnabled"
          />
          <form-input
            className="col-12 form-input"
            label="Nome da instituição financeira"
            v-model="nameValue"
            id="name"
            name="name"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <app-title label="CONTAS BANCÁRIAS" />
          <app-banks
            :bankAccounts="natBankAccounts"
            @bankAccounts="natBankAccounts = $event"
            :isDisabled="!hasInputAllowed()"
          />

          <app-title label="BANCOS INTERMEDIÁRIO" />
          <app-intermediary-banks
            :bankAccounts="intBankAccounts"
            @bankAccounts="intBankAccounts = $event"
            :isDisabled="!hasInputAllowed()"
          />

          <form-button
            class="col-12 form-input"
            title="Enviar"
            :loading="loading"
            :isDisabled="!hasInputAllowed() || loading"
            @clicked="onSubmitAction"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.box-content {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 999;
  padding: 0;

  .back {
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.3s;

    &.active {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  .content {
    background-color: var(--body-background);
    transition: right 0.3s;
    overflow: auto;
    height: 100%;
    width: 44%;
    z-index: 1;
    position: fixed;
    top: 0;
    right: -44%;
    padding: 44px 38px;

    &.active {
      right: 0;
    }

    h4 {
      font-size: 36px;
      font-weight: 500;
    }
  }
}
</style>
