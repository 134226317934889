import { defineStore } from 'pinia'

export const useConfig = defineStore('config', {
  state() {
    return {
      theme: null,
      sound: true,
      favoriteCurrency: 'USD'
    }
  },

  getters: {
    currentTheme() {
      return this.theme
    },
    soundEnabled() {
      return this.sound
    }
  },
  persist: true
})
