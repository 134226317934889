<script>
import { platformOnboardingUrlForRole } from '@composables/utils/useWhitelabelService'
import { hasInputAllowed } from '@composables/utils/useWhitelabelService.js'
import { useForm } from 'vee-validate'
import { onMounted, ref, provide, watch, computed } from 'vue'
import { useMeta } from 'vue-meta'
import { toast } from 'vue3-toastify'
import * as yup from 'yup'

import formButton from '@/components/form/button.vue'
import formInput from '@/components/form/input.vue'
import { useNoScroll } from '@/composables/utils/useNoScroll'

export default {
  name: 'formModalPromoCode',

  components: {
    formInput,

    formButton
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    initialFormData: {
      type: Object,
      default: () => ({})
    }
  },

  setup(props, { emit }) {
    useMeta({ title: props.title })

    const contentActive = ref(false)
    const scroll = ref(false)
    const { setNoScroll } = useNoScroll()
    const editModeEnabled = ref(false)
    const codeValue = ref('')
    const descriptionValue = ref('')
    const institution = ref(props.initialFormData.institution)

    const URL = computed(() => {
      return `${platformOnboardingUrlForRole(institution.value)}?codepartner=${codeValue.value}`
    })

    watch(scroll, (newValue) => {
      setNoScroll(newValue)
    })

    const { handleSubmit } = useForm({
      validationSchema: yup.object({
        code: yup.string().required(),
        description: yup.string().required()
      })
    })

    const alreadySubmittedOnce = ref(false)
    provide('alreadySubmittedOnce', alreadySubmittedOnce)

    const onSubmitAction = () => {
      alreadySubmittedOnce.value = true
      return onSubmit()
    }

    const onSubmit = handleSubmit(async () => {
      const body = {
        ...props.initialFormData,
        code: codeValue.value,
        description: descriptionValue.value
      }
      emit('onSubmitted', body)
    })

    const close = () => {
      contentActive.value = false
      scroll.value = false
      setTimeout(() => {
        emit('onClose', false)
      }, 300)
    }

    const openModal = () => {
      scroll.value = true
      setTimeout(() => {
        contentActive.value = true
      }, 100)
    }

    onMounted(() => {
      openModal()
    })

    watch(
      () => props.initialFormData,
      async (newFormData) => {
        if (Object.keys(newFormData).length === 0) return
        editModeEnabled.value = true

        codeValue.value = newFormData.code
        descriptionValue.value = newFormData.description
      },
      { immediate: true }
    )

    const onCopyAction = () => {
      navigator.clipboard.writeText(URL.value)
      toast('URL de compartilhamento copiada!', {
        theme: 'auto',
        type: 'success',
        dangerouslyHTMLString: true
      })
    }

    return {
      contentActive,
      editModeEnabled,

      codeValue,
      descriptionValue,

      onSubmitAction,
      close,
      hasInputAllowed,

      URL,
      onCopyAction
    }
  }
}
</script>

<template>
  <div class="box-content d-flex justify-content-end">
    <div :class="['back', { active: contentActive }]" @click="close()"></div>
    <div :class="['content', { active: contentActive }]">
      <div class="row">
        <h4 class="col-12">{{ title }}</h4>
      </div>

      <div class="col-12">
        <div class="row g-2">
          <form-input
            className="col-12 form-input"
            label="Código"
            v-model="codeValue"
            id="code"
            name="code"
            type="text"
            :isDisabled="!hasInputAllowed() || editModeEnabled"
          />

          <form-input
            className="col-12 form-input"
            label="Descrição"
            v-model="descriptionValue"
            id="description"
            name="description"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            v-if="editModeEnabled"
            className="col-8 form-input"
            label="URL de compartilhamento"
            v-model="URL"
            id="url"
            name="url"
            type="text"
            :isDisabled="true"
          />

          <form-button
            v-if="editModeEnabled"
            class="col-4 form-input"
            title="Copiar"
            @clicked="onCopyAction"
          />

          <form-button
            class="col-12 form-input"
            title="Enviar"
            :loading="loading"
            :isDisabled="!hasInputAllowed() || loading"
            @clicked="onSubmitAction"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.box-content {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 999;
  padding: 0;

  .back {
    background-color: rgba(0, 0, 0, 0);
    transition: background 0.36s cubic-bezier(0.32, 0.08, 0.55, 1);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.3s;

    &.active {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  .content {
    background-color: var(--body-background);
    transition: right 0.3s;
    overflow: auto;
    height: 100%;
    width: 44%;
    z-index: 1;
    position: fixed;
    top: 0;
    right: -44%;
    padding: 44px 38px;

    &.active {
      right: 0;
    }

    h4 {
      font-size: 36px;
      font-weight: 500;
    }
  }
}
</style>
