import { useInstitution } from '@composables/utils/useInstitution'
import {
  isTransferbankAdminUser,
  platformNameForRole
} from '@composables/utils/useWhitelabelService.js'
import { ref } from 'vue'

import { useAuthToken } from '@/stores/authToken.js'
import { useWss } from '@/stores/wss.js'

export function useWebSocket() {
  const institution = ref('')

  const interests = new Set()
  const isProbablyDead = ref(true)

  const authentication = useAuthToken()

  let connection = null

  let keepAliveTaskId = null
  let checkHealthTaskId = null

  const debugEnabled = ref(true)

  const { institutionType } = useInstitution()

  const startWebSocketConnections = async () => {
    await subscribeToCurrency('USD')
    await subscribeToCurrency('EUR')
    await subscribeToCurrency('GBP')
    await subscribeToCurrency('JPY')
    await subscribeToCurrency('CAD')
    await subscribeToCurrency('CHF')
    await subscribeToCurrency('AUD')
    await subscribeToMarketOpen()
    await subscribeToPtax()

    institution.value = platformNameForRole(authentication.getInstitution).toLowerCase()
    await connectRefreshExchangeAdmin()
  }

  const connectRefreshExchangeAdmin = async () => {
    const topics = ['operations/pre', 'operations/pos', 'onboardings/step2']
    if (isTransferbankAdminUser()) {
      const institutions = Object.keys(institutionType.value)
        .filter((key) => key && key.trim() !== '')
        .map((key) => key.toLowerCase())

      for (const key of institutions) {
        for (const topic of topics) {
          await subscribeTo(`admins/${key}/${topic}`)
        }
      }
    } else {
      for (const topic of topics) {
        await subscribeTo(`admins/${institution.value}/${topic}`)
      }
    }
  }

  const logMessage = (message, error = false) => {
    if (!debugEnabled.value) return
    const msg = `WSS: ${message}`
    error ? console.error(msg) : console.debug(msg)
  }

  const routePayload = (message) => {
    if (message.payload?.action === 'subscribe') return

    if (
      (message.payload?.action === 'ping' && message.payload?.status === 'pong') ||
      message.status === 'keep_alive'
    ) {
      isProbablyDead.value = false
      return
    }

    const channel = message.data.channel

    if (channel === '/topics/market/status') {
      useWss().market = message.data.marketOpen
    } else if (channel === '/topics/ptaxes') {
      useWss().PTAX = message.data
    } else {
      const exchangeOpenRegex = new RegExp(`/topics/admins/.*/operations/pre`)
      const exchangeCloseRegex = new RegExp(`/topics/admins/.*/operations/pos`)
      const newClientRegex = new RegExp(`/topics/admins/.*/onboardings/step2`)

      const { id, clientName, principalAmount, type, currencyType } = message.data
      const content = { id, clientName, principalAmount, type, currencyType }

      if (exchangeOpenRegex.test(channel)) {
        useWss().newPreOperation = content
      } else if (exchangeCloseRegex.test(channel)) {
        useWss().newPosOperation = content
      } else if (newClientRegex.test(channel)) {
        useWss().newClient = content
      } else {
        logMessage(`Unsupported channel: ${channel}`, true)
      }
    }
  }

  const processExchangeRate = (payload) => {
    const exchangeRates = useWss().getExchangeRate || {}

    const splits = payload.split('|')
    const currency = splits[3]
    const dateTimeRegex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})/
    const newDateTime = new Date(splits[5].match(dateTimeRegex)[0])

    if (exchangeRates[currency] == null) {
      exchangeRates[currency] = payload
      useWss().exchangeRate = exchangeRates
      return
    }

    const localSplits = exchangeRates[currency].split('|')
    const localDateTime = new Date(localSplits[5].match(dateTimeRegex)[0])

    if (newDateTime > localDateTime) {
      exchangeRates[currency] = payload
      useWss().exchangeRate = exchangeRates
    }
  }

  const startConnection = () => {
    return new Promise((resolve) => {
      if (connection != null) {
        resolve()
        return
      }

      connection = new WebSocket(`${process.env.VITE_APP_WSS}?token=${authentication.getToken}`)

      logMessage('Start connection')

      connection.onopen = () => {
        logMessage('Socket opened')
        runKeepAlive()
        if (checkHealthTaskId == null) checkHealth()
        resolve()
      }

      connection.onmessage = (message) => {
        try {
          if (message.data == null) return

          const response = JSON.parse(message.data)
          if (response) routePayload(response)
        } catch (exception) {
          if (exception instanceof SyntaxError) {
            processExchangeRate(message.data)
          } else {
            console.error(exception)
          }
        }
      }

      connection.onerror = (event) => {
        logMessage(`Connection error: ${event}`, true)
        closeConnection()
        logMessage('Try reconnect in 60 seconds')
        setTimeout(() => {
          logMessage('Trying reconnection...')
          startConnection()
        }, 60000)
      }

      connection.onclose = () => {
        closeConnection()
      }
    })
  }

  const closeConnection = () => {
    connection?.close?.()
    connection = null

    if (keepAliveTaskId != null) clearTimeout(keepAliveTaskId)
    if (checkHealthTaskId != null) clearTimeout(checkHealthTaskId)
    keepAliveTaskId = null
    checkHealthTaskId = null
  }

  const runKeepAlive = () => {
    keepAliveTaskId = setTimeout(() => {
      if (connection?.readyState !== WebSocket.OPEN) {
        isProbablyDead.value = true
        return
      }
      connection.send(JSON.stringify({ action: 'ping' }))
      logMessage('Keep alive ping sent')
      runKeepAlive()
    }, 10000)
  }

  const checkHealth = () => {
    checkHealthTaskId = setTimeout(() => {
      logMessage('Checking connection health')
      if (isProbablyDead.value) {
        logMessage('Connection seems dead, attempting to reconnect')
        reconnect()
      } else {
        isProbablyDead.value = true
        checkHealth()
      }
    }, 30000)
  }

  const reconnect = async () => {
    closeConnection()
    for (const topic of interests) {
      await subscribeTo(topic)
    }
  }

  const subscribeTo = async (topic) => {
    interests.add(topic)
    await startConnection()

    connection.send(
      JSON.stringify({
        action: 'subscribe',
        channel: `/topics/${topic}`
      })
    )
    logMessage(`Subscribed to ${topic}`)
  }

  const subscribeToCurrency = async (currency) => {
    await subscribeTo(`exchanges/${currency}`)
  }

  const subscribeToMarketOpen = async () => {
    await subscribeTo('market/status')
  }

  const subscribeToPtax = async () => {
    await subscribeTo('ptaxes')
  }

  const disconnectWebSocketConnections = () => {
    closeConnection()
    interests.clear()
    isProbablyDead.value = true
    logMessage('Disconnected from WebSocket')
  }

  return {
    startWebSocketConnections,
    disconnectWebSocketConnections
  }
}
