export function useNumberFormatter() {
  const numberFormat = (number, options = {}, locale = 'pt-BR') => {
    const formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: options?.minimumFractionDigits || 2,
      maximumFractionDigits: options?.maximumFractionDigits || 4
    })
    return formatter.format(number)
  }

  return { numberFormat }
}
