import { ref, onMounted, watch } from 'vue'

import { useInfiniteScroll } from '@/composables/utils/useInfiniteScroll'
import { usePagination } from '@/composables/utils/usePagination'
import { handleGlobalError } from '@/services/errorHandler'

export function usePaginatedResult(fetchFunction, resetAndUpdateResult, valuesToWatch = []) {
  const resultItems = ref([])
  const totalCount = ref('')
  const loading = ref(false)
  const { totalPages, currentPage, isLastPage, setPagination, nextPage } = usePagination()

  const resetResult = () => {
    resultItems.value = []
    currentPage.value = 0
  }

  const fetchResult = async (config) => {
    loading.value = true
    try {
      const response = await fetchFunction(config)
      resultItems.value =
        currentPage.value === 0
          ? response.data.payload
          : [...resultItems.value, ...response.data.payload]

      totalCount.value = response.data?.page?.totalCount
      setPagination(response?.data?.page?.pageCount, currentPage.value)
    } catch (e) {
      handleGlobalError(e)
    } finally {
      loading.value = false
    }
  }

  useInfiniteScroll(() => {
    if (
      !loading.value &&
      resultItems.value.length !== 0 &&
      currentPage.value < totalPages.value - 1
    ) {
      nextPage()
      resetAndUpdateResult(false)
    }
  })

  onMounted(() => {
    resetAndUpdateResult(true)
  })

  valuesToWatch.forEach((refValue) => {
    watch(refValue, () => resetAndUpdateResult(true))
  })

  return {
    resultItems,
    totalCount,
    loading,
    isLastPage,
    fetchResult,
    resetResult,
    currentPage
  }
}
