<script>
import { useField } from 'vee-validate'
import { inject, ref } from 'vue'
export default {
  name: 'appSwitch',
  props: {
    className: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    rules: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const { value, errorMessage, handleChange } = useField(() => props.name, props.rules, {
      syncVModel: true,
      validateOnValueUpdate: false
    })

    const alreadySubmittedOnce = inject('alreadySubmittedOnce', ref(false))

    const handleChangeValidation = (event) => {
      const newValue = event.target.checked
      value.value = newValue
      handleChange(value.value, alreadySubmittedOnce.value)
    }

    return {
      value,
      errorMessage,
      handleChangeValidation
    }
  }
}
</script>

<template>
  <div :class="className">
    <label>
      {{ label }}
    </label>
    <div class="radio">
      <label :class="{ invalid: isInvalid }" @change="handleChangeValidation">
        <input
          type="checkbox"
          class="switch"
          :id="id"
          :name="name"
          :checked="value"
          :disabled="isDisabled"
        />
        {{ description }}
      </label>
    </div>
  </div>
</template>
