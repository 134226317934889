<script>
import { onMounted } from 'vue'

import { theme } from '@/composables/utils/useGlobalConfig'
import { getPlatformFromUrl } from '@/composables/utils/useWhitelabelService.js'
import { useConfig } from '@/stores/config.js'

export default {
  name: 'App',

  setup() {
    const initializeTheme = () => {
      const htmlElement = document.documentElement
      const currentTheme = useConfig().theme
      const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches

      if (currentTheme) {
        htmlElement.classList.toggle(theme.DARK, currentTheme === theme.DARK)
      } else if (systemPrefersDark) {
        htmlElement.classList.add(theme.DARK)
        useConfig().theme = theme.DARK
      } else {
        htmlElement.classList.add(theme.LIGHT)
        useConfig().theme = theme.LIGHT
      }
    }

    const updateFavicon = () => {
      const platformKey = getPlatformFromUrl()
      const faviconUrl = `/img/white-label/${platformKey}/favicon/icon-32x32.png`

      let link = document.querySelector("link[rel~='icon']")
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
      link.href = faviconUrl
    }

    onMounted(async () => {
      initializeTheme()
      updateFavicon()
    })
  }
}
</script>

<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | ADMIN` : `ADMIN` }}</template>
  </metainfo>
  <router-view />
</template>

<style lang="scss">
@import './assets/css/style.scss';
</style>
