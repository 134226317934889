<script>
import { ref, onMounted } from 'vue'

export default {
  name: 'appLoading',
  props: {
    color: {
      type: String,
      default: ''
    },
    duration: {
      type: String,
      default: '2.5s'
    },
    count: {
      type: Number,
      default: 5
    }
  },
  setup() {
    const is_iPhone = ref(false)

    onMounted(() => {
      if (navigator.userAgent.includes('iPhone')) {
        is_iPhone.value = true
      }
    })

    return {
      is_iPhone
    }
  }
}
</script>

<template>
  <div>
    <svg>
      <defs>
        <filter id="goo">
          <feGaussianBlur
            in="SourceGraphic"
            color-interpolation-filters="sRGB"
            result="blur"
            stdDeviation="5"
          />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
            result="goo"
          />
        </filter>
      </defs>
    </svg>
    <div v-if="!is_iPhone" class="container-loading goo2">
      <div
        v-for="index in count"
        :key="index"
        :style="`background: ${color}; animation-duration: ${duration}`"
      ></div>
    </div>
    <div v-else class="lds-ellipsis">
      <div v-for="index in 4" :key="index" :style="`background: ${color}`"></div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
svg {
  width: 0;
  height: 0;
}

.container-loading {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.goo2 {
  filter: url(#goo);
  -webkit-filter: url(#goo);
}

.container-loading div {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.goo2 > div {
  background: var(--primary-color);
  transform: translateX(0);
  animation: sideways infinite ease;
}

.goo2 > div:nth-child(1) {
  animation-delay: 0.25s;
}

.goo2 > div:nth-child(2) {
  animation-delay: 0.5s;
}

.goo2 > div:nth-child(3) {
  animation-delay: 0.75s;
}

.goo2 > div:nth-child(4) {
  animation-delay: 1s;
}

.goo2 > div:nth-child(5) {
  animation-delay: 1.25s;
}

.goo2 {
  padding-left: 6px;
  width: 90px;
  margin-top: -15px;
}

@keyframes sideways {
  33% {
    transform: translateX(15px);
  }
  66% {
    transform: translateX(-15px);
  }
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  margin-top: -15px;
  width: 90px;
  height: 13px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.lds-ellipsis div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 13px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 13px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 38px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 63px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
