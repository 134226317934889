import { configs } from '@composables/utils/useWhitelabelService'
import { ref } from 'vue'

export const useInstitution = () => {
  const generateInstitutionType = () => {
    const institutionType = { '': { name: 'Todos', color: '' } }
    for (const key in configs) {
      const config = configs[key]
      institutionType[config.key] = {
        name: config.key,
        short_name: config.short_name,
        tag: config.tag,
        color: config.color,
        isApp: config.isApp || false
      }
    }
    return institutionType
  }

  const institutionType = ref(generateInstitutionType())

  const fetchInstitutionOptions = ({ includeAll }) => {
    return Object.keys(institutionType.value)
      .filter((key) => includeAll || key !== '')
      .map((key) => ({
        name: institutionType.value[key].name,
        key: key,
        color: institutionType.value[key].color,
        isApp: institutionType.value[key].isApp
      }))
  }

  return {
    institutionType,
    fetchInstitutionOptions
  }
}
