<script>
import { ref, watchEffect } from 'vue'

import formSelect from '@/components/form/select.vue'

export default {
  name: 'appListDocument',

  components: {
    formSelect
  },

  props: {
    uploadedFileUrls: {
      type: Array,
      default: () => []
    },
    typeFile: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const fileTypes = ref([])

    watchEffect(() => {
      fileTypes.value = props.uploadedFileUrls.map((file) => file.fileCode || null)
    })

    const openUrlInNewWindow = (url) => {
      window.open(url, '_blank')
    }

    const removeFile = (index) => {
      emit('file-removed', index)
      emitDocumentUpdate()
    }

    const emitDocumentUpdate = () => {
      const updatedDocuments = props.uploadedFileUrls.map((file, index) => ({
        ...file,
        fileCode: fileTypes.value[index] || null
      }))
      emit('update-documents', updatedDocuments)
    }

    const emitFileTypeChange = (index, fileType) => {
      fileTypes.value[index] = fileType
      emitDocumentUpdate()
    }

    return {
      fileTypes,
      emitFileTypeChange,
      openUrlInNewWindow,
      removeFile
    }
  }
}
</script>

<template>
  <div>
    <div class="row g-2 mt-3 files" v-for="(file, index) in uploadedFileUrls" :key="index">
      <div class="col-5 pt-3">
        <p @click="openUrlInNewWindow(file.url)" role="button" class="pt-3">
          <i class="bi bi-file-earmark-medical-fill"></i>
          {{ file.name }}
        </p>
      </div>
      <form-select
        class="col-6 form-input"
        label="Tipo de documento"
        :items="typeFile"
        v-model="fileTypes[index]"
        @change="() => emitFileTypeChange(index, fileTypes[index])"
        :id="'fileTypes' + index"
        :name="'fileTypes' + index"
      />
      <div class="col-1 form-input">
        <button @click="removeFile(index)">
          <i class="bi bi-trash-fill"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.files {
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
