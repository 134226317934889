<template>
  <div>
    <input type="file" ref="fileInput" @change="handleFileChange" style="display: none" />
    <form-button
      class="col-12 form-input"
      buttonColor="secondary"
      title="Anexar Arquivo"
      :loading="loadingFile"
      :isDisabled="loadingFile"
      icon="bi bi-cloud-arrow-up-fill"
      @click="triggerFileInput"
    />
  </div>
</template>

<script>
import { ref, watch } from 'vue'

import formButton from '@/components/form/button.vue'

export default {
  name: 'fileUpload',
  components: {
    formButton
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const fileInput = ref(null)
    const loadingFile = ref(false)

    watch(
      () => props.loading,
      (newValue) => {
        loadingFile.value = newValue
      },
      { deep: true }
    )

    const triggerFileInput = () => {
      fileInput.value.click()
    }

    const handleFileChange = (event) => {
      const file = event.target.files[0]
      if (file) {
        emit('fileSelected', file)
      }
    }

    return {
      fileInput,
      triggerFileInput,
      handleFileChange,
      loadingFile
    }
  }
}
</script>
