import { ref } from 'vue'

export const usePagination = () => {
  const totalPages = ref(0)
  const currentPage = ref(0)
  const isLastPage = ref(false)

  const setPagination = (totalPagesValue, currentPageValue) => {
    totalPages.value = totalPagesValue
    isLastPage.value = totalPages.value > 1 && currentPage.value === totalPages.value - 1
    currentPage.value = currentPageValue
  }

  const nextPage = () => {
    if (!isLastPage.value) {
      currentPage.value += 1
    }
  }

  return {
    totalPages,
    currentPage,
    isLastPage,
    setPagination,
    nextPage
  }
}
