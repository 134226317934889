<script>
import { Tooltip } from 'bootstrap'
import { useField } from 'vee-validate'
import { ref, onMounted, inject } from 'vue'

export default {
  name: 'appSelect',
  props: {
    className: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const { value, errorMessage, handleChange } = useField(() => props.name, props.rules, {
      syncVModel: true,
      validateOnValueUpdate: false
    })

    const alreadySubmittedOnce = inject('alreadySubmittedOnce', ref(false))

    const handleChangeValidation = (value) => {
      handleChange(value, alreadySubmittedOnce.value)
    }

    onMounted(() => {
      new Tooltip(document.body, {
        selector: '[data-bs-toggle="tooltip"]'
      })
    })

    return {
      value,
      errorMessage,
      handleChangeValidation
    }
  }
}
</script>

<template>
  <div :class="className">
    <label :for="id">
      {{ label }}
      <span v-if="tooltip" data-bs-toggle="tooltip" data-bs-placement="left" :title="tooltip">
        <i class="bi bi-info-square-fill"></i>
      </span>
    </label>
    <i v-if="icon" :class="icon"></i>
    <select
      :id="id"
      :name="name"
      :value="value"
      @change="handleChangeValidation"
      :disabled="isDisabled"
      :class="{ invalid: isInvalid || !!errorMessage }"
    >
      <option v-for="(item, i) in items" :key="i" :value="item.key">
        {{ item.name }}
      </option>
    </select>
  </div>
</template>
