<template>
  <div>
    <div v-for="(bank, index) in banks" :key="index" class="row g-2">
      <form-select
        class="col-12 form-input"
        label="Nome do Banco"
        v-model="bank.code"
        :items="bankOptions"
        :id="`banks[${index}].code`"
        :name="`banks[${index}].code`"
        :isDisabled="isDisabled"
      />

      <form-input
        className="col-12 col-md-6 form-input"
        label="Agência"
        v-model="bank.agency"
        :id="`banks[${index}].agency`"
        :name="`banks[${index}].agency`"
        type="text"
        :isDisabled="isDisabled"
      />
      <form-input
        className="col-12 col-md-6 form-input"
        label="Conta"
        v-model="bank.account"
        :id="`banks[${index}].account`"
        :name="`banks[${index}].account`"
        type="text"
        :isDisabled="isDisabled"
      />

      <form-button
        class="col-12 form-input mt-0"
        buttonColor="fifth mt-0 mb-4"
        title="Remover Conta"
        :isDisabled="isDisabled"
        @click.prevent="removeBank(index)"
      />
    </div>

    <div v-if="banks.length > 0" class="row">
      <div class="text-center">
        <img class="line-svg" :src="loadAsset('icon/svg/line_add.svg')" />
      </div>
    </div>
    <form-button
      class="col-12 form-input mt-0"
      buttonColor="secondary mt-1"
      title="Adicionar Conta Bancária"
      :isDisabled="isDisabled"
      @click.prevent="addNewBank"
    />
  </div>
</template>

<script>
import { loadAsset } from '@composables/utils/loadAsset.ts'
import { ref, reactive, onMounted, watchEffect } from 'vue'

import formButton from '@/components/form/button.vue'
import formInput from '@/components/form/input.vue'
import formSelect from '@/components/form/select.vue'
import { useCache } from '@/stores/cache.js'

export default {
  name: 'UserBankAccounts',
  components: {
    formInput,
    formSelect,
    formButton
  },
  props: {
    bankAccounts: {
      type: Array,
      default: () => []
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const banks = reactive([...props.bankAccounts])
    const bankOptions = ref([])

    const cacheBanks = useCache().getBanks

    function addNewBank() {
      banks.push({
        code: '',
        name: '',
        agency: '',
        account: '',
        defaultAccount: false
      })
      emit('bankAccounts', banks)
    }

    function removeBank(index) {
      banks.splice(index, 1)
      emit('bankAccounts', banks)
    }

    onMounted(async () => {
      try {
        bankOptions.value = cacheBanks.map((bank) => ({
          key: bank.code,
          bank: bank.name,
          name: bank.code + ' - ' + bank.name
        }))
      } catch (e) {
        handleGlobalError(e)
      }
    })

    watchEffect(() => {
      banks.forEach((bank) => {
        const selectedBank = bankOptions.value.find((option) => option.key === bank.code)
        if (selectedBank && bank.name !== selectedBank.bank) {
          bank.name = selectedBank.bank
        }
      })
    })

    return {
      banks,
      bankOptions,
      addNewBank,
      removeBank,
      loadAsset
    }
  }
}
</script>

<style lang="scss">
.form-input {
  button {
    &.fifth {
      color: var(--sixth-color);
      border-color: var(--fifth-color);
      background-color: var(--fifth-color);

      &:hover,
      &:focus {
        color: var(--sixth-color);
        border-color: var(--fifth-color);
        background-color: var(--fifth-color);
      }
    }
  }
}
</style>
