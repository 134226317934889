<template>
  <div class="row g-2 mt-3">
    <form-input
      v-for="(field, index) in filterSchema.inputFields"
      :key="`input-${index}`"
      class="col form-input"
      v-bind="field.props"
      :modelValue="localFormValues[field.model]"
      @update:modelValue="(value) => updateFormValue(field.model, value)"
    />

    <form-date
      v-for="(field, index) in filterSchema.dateFields"
      :key="`date-${index}`"
      class="col form-input"
      v-bind="field.props"
      :modelValue="localFormValues[field.model]"
      @update:modelValue="(value) => updateFormValue(field.model, value)"
    />

    <form-select
      v-for="(field, index) in filterSchema.selectFields"
      :key="`select-${index}`"
      class="col form-input"
      v-bind="field.props"
      :items="field.items"
      :modelValue="localFormValues[field.model]"
      @update:modelValue="(value) => updateFormValue(field.model, value)"
    />
  </div>
</template>

<script>
import { reactive, watch } from 'vue'

import formDate from '@/components/form/date.vue'
import formInput from '@/components/form/input.vue'
import formSelect from '@/components/form/select.vue'

export default {
  name: 'FilterSection',
  components: {
    formInput,
    formSelect,
    formDate
  },
  props: {
    filterSchema: {
      type: Object,
      required: true
    },
    formValues: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const localFormValues = reactive({ ...props.formValues })

    const updateFormValue = (model, value) => {
      localFormValues[model] = value
      emit('update:formValues', { model, value })
    }

    watch(
      () => props.formValues,
      (newValues) => {
        Object.assign(localFormValues, newValues)
      },
      { deep: true }
    )

    return {
      localFormValues,
      updateFormValue
    }
  }
}
</script>
