<template>
  <div class="col-12 group-title mt-4">
    {{ label }}
  </div>
</template>
<script>
export default {
  name: 'appTitle',
  props: {
    label: {
      type: String,
      default: ''
    }
  }
}
</script>
