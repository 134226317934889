import { ref } from 'vue'

export const useOperationPendency = () => {
  const pendencyType = ref({
    '': 'Todos',
    OPEN: 'Aberto',
    CLOSE: 'Finalizado'
  })

  const fetchPendencyOptions = () => {
    return Object.keys(pendencyType.value).map((key) => ({
      name: pendencyType.value[key],
      key: key
    }))
  }

  return {
    pendencyType,
    fetchPendencyOptions
  }
}
