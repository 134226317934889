import { ref } from 'vue'

import { handleGlobalError } from '@/services/errorHandler'
import { fetchCertificateDocusign, fetchEnvelopesDocusign } from '@/services/ms'

export function useDocusign() {
  const loadingOpenDocusign = ref({})
  const loadingOpenCertificate = ref({})

  const openDocusignCertificate = async (item) => {
    try {
      loadingOpenCertificate.value[item.id] = true
      const response = await fetchCertificateDocusign(item.id)

      if (response && response.data) {
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
        const pdfUrl = URL.createObjectURL(pdfBlob)
        window.open(pdfUrl, '_blank')
      }
    } catch (e) {
      handleGlobalError(e)
    } finally {
      loadingOpenCertificate.value[item.id] = false
    }
  }

  const openDocusign = async (item) => {
    try {
      loadingOpenDocusign.value[item.id] = true
      const response = await fetchEnvelopesDocusign(item.id)

      if (response && response.data) {
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
        const pdfUrl = URL.createObjectURL(pdfBlob)
        window.open(pdfUrl, '_blank')
      }
    } catch (e) {
      handleGlobalError(e)
    } finally {
      loadingOpenDocusign.value[item.id] = false
    }
  }

  return {
    openDocusign,
    loadingOpenDocusign,
    loadingOpenCertificate,
    openDocusignCertificate
  }
}
