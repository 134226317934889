import { ref } from 'vue'

export const useCurrencyOptions = () => {
  const statusType = ref({
    USD: 'Dólar Americano',
    EUR: 'Euro',
    GBP: 'Libra esterlina',
    JPY: 'Iene',
    AUD: 'Dólar Australiano',
    CHF: 'Franco Suíço',
    CAD: 'Dólar Canadense'
  })

  const fetchCurrencyOptions = () => {
    return Object.keys(statusType.value).map((key) => ({
      name: statusType.value[key],
      key: key
    }))
  }

  return {
    statusType,
    fetchCurrencyOptions
  }
}
