<script>
import { loadAsset } from '@composables/utils/loadAsset.ts'
import gsap from 'gsap'
import { onMounted, computed, ref } from 'vue'
import { useMeta } from 'vue-meta'

import appImg from '@/components/shared/img.vue'
import appModal from '@/components/shared/modal.vue'
import { usePermissions } from '@/composables/utils/usePermissions'
import { fetchDashboard } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'
import { updateAlterMarket } from '@/services/ms'
import { useAuthToken } from '@/stores/authToken.js'
import { useWss } from '@/stores/wss.js'

export default {
  name: 'appDashboard',
  components: {
    appImg,
    appModal
  },

  setup() {
    useMeta({ title: 'Dashboard' })

    const permissions = usePermissions()

    const authentication = useAuthToken()

    const firstName = computed(() => {
      const name = authentication.getName
      return name ? name.split(' ')[0] : ''
    })

    const modalIsShown = ref(false)
    const isMarketClosed = ref(useWss().isMarketClosed)
    const loadingModal = ref(false)
    const modalConfig = ref({})

    const dashboardConfig = [
      { key: 'total', label: 'Cadastros totais', class: 'total' },
      { key: 'approved', label: 'Aprovados', class: 'approved' },
      { key: 'waitingDocs', label: 'Aguardando docs.', class: 'waiting-docs' },
      { key: 'waitingApproved', label: 'Atualizado cliente', class: 'waiting-approved' },
      { key: 'pending', label: 'Pendentes', class: 'pending' }
    ]

    const openExchangeMarket = () => {
      modalConfig.value = {
        origin: 'exchange-market',
        title: 'Você tem certeza?',
        icon: 'bi bi-exclamation-square-fill',
        description: 'Essa mudança pode afetar o uso da plataforma para o cliente.',
        showButtons: true,
        type: 'warning',
        submitButtonText: 'Confirmar',
        backButtonText: 'Cancelar'
      }
      modalIsShown.value = true
    }

    const handleConfirm = async (item) => {
      if (item.origin === 'exchange-market') {
        loadingModal.value = true
        try {
          const status = isMarketClosed.value ? 'close' : 'open'
          await updateAlterMarket(status)
          isMarketClosed.value = !isMarketClosed.value
          modalIsShown.value = false
        } catch (e) {
          handleGlobalError('Erro Exchange Market:', e)
        } finally {
          loadingModal.value = false
        }
      }
    }

    const dashboard = ref({
      total: 0,
      approved: 0,
      waitingDocs: 0,
      waitingApproved: 0,
      pending: 0
    })
    const loading = ref(false)

    const fetchDashboards = async () => {
      loading.value = true
      try {
        const response = await fetchDashboard()
        animateDashboard(response.data.payload)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loading.value = false
      }
    }

    const animateDashboard = (data) => {
      gsap.to(dashboard.value, {
        duration: 1,
        total: data.total,
        approved: data.approved,
        waitingDocs: data.waitingDocs,
        waitingApproved: data.waitingApproved,
        pending: data.pending,
        roundProps: 'total,approved,waitingDocs,waitingApproved,pending'
      })
    }

    onMounted(() => {
      fetchDashboards()
    })

    return {
      permissions,
      dashboardConfig,

      firstName,
      loadAsset,

      dashboard,

      modalConfig,
      modalIsShown,
      loadingModal,
      isMarketClosed,
      openExchangeMarket,
      handleConfirm
    }
  }
}
</script>

<template>
  <div>
    <app-modal
      v-if="modalIsShown"
      :modalConfig="modalConfig"
      :loading="loadingModal"
      @submit="handleConfirm"
      @onClose="modalIsShown = false"
    />

    <div class="row g-2 title">
      <h2 class="col">Bem-vindo, {{ firstName }}!</h2>
      <div v-if="permissions.canOpenMarket()" class="col-12 col-md-3 form-input">
        <button
          :class="['secondary', { 'close-market': isMarketClosed }]"
          @click="openExchangeMarket"
        >
          <i class="bi bi-toggle2-off"></i>
          {{ isMarketClosed ? 'FORÇAR FECHAMENTO' : 'FORÇAR ABERTURA' }}
        </button>
      </div>
      <a class="col-12 col-md-3 form-input" href="https://app.guiacambio.com.br/" target="_blank">
        <button class="guia">
          Acessar
          <app-img
            class="img-responsive logo"
            :src="loadAsset('logo_guia.svg')"
            height="18"
            alt="logo"
          />
        </button>
      </a>
    </div>

    <div class="row g-2 mt-2 dashboard">
      <div class="col" v-for="item in dashboardConfig" :key="item.key">
        <div :class="['item', item.class]">
          <h4>{{ dashboard[item.key] }}</h4>
          <p>{{ item.label }}</p>
        </div>
      </div>
    </div>

    <div class="row welcome-page">
      <div class="col-8 mt-4">
        <p>
          Como parte essencial da equipe <b>transferbank</b>, você desempenha um papel crucial em
          nossa missão de facilitar transferências internacionais para empresas e pessoas físicas.
          Aqui estão algumas informações importantes e atualizações recentes sobre nossa plataforma:
        </p>

        <h4 class="mt-5">Visão Geral do <b>transferbank</b></h4>
        <p>
          Somos uma fintech independente e especializada em câmbio, dedicada a oferecer serviços de
          transferências internacionais com excelência. Com o apoio de investidores renomados e uma
          trajetória de sucesso, já transacionamos mais de R$ 4 bilhões em mais de 30 mil operações,
          atendendo empresas e pessoas físicas.
        </p>

        <h4 class="mt-5">Nossos Diferenciais</h4>
        <ul>
          <li>
            <strong>Agilidade e Segurança:</strong> Realize pagamentos e recebimentos internacionais
            de forma rápida e segura.
          </li>
          <li>
            <strong>Transparência e Economia:</strong> Aproveite a transparência total nas operações
            com câmbio comercial em tempo real.
          </li>
          <li>
            <strong>Suporte Dedicado:</strong> Contamos com suporte em português para auxiliá-lo em
            todas as etapas do processo.
          </li>
        </ul>

        <h4 class="mt-5">Empoderando o Setor</h4>
        <p>
          Na <b>transferbank</b>, estamos comprometidos em empoderar empreendedores e o setor
          financeiro com soluções cambiais de ponta a ponta. Juntos, estamos construindo um futuro
          onde as fronteiras financeiras não limitam o potencial de negócios e indivíduos.
        </p>

        <p>
          {{ firstName }}, sua dedicação e trabalho duro são fundamentais para o nosso sucesso.
          Obrigado por fazer parte da nossa jornada!
        </p>
      </div>
      <div class="col-4 mt-4">
        <h4>Recursos da Plataforma</h4>
        <ul>
          <li>Operações em 7 moedas</li>
          <li>Acesso ao histórico de operações de câmbio</li>
          <li>Radar de notícias relevantes ao mercado</li>
          <li>Gestão de documentos e comprovantes</li>
        </ul>

        <h4 class="mt-5">Novidades</h4>
        <p>
          Estamos constantemente inovando para melhorar a experiência de nossos clientes.
          Mantenha-se atualizado com as últimas notícias e recursos adicionados à plataforma.
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.guia {
  background-color: #000 !important;
  border-color: #000 !important;
}
.form-input {
  > button {
    &.close-market {
      color: #fff !important;
      border-color: crimson !important;
      background-color: crimson !important;
    }
  }
}
.dashboard {
  .item {
    padding: 15px;
    border-radius: 9px;
    background-image: url(../assets/img/dashboard.svg);
    background-repeat: no-repeat;
    background-position: bottom right;

    &.total {
      background-color: #427c5b;
    }

    &.approved {
      background-color: #1dd96d;
    }

    &.waiting-docs {
      background-color: #f25a13;
    }

    &.waiting-approved {
      background-color: #ddb011;
    }

    &.pending {
      background-color: #e23831;
    }

    h4 {
      color: #fff;
      margin-top: 30px;
      margin-bottom: 0;
      font-size: 36px;
      font-weight: 500;
    }

    p {
      color: #fff;
      margin-bottom: 5px;
      line-height: 20px;
      font-weight: 500;
    }
  }
}
.welcome-page {
  ul {
    color: var(--label-color);

    li {
      padding: 4px 0;
    }
  }
}
</style>
