<template>
  <div v-if="exchangeRecipient && bankChangeValue" class="col-12 mt-1 mb-4">
    <div class="operation">
      <div class="row">
        <div class="col-6">
          <label>Nome:</label>
          <p>{{ exchangeRecipient?.name }}</p>
        </div>

        <div class="col-6">
          <label>País:</label>
          <p>{{ exchangeRecipient?.country }}</p>
        </div>

        <div class="col-12">
          <label>Banco:</label>
          <p>{{ exchangeRecipient?.bank }}</p>
        </div>

        <div class="col-6">
          <label>Conta:</label>
          <p>{{ exchangeRecipient?.account }}</p>
        </div>

        <div class="col-6">
          <label>SWIFT:</label>
          <p>{{ exchangeRecipient?.swift }}</p>
        </div>

        <div class="col-6">
          <label>Banco intermediário:</label>
          <p>{{ exchangeRecipient?.intermediaryBankAccount?.name || emptyPlaceholder }}</p>
        </div>

        <div class="col-6">
          <label>Conta:</label>
          <p>{{ exchangeRecipient?.intermediaryBankAccount?.account || emptyPlaceholder }}</p>
        </div>

        <div class="col-6">
          <label>SWIFT:</label>
          <p>{{ exchangeRecipient?.intermediaryBankAccount?.swift || emptyPlaceholder }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExchangeRecipientDetails',
  props: {
    exchangeRecipient: {
      type: Object,
      required: true
    },
    bankChangeValue: {
      type: String,
      required: true
    },
    emptyPlaceholder: {
      type: String,
      default: '-'
    }
  }
}
</script>
