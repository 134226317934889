import { ref } from 'vue'

export const usePlatformLogs = () => {
  const logsType = ref({
    '': 'Todos',
    DELETE_ORDER: 'REMOÇÃO DE OFERTA',
    DELETE_POS_OPERATION: 'REMOÇÃO DE POS OP',
    MOVE_POS_TO_PRE: 'MOVEU POS OP PARA PRE OP',
    DELETE_EXCHANGE_RECIPIENT: 'REMOÇÃO DE CONTA BANCÁRIA',
    CREATE_EXCHANGE_RECIPIENT: 'ADIÇÃO DE CONTA BANCÁRIA',
    CREATE_ORDER: 'CRIOU UMA T.A',
    CANCEL_ORDER: 'REMOVEU UMA T.A',
    SAVE_EXCHANGE_OPERATION: 'SALVOU/SOLICITOU PRÉ-OPERAÇÃO',
    DELETE_EXCHANGE_OPERATION: 'DELETOU UM PRE-OPERAÇÃO',
    CREATE_POS_OPERATION: 'FECHOU UMA OPERAÇÃO',
    UPDATE_POS_OPERATION: 'EDITOU VALORES DA PÓS-OPERAÇÃO',
    UPDATE_COMPLETED_OPERATION: 'EDITOU VALORES DA OPERAÇÃO FINALIZADA',
    CREATE_PROMOTIONAL_CODE: 'CRIAÇÃO DE CÓDIGO PROMOCIONAL',
    UPDATE_PROMOTIONAL_CODE: 'ATUALIZAÇÃO DE CÓDIGO PROMOCIONAL',
    DELETE_PROMOTIONAL_CODE: 'REMOÇÃO DE CÓDIGO PROMOCIONAL',
    DELETE_CLIENT: 'DELETOU UM CLIENTE',
    UPDATE_CLIENT: 'ATUALIZOU UM CLIENTE',
    DELETE_USER: 'DELETOU UM USUÁRIO',
    MARKET_STATUS: 'ATUALIZAÇÃO DO STATUS DO MERCADO', // MS
    BUY_MASK: 'ATUALIZAÇÃO DE MÁSCARA DE COMPRA', // MS
    SELL_MASK: 'ATUALIZAÇÃO DE MÁSCARA DE VENDA', // MS
    OPEN_CHANGE: 'VARIAÇÃO DE ABERTURA DE MERCADO' // MS
  })

  const fetchLogsOptions = () => {
    return Object.keys(logsType.value).map((key) => ({
      name: logsType.value[key],
      key: key
    }))
  }

  return {
    logsType,
    fetchLogsOptions
  }
}
