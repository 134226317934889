export const rolePermissions = {
  SUPER_ADMIN: {
    canOpenMarket: true,

    // OPERATION
    canCreatedData: true,
    canEditData: true,
    canDeleteData: true,
    canManageOperation: true,

    canEditSensitiveInput: true,

    // MENU
    canManageUsers: true,
    canViewDashboard: true,
    canViewTargetTaxes: true,
    canViewPreOperations: true,
    canViewPostOperations: true,
    canViewCompletedOperations: true,
    canViewClients: true,
    canViewHolidays: true,
    canViewParameters: true,
    canViewNewsletter: true,
    canViewGiftCard: true,
    canViewPlatformLogs: true,
    canViewPromoCodes: true,
    canViewPushNotification: true,
    canViewPlatformAlert: true,
    canManageBrokers: true,
    canViewBankerPromoCode: true
  },
  ADMIN: {
    // OPERATION
    canCreatedData: true,
    canEditData: true,
    canDeleteData: false,
    canManageOperation: true,

    // MENU
    canManageUsers: true,
    canViewDashboard: true,
    canViewTargetTaxes: true,
    canViewPreOperations: true,
    canViewPostOperations: true,
    canViewCompletedOperations: true,
    canViewClients: true,
    canViewHolidays: true,
    canViewParameters: true,
    canViewNewsletter: true,
    canViewGiftCard: true,
    canViewPlatformLogs: true,
    canViewPromoCodes: true,
    canViewPushNotification: true,
    canViewPlatformAlert: true,
    canViewBankerPromoCode: true
  },
  SUPER_ADMIN_WHITELABEL: {
    // OPERATION
    canCreatedData: true,
    canEditData: true,
    canDeleteData: true,
    canManageOperation: true,

    // MENU
    canManageUsers: true,
    canViewDashboard: true,
    canViewTargetTaxes: true,
    canViewPreOperations: true,
    canViewPostOperations: true,
    canViewCompletedOperations: true,
    canViewClients: true,
    canViewPlatformLogs: true,
    canViewBankerPromoCode: true
  },
  ADMIN_WHITELABEL: {
    // OPERATION
    canCreatedData: true,
    canEditData: true,
    canDeleteData: false,
    canManageOperation: true,

    // MENU
    canManageUsers: false,
    canViewDashboard: true,
    canViewTargetTaxes: true,
    canViewPreOperations: true,
    canViewPostOperations: true,
    canViewCompletedOperations: true,
    canViewClients: true,
    canViewBankerPromoCode: true
  },
  ADMIN_BANKER_WHITELABEL: {
    // OPERATION
    canCreatedData: false,
    canEditData: true,
    canDeleteData: false,
    canManageOperation: false,

    // MENU
    canManageUsers: false,
    canViewDashboard: true,
    canViewTargetTaxes: true,
    canViewPreOperations: true,
    canViewPostOperations: true,
    canViewCompletedOperations: true,
    canViewClients: true,
    canViewBankerPromoCode: false
  },
  ADMIN_BROKER_WHITELABEL: {
    // OPERATION
    canCreatedData: false,
    canEditData: true,
    canDeleteData: false,
    canManageOperation: false,

    // MENU
    canManageUsers: false,
    canViewDashboard: true,
    canViewTargetTaxes: true,
    canViewPreOperations: true,
    canViewPostOperations: true,
    canViewCompletedOperations: true,
    canViewClients: true,
    canViewBankerPromoCode: true
  }
}
