import { ref } from 'vue'

export const emptyPlaceholder = ref('-- --')

export const clientTypes = {
  PF: 'PF',
  PJ: 'PJ'
}

export const operationTypes = {
  BUYING: 'BUYING',
  SELLING: 'SELLING'
}

export const transactionTypes = {
  TRANSFERBUY: 'TRANSFERBUY',
  TRADEBUY: 'TRADEBUY',
  TRADESELL: 'TRADESELL',
  TRANSFERSELL: 'TRANSFERSELL'
}

export const liquidationTypeOptions = ref([
  { key: 'D0', name: 'D+0' },
  { key: 'D1', name: 'D+1' },
  { key: 'D2', name: 'D+2' }
])

export const maritalStatusOptions = ref([
  {
    name: 'Casado(a)',
    key: 'MARRIED',
    docusign: 'casado'
  },
  {
    name: 'Solteiro(a)',
    key: 'SINGLE',
    docusign: 'solteiro'
  },
  {
    name: 'Viúvo(a)',
    key: 'WIDOWED',
    docusign: 'viuvo'
  },
  {
    name: 'Divorciado(a)',
    key: 'DIVORCED',
    docusign: 'divorciado'
  },
  {
    name: 'União estável',
    key: 'STABLE_UNION',
    docusign: 'uniao_estavel'
  }
])

export const operationNatureTypes = {
  EXPMERC: 'EXPMERC'
}

export const spreadTypes = {
  PERCENTAGE: 'PERCENTAGE',
  MONETARY: 'MONETARY'
}

export const theme = {
  DARK: 'dark',
  LIGHT: 'light'
}

export const preStatusOptions = ref([
  {
    key: 'ALLOW',
    name: 'Liberado'
  },
  {
    key: 'CANCELED',
    name: 'Cancelado'
  },
  {
    key: 'UNDER_ANALYSIS',
    name: 'Em Análise'
  },
  {
    key: 'REFUSED',
    name: 'Recusado'
  },
  {
    key: 'TED_CLIENT',
    name: 'Esperando TED do Cliente'
  },
  {
    key: 'EDITING',
    name: 'Em Edição'
  },
  {
    key: 'OUTSTANDING_DOCUMENTS',
    name: 'Esperando Documentação'
  },
  {
    key: 'RECEIVED',
    name: 'Recebimento'
  },
  {
    key: 'WAITING_RECEIVEMENT',
    name: 'Aguardando Recebimento do Exterior'
  },
  {
    key: 'WAITING_ONBOARDING',
    name: 'Cadastro em andamento'
  }
])

export const posStatusOptions = ref([
  { key: 'OPEN', name: 'Aberto' },
  { key: 'CLOSE', name: 'Finalizado' }
])

export const taxesStatusOptions = ref([
  { key: 'OPEN', name: 'Aberto' },
  { key: 'CLOSED', name: 'Fechado' },
  { key: 'CANCELED', name: 'Cancelado' }
])

export const orderOptions = ref([
  { key: 'NAME', name: 'Nome' },
  { key: 'LAST_UPDATE', name: 'Edição' },
  { key: 'LAST_CLOSED_OPERATION', name: 'Último fechamento' }
])

export const clientTypesOptions = ref([
  { key: '', name: 'Todos' },
  { key: 'PF', name: 'PF' },
  { key: 'PJ', name: 'PJ' }
])

export const typesPromoCodeOptions = ref([
  { key: 'PF', name: 'PF' },
  { key: 'PJ', name: 'PJ' },
  { key: 'PJ_PF', name: 'PF & PJ' }
])

export const clientStatusOptions = ref([
  { key: 'PENDING', name: 'Pendente' },
  { key: 'APPROVED', name: 'Aprovado' },
  { key: 'WAITING_DOCS', name: 'Aguardando Documentos' },
  { key: 'WAITING_APPROVED', name: 'Aguardando Aprovação' }
])

export const spreadTypeOptions = ref([
  { key: 'PERCENTAGE', name: 'Percentual (%)' },
  { key: 'MONETARY', name: 'Monetário (R$)' }
])

export const legalNatureOptions = ref([
  { key: 'MEI', name: 'MEI' },
  { key: 'EI', name: 'Empresário Individual (EI)' },
  { key: 'SLU', name: 'Sociedade Limitada Unipessoal (SLU)' },
  { key: 'LTDA', name: 'Sociedade Empresária Limitada (LTDA)' },
  { key: 'Sociedade Simples', name: 'Sociedade Simples' },
  { key: 'SA', name: 'Sociedade Anônima' }
])

export const documentTypeOptions = ref([
  { key: 'RG', name: 'RG' },
  { key: 'CNH', name: 'CNH' },
  { key: 'Passport', name: 'Passaporte' }
])

export const institutionTypeOptions = ref([
  { key: 'BRAZA', name: 'BRAZA' },
  { key: 'BS2', name: 'BS2' }
])

export const genderOptions = ref([
  { key: 'm', name: 'Masculino' },
  { key: 'f', name: 'Feminino' }
])

export const docusignStatus = {
  CREATED: { name: '', color: 'yellow' },
  SENT: { name: 'E-mail enviado', color: 'yellow' },
  DELIVERED: { name: 'E-mail recebido', color: 'yellow' },
  COMPLETED: { name: 'Concluído', color: 'green' },
  DECLINED: { name: 'Recusado', color: 'red' },
  VOIDED: { name: 'Anulado', color: 'red' }
}

export const typeFile = ref([
  { key: 'documents', name: 'DOCUMENTOS DA OPERAÇÃO' },
  { key: 'exchangeContract', name: 'CONTRATO DOCUSIGN' },
  { key: 'adminDarf', name: 'DARFS DA OPERAÇÃO' },
  { key: 'fileDIOrDUE', name: 'DI/DU-E' },
  { key: 'clientTeds', name: 'TED ENVIADA PELO CLIENTE' },
  { key: 'swiftTeds', name: 'SWIFT CLIENTE' },
  { key: 'fileDarf', name: 'DARF' }
])

export const documentStatusOptions = ref([
  { key: 'APPROVED', name: 'APROVADO' },
  { key: 'PENDING', name: 'PENDENTE' },
  { key: 'REFUSED', name: 'RECUSADO' }
])
