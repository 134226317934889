<template>
  <label @change="toggle">
    <input type="checkbox" class="switch" :id="id" :name="name" :checked="isChecked" />
    {{ label }}
  </label>
</template>

<script>
export default {
  name: 'toggleSwitchMenu',
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    isChecked: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    onToggle: {
      type: Function,
      required: true
    }
  },
  methods: {
    toggle() {
      this.onToggle()
    }
  }
}
</script>

<style scoped lang="scss">
label {
  width: 100%;
  cursor: pointer;

  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type='checkbox'],
    input[type='radio'] {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 22px;
      height: 16px;
      outline: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0 7px 0 0;
      cursor: pointer;
      border: 2px solid $base-border-color-input;
      background: var(--b, var(--background));
      transition:
        background 0.3s,
        border-color 0.3s,
        box-shadow 0.2s;

      &:after {
        content: '';
        opacity: 0;
        width: 12px;
        height: 12px;
        background: var(--secondary-color);
        position: absolute;
        top: 3px;
        left: 3px;
        -webkit-transform: scale(0);
        transform: scale(0);
        transition:
          transform 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2),
          opacity 0.2s;
      }

      &:hover {
        border: 2px solid var(--secondary-color);
      }

      &:checked {
        border: 2px solid var(--secondary-color);

        &:after {
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
    }

    input[type='radio'] {
      border-radius: 100%;

      &:after {
        border-radius: 100%;
      }
    }

    input[type='checkbox'] {
      &:not(.switch) {
        border-radius: 5px;
        &:after {
          border-radius: 2px;
        }
      }

      &.switch {
        width: 30px;
        border-radius: 11px;
        margin-top: 3px;
        &:after {
          opacity: 1;
          left: 2px;
          top: 2px;
          border-radius: 50%;
          width: 8px;
          height: 8px;
          background: $base-border-color-input;
          transform: translateX(0px);
        }
        &:checked {
          background: var(--secondary-color);
          &:after {
            background: var(--fourth-color);
            transform: translateX(14px);
          }
        }
      }
    }
  }
}
</style>
