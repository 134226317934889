import axios from 'axios'

import { handleGlobalError } from '@/services/errorHandler'

class searchNatureDocsByBacenCode {
  constructor() {
    this.apiClient = axios.create({
      baseURL: 'https://api.guiacambio.com.br/graphql',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': process.env.GUIA_CAMBIO_API_KEY
      }
    })
  }

  async searchBacenCode(searchTerm) {
    const data = {
      query: `
        query FilterNodeNavigation($natureCode: String) {
          filterNodeNavigation(natureCode: $natureCode) {
            id
            id_parent
            sub_type
            title
            type
            details {
              definition {
                description
              }
            }
          }
        }`,
      variables: { natureCode: searchTerm }
    }

    try {
      const response = await this.apiClient.post('', data)
      return response.data
    } catch (e) {
      handleGlobalError(e)
      throw e
    }
  }
}

export default new searchNatureDocsByBacenCode()
