import * as yup from 'yup'

export const greatherThan = () => {
  return yup
    .string()
    .required()
    .test('is-greater-than-zero', 'Value must be greater than zero', (value) => {
      const number = parseFloat(value.replace(',', '.'))
      return number > 0
    })
}

export const isEqualFloat = (a, b, p) => {
  return Math.abs(a - b) < Math.pow(10, -p)
}
