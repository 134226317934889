import { defineStore } from 'pinia'

export const useWss = defineStore('wss', {
  state() {
    return {
      exchangeRate: {},
      market: false,
      PTAX: null,
      newPreOperation: {
        id: null,
        clientName: null,
        principalAmount: null,
        type: null,
        currencyType: null
      },
      newPosOperation: {
        id: null,
        clientName: null,
        principalAmount: null,
        type: null,
        currencyType: null
      },
      newClient: {
        id: null,
        clientName: null
      }
    }
  },

  getters: {
    getExchangeRate() {
      return this.exchangeRate
    },
    isMarketClosed() {
      return this.market
    },
    getPTAX() {
      return this.PTAX
    },
    getNewPreOperation() {
      return this.newPreOperation
    },
    getNewPosOperation() {
      return this.newPosOperation
    },
    getNewClient() {
      return this.newClient
    }
  },
  persist: true
})
