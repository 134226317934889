<script>
import { hasInputAllowed } from '@composables/utils/useWhitelabelService.js'
import searchNatureDocsByBacenCode from '@services/guiaCambioApiSearch'
import { getTime, format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useForm } from 'vee-validate'
import { ref, provide, watch, computed } from 'vue'
import { useMeta } from 'vue-meta'
import * as yup from 'yup'

import appExchangeRecipientDetails from './_exchangeRecipientDetails.vue'

import formButton from '@/components/form/button.vue'
import formInput from '@/components/form/input.vue'
import formMoney from '@/components/form/money.vue'
import formSearch from '@/components/form/search.vue'
import formSelect from '@/components/form/select.vue'
import formTextarea from '@/components/form/textarea.vue'
import formHomeBroker from '@/components/modalHomeBroker/formHomeBroker.vue'
import bacenPreview from '@/components/shared/bacenPreview.vue'
import appBankList from '@/components/shared/bankList.vue'
import appImportDocument from '@/components/shared/importDocument.vue'
import appListDocument from '@/components/shared/listDocument.vue'
import appTitle from '@/components/shared/title.vue'
import { configClientMoney, configIof, configIr } from '@/composables/utils/moneyConfig'
import { useCurrencyOptions } from '@/composables/utils/useCurrencyOptions'
import {
  preStatusOptions,
  clientTypes,
  transactionTypes,
  operationTypes,
  emptyPlaceholder
} from '@/composables/utils/useGlobalConfig'
import useModal from '@/composables/utils/useModal'
import { useOperationStatus } from '@/composables/utils/useOperationStatus'
import { useSearchThrottle } from '@/composables/utils/useSearch'
import { fetchClients, fetchClientBank, uploadDocument } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'
import { useAuthToken } from '@/stores/authToken.js'
import { useCache } from '@/stores/cache.js'

export default {
  name: 'formModalPre',

  components: {
    appTitle,
    formSearch,
    formInput,
    formMoney,
    formSelect,
    formButton,
    appImportDocument,
    appListDocument,
    appBankList,
    formTextarea,
    bacenPreview,
    formHomeBroker,
    appExchangeRecipientDetails
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    initialFormData: {
      type: Object,
      default: () => ({})
    }
  },

  emits: ['onClose', 'onSubmitted'],

  setup(props, { emit }) {
    useMeta({ title: props.title })

    const { contentActive, closeModal } = useModal(emit)

    const editModeEnabled = ref(false)

    const modalHomeBroker = ref(false)
    const homeBrokerFormData = ref('')

    const { statusType } = useOperationStatus()
    const cacheNatureType = useCache().getNatureType
    const cacheResponsible = useCache().getOperators

    const clientNameValue = ref('')
    const clientApiResult = ref([])
    const clientId = ref('')
    const clientType = ref('')

    const exchangeRecipient = ref({})

    const natureTypeValue = ref('')
    const operationOptions = ref([])

    const operationType = ref('')

    const operationNatureValue = ref('')
    const operationNatureOptions = ref([])

    const operationSubnatureValue = ref('')
    const operationSubnatureOptions = ref([])

    const bacenCodeValue = ref('')

    const invoiceNumberValue = ref('')
    const payerValue = ref('')
    const principalAmountValue = ref('')

    const iofValue = ref('')
    const closingForecastValue = ref('')

    const bankChangeValue = ref('')

    const financialInstitutionValue = ref('')
    const financialInstitutionOptions = ref([])

    const responsibleValue = ref('')
    const responsibleOptions = ref([])

    const siscomexProtocolValue = ref('')

    const statusValue = ref('')
    const oldStatus = ref('')

    const operationObservationValue = ref('')
    const operationObservations = ref([])

    const documents = ref([])
    const selectedDocTypes = ref([])
    const typeFile = ref([])

    const loadingUpload = ref(false)
    const uploadedFileUrls = ref([])

    const currencyTypeValue = ref('')
    const { fetchCurrencyOptions } = useCurrencyOptions()
    const currencyOptions = fetchCurrencyOptions()

    const bankOptions = ref([])

    const authentication = useAuthToken()

    const canOperate = authentication.getCanOperate

    const responseBacenCode = ref({})

    const isNatureDisabled = ref(false)

    const documentsUpdated = ref(false)

    const alreadySubmittedOnce = ref(false)
    provide('alreadySubmittedOnce', alreadySubmittedOnce)

    useSearchThrottle(bacenCodeValue, () => {
      const config = {
        search: bacenCodeValue.value
      }
      searchBacenCode(config)
    })

    const searchBacenCode = async (searchTerm) => {
      try {
        const result = await searchNatureDocsByBacenCode.searchBacenCode(searchTerm.search)
        responseBacenCode.value = result
      } catch (e) {
        handleGlobalError(e)
      }
    }

    useSearchThrottle(clientNameValue, () => {
      const config = {
        search: clientNameValue.value
      }
      searchClientByName(config)
    })

    const formatOptionName = (item) => {
      if (item.clientType === clientTypes.PF) {
        if (
          item.subType === transactionTypes.TRANSFERBUY ||
          item.subType === transactionTypes.TRADEBUY
        ) {
          return `${item.name} - Envio`
        } else if (
          item.subType === transactionTypes.TRADESELL ||
          item.subType === transactionTypes.TRANSFERSELL
        ) {
          return `${item.name} - Recebimento`
        }
      }
      return item.name
    }

    const searchClientByName = async (searchTerm) => {
      try {
        const response = await fetchClients(searchTerm)
        clientApiResult.value = response.data.payload
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const selectedClient = async (data) => {
      clientNameValue.value = data.clientName
      clientId.value = data.clientId
      clientType.value = data.clientType
      natureTypeValue.value = ''
      operationNatureValue.value = ''
      operationSubnatureValue.value = ''
      operationType.value = ''
      bacenCodeValue.value = ''
      operationOptions.value = []
      operationNatureOptions.value = []
      operationSubnatureOptions.value = []

      financialInstitutionOptions.value = (data.allowedFinancialInstitutions || []).map((item) => ({
        ...item,
        key: item.institution,
        name: item.institution
      }))

      const filteredData = cacheNatureType.filter((item) => item.clientType === data.clientType)
      operationOptions.value = filteredData.map((item) => ({
        ...item,
        key: item.subType,
        name: formatOptionName(item)
      }))
    }

    const checkIsNatureDisabled = () => {
      if (operationNatureValue.value) {
        const selectedNature = operationNatureOptions.value.find(
          (nature) => nature.code === operationNatureValue.value
        )
        isNatureDisabled.value = selectedNature ? selectedNature.isDisabled : false
      }
    }

    const { handleSubmit } = useForm({
      validationSchema: yup.object({
        search: yup.string().required(),
        operationType: yup.string().required(),
        nature: yup
          .string()
          .required()
          .test(() => {
            return !isNatureDisabled.value
          }),

        transacted: yup
          .string()
          .required()
          .test('is-greater-than-zero', 'Transacted must be greater than zero', (value) => {
            const number = parseFloat(value.replace(',', '.'))
            return number > 0
          }),
        currency: yup.string().required(),

        bank: bankOptions.value.length > 0 ? yup.string().required() : yup.string(),
        financialInstitution: yup.string().required(),
        status: yup.string().required()
      })
    })

    const onSubmitAction = () => {
      alreadySubmittedOnce.value = true
      return onSubmit()
    }

    const onSubmit = handleSubmit(async () => {
      let closeDateTimestamp
      const parsedDate = new Date(closingForecastValue.value)
      closeDateTimestamp = getTime(parsedDate)

      const body = {
        ...props.initialFormData,
        clientName: clientNameValue.value,
        clientId: clientId.value,
        clientType: clientType.value,
        subType: natureTypeValue.value,
        operationNature: operationNatureValue.value,
        operationSubnature: operationSubnatureValue.value,
        type: operationType.value,
        bacenCode: bacenCodeValue.value,
        iof: parseFloat(iofValue.value / 100),
        currencyType: currencyTypeValue.value,
        principalAmount: parseFloat(principalAmountValue.value),
        invoiceNumber: invoiceNumberValue.value,
        payerName: payerValue.value,
        closeDate: closeDateTimestamp,
        financialInstitution: financialInstitutionValue.value,
        siscomexProtocol: siscomexProtocolValue.value,
        status: statusValue.value,
        createdBy: 'ADMIN',

        documents: documents.value,
        exchangeContract: null,
        fileDarf: null,
        payTo: {},
        tedClient: [],
        tedDourada: [],

        backOfficeUserId: responsibleValue.value,

        exchangeRecipient: exchangeRecipient.value,

        motivation: operationObservationValue.value
      }
      emit('onSubmitted', body)
    })

    watch(natureTypeValue, (newVal) => {
      const selectedOperation = operationOptions.value.find((item) => item.key === newVal) || ''

      if (selectedOperation && selectedOperation.natures) {
        operationNatureOptions.value = selectedOperation.natures.map((nature) => ({
          ...nature,
          key: nature.code,
          name: nature.name
        }))
      } else {
        operationNatureOptions.value = []
      }

      operationType.value = selectedOperation.type

      bankOptions.value = []
      if (operationType.value === operationTypes.BUYING) createdBank()
    })

    watch(operationNatureValue, (newVal) => {
      const selectedNature = operationNatureOptions.value.find((item) => item.key === newVal)
      bacenCodeValue.value = selectedNature?.bacenCode || bacenCodeValue.value

      if (selectedNature && selectedNature.subNatures.length > 0) {
        operationSubnatureOptions.value = selectedNature.subNatures.map((subNature) => ({
          ...subNature,
          key: subNature.code,
          name: subNature.name
        }))
      } else {
        operationSubnatureOptions.value = []

        if (selectedNature?.docTypes?.length > 0) {
          selectedDocTypes.value = selectedNature.docTypes
          typeFile.value = selectedNature.docTypes.map((subNature) => ({
            key: subNature.code,
            name: subNature.name
          }))
        }
      }
      checkIsNatureDisabled()
    })

    watch(operationSubnatureValue, (newVal) => {
      const selectedSubNature = operationSubnatureOptions.value.find((item) => item.code === newVal)
      bacenCodeValue.value = selectedSubNature?.bacenCode || bacenCodeValue.value

      if (selectedSubNature && selectedSubNature && selectedSubNature.docTypes) {
        selectedDocTypes.value = selectedSubNature.docTypes

        typeFile.value = selectedSubNature.docTypes.map((subNature) => ({
          key: subNature.code,
          name: subNature.name
        }))
      }
    })

    watch(bankChangeValue, (newVal) => {
      exchangeRecipient.value = bankOptions.value.find((item) => item.key === newVal)
    })

    watch(
      () => props.initialFormData,
      async (newFormData) => {
        if (Object.keys(newFormData).length === 0) return
        editModeEnabled.value = true
        try {
          const filteredData = cacheNatureType.filter(
            (item) => item.clientType === newFormData.clientType
          )
          operationOptions.value = filteredData.map((item) => ({
            ...item,
            key: item.subType,
            name: formatOptionName(item)
          }))

          natureTypeValue.value = newFormData.subType

          financialInstitutionOptions.value = (newFormData.institutionOptions || []).map(
            (item) => ({
              ...item,
              key: item.institution,
              name: item.institution
            })
          )

          responsibleOptions.value = cacheResponsible.map((item) => ({
            key: item.id,
            name: item.userName
          }))
        } catch (e) {
          handleGlobalError(e)
        }

        if (newFormData != null) {
          clientType.value = newFormData.clientType
          clientNameValue.value = newFormData.clientName || ''
          clientId.value = newFormData.clientId
          operationNatureValue.value = newFormData.operationNature
          operationSubnatureValue.value = newFormData.operationSubnature
          bacenCodeValue.value = newFormData.bacenCode || ''
          invoiceNumberValue.value = newFormData.invoiceNumber
          principalAmountValue.value = newFormData.principalAmount
          currencyTypeValue.value = newFormData.currencyType
          iofValue.value = newFormData.iof * 100
          payerValue.value = newFormData.payerName

          closingForecastValue.value = newFormData.closeDate || ''

          if (newFormData && newFormData.documents) {
            documents.value = newFormData.documents
            uploadedFileUrls.value = newFormData.documents
          }

          bankChangeValue.value = newFormData.exchangeRecipient?.id || ''
          await new Promise((resolve) => setTimeout(resolve, 100))
          exchangeRecipient.value = newFormData.exchangeRecipient
          siscomexProtocolValue.value = newFormData.siscomexProtocol

          responsibleValue.value = newFormData.backOfficeUserId

          financialInstitutionValue.value = newFormData.financialInstitution
          operationObservations.value = newFormData.motivations
          oldStatus.value = newFormData.status
          statusValue.value = newFormData.status
        }
      },
      { immediate: true }
    )

    const createdBank = async () => {
      try {
        const response = await fetchClientBank(clientId.value)
        bankOptions.value = (response.data.payload || []).map((item) => ({
          ...item,
          key: item.id,
          name: item.name
        }))
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const updateBank = async (id) => {
      exchangeRecipient.value = bankOptions.value.find((item) => item.key === id)
    }

    const handleFileSelected = async (files) => {
      for (const file of files) {
        loadingUpload.value = true
        const fileUrl = await uploadFileToS3(file, uploadDocument)
        uploadedFileUrls.value.push(fileUrl)
        loadingUpload.value = false
      }
    }

    const uploadFileToS3 = async (file, uploadApiFunction) => {
      const formData = new FormData()
      formData.append('file', file)

      try {
        const response = await uploadApiFunction(formData)
        return {
          name: file.name,
          url: response.data.payload.url
        }
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const handleDocumentUpdate = (updatedDocuments) => {
      documents.value = updatedDocuments
      documentsUpdated.value = true
    }

    const removeFile = (index) => {
      if (index >= 0 && index < uploadedFileUrls.value.length) {
        uploadedFileUrls.value.splice(index, 1)
      }
    }

    const formatDate = (dateString) => {
      const zonedDate = utcToZonedTime(new Date(dateString), 'UTC')

      return format(zonedDate, "dd/MM/yy 'às' HH:mm", { timeZone: 'UTC' })
    }

    const showOperationObservation = computed(() => {
      return editModeEnabled.value && statusValue.value !== oldStatus.value
    })

    const handleHomeBroker = async () => {
      try {
        homeBrokerFormData.value = props.initialFormData
        modalHomeBroker.value = true
      } catch (e) {
        handleGlobalError(e)
      }
    }

    return {
      clientNameValue,
      clientId,
      clientApiResult,
      operationType,
      natureTypeValue,
      operationOptions,
      operationNatureValue,
      operationNatureOptions,
      operationSubnatureValue,
      operationSubnatureOptions,
      selectedDocTypes,
      bacenCodeValue,
      responseBacenCode,
      invoiceNumberValue,
      payerValue,
      principalAmountValue,
      configClientMoney,
      iofValue,
      configIof,
      configIr,
      closingForecastValue,

      currencyTypeValue,
      currencyOptions,

      bankChangeValue,

      financialInstitutionValue,
      financialInstitutionOptions,

      responsibleValue,
      responsibleOptions,

      statusValue,
      preStatusOptions,

      closeModal,
      onSubmitAction,
      contentActive,

      handleFileSelected,
      handleDocumentUpdate,
      uploadedFileUrls,
      loadingUpload,
      removeFile,
      typeFile,

      bankOptions,
      exchangeRecipient,
      updateBank,

      siscomexProtocolValue,

      editModeEnabled,
      showOperationObservation,
      operationObservationValue,
      operationObservations,
      formatDate,
      statusType,

      selectedClient,
      operationTypes,
      emptyPlaceholder,
      hasInputAllowed,
      isNatureDisabled,

      homeBrokerFormData,
      modalHomeBroker,
      handleHomeBroker,
      canOperate
    }
  }
}
</script>

<template>
  <div class="box-content d-flex justify-content-end">
    <div :class="['back', { active: contentActive }]" @click="closeModal()"></div>
    <div :class="['content', { active: contentActive }]">
      <h4>{{ title }}</h4>

      <div class="col-12">
        <div class="row g-1">
          <form-search
            className="col-12 form-input"
            label="Cliente"
            v-model="clientNameValue"
            id="search"
            name="search"
            type="search"
            icon="bi bi-search"
            placeholder="Nome do cliente"
            :results="clientApiResult"
            @onSelect="selectedClient"
            :isDisabled="!hasInputAllowed()"
          />

          <form-select
            class="col-12 form-input"
            label="Tipo de operação"
            v-model="natureTypeValue"
            :items="operationOptions"
            id="operationType"
            name="operationType"
            :isDisabled="!hasInputAllowed() || operationOptions.length === 0"
          />

          <form-select
            class="col-12 form-input"
            label="Natureza"
            v-model="operationNatureValue"
            :items="operationNatureOptions"
            id="nature"
            name="nature"
            :isDisabled="!hasInputAllowed() || operationNatureOptions.length === 0"
          />

          <form-select
            v-if="operationSubnatureOptions.length > 0"
            class="col-12 form-input"
            label="Sub natureza"
            v-model="operationSubnatureValue"
            :items="operationSubnatureOptions"
            id="subNature"
            name="subNature"
            :isDisabled="!hasInputAllowed() || operationSubnatureOptions.length === 0"
          />

          <div class="col-12 form-input">
            <div v-if="isNatureDisabled" class="alert alert-warning" role="alert">
              <i class="bi bi-exclamation-triangle-fill"></i>
              A natureza selecionada foi descontinuada.
            </div>
          </div>

          <form-input
            className="col-12 form-input"
            label="Código da Natureza"
            v-model="bacenCodeValue"
            id="bacenCode"
            name="bacenCode"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <bacen-preview
            v-if="responseBacenCode?.data?.filterNodeNavigation != null"
            :items="responseBacenCode?.data?.filterNodeNavigation"
          />

          <app-title label="DETALHES DA OPERAÇÃO" />
          <form-input
            className="col-12 form-input"
            v-model="invoiceNumberValue"
            label="Invoice"
            id="invoice"
            name="invoice"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            v-if="operationType === operationTypes.SELLING"
            className="col-12 form-input"
            v-model="payerValue"
            label="Ordenante"
            id="payer"
            name="payer"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-select
            class="col-12 form-input"
            label="Moeda selecionada"
            v-model="currencyTypeValue"
            :items="currencyOptions"
            id="currency"
            name="currency"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="principalAmountValue"
            :label="`Valor ${
              operationType == operationTypes.SELLING
                ? 'para recebimento - '
                : operationType == operationTypes.BUYING
                  ? 'para envio - '
                  : ''
            } ${currencyTypeValue}`"
            id="transacted"
            name="transacted"
            :config="configClientMoney"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="iofValue"
            label="IOF da operação"
            id="iofOperation"
            name="iofOperation"
            :config="configIof"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            v-if="operationType === operationTypes.BUYING"
            className="col-12 form-input"
            label="Protocolo SISCOMEX"
            v-model="siscomexProtocolValue"
            id="siscomexProtocol"
            name="siscomexProtocol"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <app-title label="DOCUMENTAÇÃO" />

          <app-import-document
            class="col-12"
            id="docs"
            name="docus"
            @fileSelected="handleFileSelected"
            accept="image/*, .pdf, .word"
            :loading="loadingUpload"
          />

          <app-list-document
            class="col-12"
            :uploadedFileUrls="uploadedFileUrls"
            :typeFile="typeFile"
            @update-documents="handleDocumentUpdate"
            @file-removed="removeFile"
          />

          <div v-if="selectedDocTypes.length > 0" class="doc-type">
            <h3>Os documentos necessários de acordo com a natureza da sua operação são:</h3>
            <ol>
              <li v-for="docType in selectedDocTypes" :key="docType.id">
                {{ docType.name }}
              </li>
            </ol>
          </div>

          <app-bank-list
            v-if="bankOptions.length > 0"
            v-model="bankChangeValue"
            :clientId="clientId"
            :initialBanks="bankOptions"
            :isDisabled="!hasInputAllowed() || bankOptions.length === 0"
            @edit="updateBank"
          />

          <app-exchange-recipient-details
            v-if="bankChangeValue != ''"
            :bankChangeValue="bankChangeValue"
            :exchangeRecipient="exchangeRecipient"
            :emptyPlaceholder="emptyPlaceholder"
          />

          <form-select
            class="col-12 form-input"
            label="Instituição Financeira (Verifique o limite por banco)"
            v-model="financialInstitutionValue"
            :items="financialInstitutionOptions"
            id="financialInstitution"
            name="financialInstitution"
            :isDisabled="!hasInputAllowed()"
          />

          <form-select
            class="col-12 form-input"
            label="Operador responsável"
            v-model="responsibleValue"
            :items="responsibleOptions"
            id="responsible"
            name="responsible"
            :isDisabled="!hasInputAllowed()"
          />

          <form-select
            class="col-12 form-input"
            label="Status"
            v-model="statusValue"
            :items="preStatusOptions"
            id="status"
            name="status"
            :isDisabled="!hasInputAllowed()"
          />

          <form-textarea
            v-if="hasInputAllowed() && showOperationObservation"
            className="col-12 form-input"
            label="Observações"
            v-model="operationObservationValue"
            id="operationObservation"
            name="operationObservation"
            placeholder="Descreva o motivo para a atualização do status."
            type="text"
          />

          <ul v-if="operationObservations.length > 0" class="col-12 motivations">
            <li v-for="item in operationObservations" :key="item.id">
              <div>
                <span>Alterado em {{ formatDate(item.createdAt) }}</span>
                <p class="mb-0">
                  {{ item.description }}
                </p>
                <span
                  >Status alterado de <b>{{ statusType[item.oldStatus] }}</b> para
                  <b>{{ statusType[item.newStatus] }}</b></span
                >
              </div>
            </li>
          </ul>

          <form-button
            v-if="canOperate && editModeEnabled"
            class="col-12 form-input home-broker mb-2"
            title="Home Broker"
            @clicked="handleHomeBroker"
          />

          <form-button
            class="col-12 form-input"
            title="Enviar"
            :loading="loading"
            :isDisabled="!hasInputAllowed()"
            @clicked="onSubmitAction"
          />
        </div>
      </div>
    </div>
  </div>
  <form-home-broker
    v-if="modalHomeBroker"
    title="Fechamento de operação"
    :initialFormData="homeBrokerFormData"
    @onCloseHomeBroker="modalHomeBroker = false"
    @preClose="closeModal()"
  />
</template>

<style lang="scss">
.form-input {
  &.home-broker {
    button {
      background-color: #a6a606 !important;
      border-color: #a6a606 !important;
      color: #ffffff;
    }
  }
}

.motivations {
  > li {
    padding: 10px 10px 0px 15px;
    border-radius: 9px;

    span {
      color: var(--label-color);
      font-size: 9px;
    }

    p {
      color: var(--sixth-color);
      font-size: 14px;
    }

    &::before {
      content: '';
      background: var(--fifth-color);
      display: block;
      height: 81px;
      left: 42px;
      margin-top: 20px;
      position: absolute;
      width: 1px;
    }

    div {
      &::after {
        content: '';
        border-radius: 50%;
        height: 9px;
        width: 9px;
        background: var(--fifth-color);
        display: block;
        position: absolute;
        left: 38px;
        margin-top: -65px;
      }
    }

    &:last-child {
      &::before {
        content: none;
      }
    }
  }
}

.doc-type {
  h3 {
    color: var(--sixth-color);
    font-size: 16px;
    margin: 0 0 10px;
    font-weight: 500;
  }
  ol {
    color: var(--sixth-color);
    background-color: #e1e1e114;
    border-radius: 9px;
    padding: 10px 20px 10px 25px;
    font-size: 14px;
    line-height: 28px;
  }
}
</style>
@/services/guiaCambioApiClient
