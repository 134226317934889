export const useNoScroll = () => {
  const setNoScroll = (isNoScroll) => {
    if (isNoScroll) {
      document.body.classList.add('no-scroll')
    } else {
      setTimeout(() => {
        document.body.classList.remove('no-scroll')
      }, 300)
    }
  }

  return { setNoScroll }
}
