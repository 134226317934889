<template>
  <div v-if="editModeEnabled && operationType === operationTypes.SELLING" class="col-12">
    <app-title label="TRANSFERÊNCIA PARA CONTA DO CLIENTE" />
    <div class="operation">
      <div class="row">
        <div class="col-12">
          <label>Banco:</label>
          <p>{{ tedClientDetail?.name || emptyPlaceholder }}</p>
        </div>
        <div class="col-6">
          <label>Agência:</label>
          <p>{{ tedClientDetail?.agency || emptyPlaceholder }}</p>
        </div>
        <div class="col-6">
          <label>Conta:</label>
          <p>{{ tedClientDetail?.account || emptyPlaceholder }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label>Referente a:</label>
          <p>{{ currencyTypeValue }} {{ numberFormat(principalAmountValue, 'pt-BR') }}</p>
        </div>
        <div class="col-6">
          <label>Invoice:</label>
          <p>{{ invoiceNumberValue || emptyPlaceholder }}</p>
        </div>

        <div class="col-6">
          <label>Ordenante:</label>
          <p>{{ payerNameValue || emptyPlaceholder }}</p>
        </div>
        <div class="col-6">
          <label>IOF:</label>
          <p>R$ {{ numberFormat(operationDetail.iofValue, 'pt-BR') || emptyPlaceholder }}</p>
        </div>

        <div class="col-6">
          <label>IR:</label>
          <p>R$ {{ numberFormat(operationDetail.irValue, 'pt-BR') || emptyPlaceholder }}</p>
        </div>
        <div class="col-6">
          <label>Tarifa:</label>
          <p>{{ numberFormat(operationDetail.clientTaxRS, 'pt-BR') }}</p>
        </div>

        <div class="col-6">
          <label>Transferir:</label>
          <p :class="operationType === operationTypes.SELLING ? 'color-sell' : 'color-buy'">
            R$ {{ numberFormat(operationDetail.finalPriceRS, 'pt-BR') }} em
            {{ liquidationTypeValue }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appTitle from '@/components/shared/title.vue'

export default {
  name: 'TransferenciaCliente',

  components: {
    appTitle
  },

  props: {
    editModeEnabled: {
      type: Boolean,
      default: false
    },
    operationType: {
      type: String,
      required: true
    },
    operationTypes: {
      type: Object,
      required: true
    },
    tedClientDetail: {
      type: Object,
      default: () => ({})
    },
    emptyPlaceholder: {
      type: String,
      default: '-'
    },
    currencyTypeValue: {
      type: String,
      default: ''
    },
    principalAmountValue: {
      type: [Number, String],
      default: 0
    },
    invoiceNumberValue: {
      type: String,
      default: ''
    },
    payerNameValue: {
      type: String,
      default: ''
    },
    operationDetail: {
      type: Object,
      default: () => ({})
    },
    liquidationTypeValue: {
      type: String,
      default: ''
    },
    numberFormat: {
      type: Function,
      required: true
    }
  },

  setup(props) {
    return {
      ...props
    }
  }
}
</script>
