<template>
  <div>
    <app-title v-if="!editModeEnabled" label="DADOS BANCÁRIOS DO BENEFICIÁRIO" />

    <app-bank-list
      v-if="bankOptions.length > 0 && !editModeEnabled"
      :value="bankChangeValue"
      :clientId="clientId"
      :initialBanks="bankOptions"
      :isDisabled="bankOptions.length === 0"
      @input="updateBank"
    />
    <app-exchange-recipient-details
      v-if="exchangeRecipient && bankChangeValue"
      :exchange-recipient="exchangeRecipient"
      :bank-change-value="bankChangeValue"
      :empty-placeholder="emptyPlaceholder"
    />
  </div>
</template>

<script>
import appExchangeRecipientDetails from './_exchangeRecipientDetails.vue'

import appBankList from '@/components/shared/bankList.vue'
import appTitle from '@/components/shared/title.vue'

export default {
  components: {
    appExchangeRecipientDetails,
    appBankList,
    appTitle
  },
  props: {
    bankOptions: {
      type: Array,
      required: true
    },
    bankChangeValue: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    },
    exchangeRecipient: {
      type: Object,
      required: true
    },
    emptyPlaceholder: {
      type: String,
      default: '-'
    },
    editModeEnabled: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    updateBank(id) {
      this.$emit('input', id)
    }
  }
}
</script>
