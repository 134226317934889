import { ref } from 'vue'

import { handleGlobalError } from '@/services/errorHandler'

export const useS3Uploader = () => {
  const error = ref(null)

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const convertBase64ToBlob = (base64Image) => {
    const parts = base64Image.split('base64,')
    const imageType = parts[0].split(':')[1]
    const decodedData = atob(parts[1])
    const uInt8Array = new Uint8Array(decodedData.length)
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i)
    }
    return new Blob([uInt8Array], { type: imageType })
  }

  const uploadFileToS3 = async (file, uploadApiFunction) => {
    try {
      const base64 = await fileToBase64(file.value)

      const blob = convertBase64ToBlob(base64)

      const response = await uploadApiFunction(blob)
      return response.data.payload.url
    } catch (e) {
      handleGlobalError(e)
      throw error
    }
  }

  return { uploadFileToS3, error }
}
