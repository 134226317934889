<script>
import appLoading from '@/components/shared/loading.vue'

export default {
  name: 'appButton',

  components: {
    appLoading
  },
  props: {
    className: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    buttonColor: {
      type: String,
      default: 'secondary'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const buttonClicked = () => {
      emit('clicked')
    }
    return {
      buttonClicked
    }
  }
}
</script>

<template>
  <div :class="className">
    <button :class="buttonColor" @click="buttonClicked" :disabled="isDisabled">
      <app-loading v-if="loading" class="mx-auto" :duration="'2.3s'" />
      <div v-else class="button-content">
        <i v-if="icon" :class="icon"></i>
        {{ title }}
      </div>
    </button>
  </div>
</template>
