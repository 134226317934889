<script>
import { parseISO, format, isValid } from 'date-fns'
import { useField } from 'vee-validate'
import { ref, computed, inject, watch } from 'vue'

export default {
  name: 'appDate',
  props: {
    className: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number, Date],
      default: ''
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { value, errorMessage, handleChange } = useField(() => props.name, props.rules, {
      syncVModel: true,
      validateOnValueUpdate: false
    })

    const alreadySubmittedOnce = inject('alreadySubmittedOnce', ref(false))

    const handleChangeValidation = (selectedDate) => {
      let formattedDate

      if (selectedDate instanceof Date && !isNaN(selectedDate)) {
        formattedDate = format(selectedDate, 'yyyy-MM-dd')
      }
      if (formattedDate) {
        emit('update:modelValue', formattedDate)
        handleChange(formattedDate, alreadySubmittedOnce.value)
      }
    }

    const valueInput = computed(() => {
      if (value.value) {
        let parsedDate

        if (typeof value.value === 'string' && value.value.includes('-')) {
          parsedDate = parseISO(value.value)
        } else if (typeof value.value === 'string' || value.value instanceof Date) {
          parsedDate = new Date(value.value)
        }

        if (isValid(parsedDate)) {
          return format(parsedDate, 'dd/MM/yyyy')
        } else {
          return 'DD/MM/YYYY'
        }
      } else {
        return 'DD/MM/YYYY'
      }
    })

    watch(
      () => props.modelValue,
      (newVal) => {
        if (newVal) {
          let formattedDate

          if (newVal instanceof Date || (typeof newVal === 'string' && !newVal.includes('-'))) {
            formattedDate = format(new Date(newVal), 'yyyy-MM-dd')
          } else if (typeof newVal === 'string' && newVal.includes('-')) {
            formattedDate = format(parseISO(newVal), 'yyyy-MM-dd')
          }

          emit('update:modelValue', formattedDate)
        }
      },
      { immediate: true }
    )

    return {
      value,
      valueInput,
      errorMessage,
      handleChangeValidation
    }
  }
}
</script>

<template>
  <div :class="className">
    <label :for="id">{{ label }}</label>
    <i v-if="icon" :class="icon"></i>
    <VDatePicker v-model="value" @dayclick="handleChangeValidation">
      <template #default="{ togglePopover }">
        <input
          :id="id"
          :name="name"
          :value="valueInput"
          type="button"
          :disabled="isDisabled"
          :class="{ invalid: isInvalid || !!errorMessage }"
          @click="togglePopover"
        />
      </template>
    </VDatePicker>
  </div>
</template>

<style lang="scss">
.vc-header {
  .vc-title,
  .vc-arrow {
    background-color: transparent;
  }
}
</style>
