<script>
import { onMounted, ref } from 'vue'
import { useMeta } from 'vue-meta'
import { toast } from 'vue3-toastify'
import * as yup from 'yup'

import formModal from '@/components/shared/formModal.vue'
import appTableSection from '@/components/shared/tableSection.vue'
import { useNoScroll } from '@/composables/utils/useNoScroll'
import { fetchGiftCards, createGiftCardVoucher } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'

export default {
  name: 'appGiftCard',

  components: {
    appTableSection,
    formModal
  },

  setup() {
    useMeta({ title: 'Gift Card' })

    const modalFormIsShown = ref(false)
    const modalConfig = ref({})

    const giftCards = ref([])
    const loading = ref(false)

    const { setNoScroll } = useNoScroll()

    const tableHeaders = [
      { key: 'clientId', label: 'ID', type: 'text' },
      { key: 'clientName', label: 'Nome', type: 'text' },
      { key: 'giftCardName', label: 'Giftcard', type: 'text' },
      { key: 'giftCardValue', label: 'Valor', type: 'text' },
      {
        key: 'actions',
        label: 'Ações',
        type: 'actions',
        options: [{ action: 'edit', label: 'Editar', class: 'edit', emitEvent: 'edit' }]
      }
    ]

    const fetchGiftCard = async () => {
      loading.value = true
      try {
        const response = await fetchGiftCards()
        giftCards.value = response.data.payload
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loading.value = false
      }
    }

    const resetGiftCards = () => {
      giftCards.value = []
    }

    onMounted(() => {
      fetchGiftCard()
    })

    const title = ref('')
    const initialFormData = ref({})
    const modalEnabled = ref(false)
    const modalInfoEnabled = ref(false)
    const editModeEnabled = ref(false)
    const loadingButton = ref(false)

    const formSchema = [
      {
        component: 'formInput',
        name: 'clientId',
        label: 'ID',
        class: 'col-12 form-input',
        value: 'clientId',
        isDisabled: true,
        rules: yup.string().required()
      },
      {
        component: 'formInput',
        name: 'clientName',
        label: 'Nome',
        class: 'col-12 form-input',
        value: 'clientName',
        isDisabled: true,
        rules: yup.string().required()
      },
      {
        component: 'formInput',
        name: 'giftCardName',
        label: 'Gift Card',
        class: 'col-12 form-input',
        value: 'giftCardName',
        isDisabled: true,
        rules: yup.string().required()
      },
      {
        component: 'formInput',
        name: 'giftCardValue',
        label: 'Valor',
        class: 'col-12 form-input',
        value: 'giftCardValue',
        isDisabled: true,
        rules: yup.string().required()
      },
      {
        component: 'formInput',
        name: 'codeGiftCardValue',
        label: 'Voucher',
        class: 'col-12 form-input',
        value: 'codeGiftCardValue',
        rules: yup.string().required()
      }
    ]

    const handleEdit = (item) => {
      title.value = `Editar GiftCard: ${item.clientName}`
      initialFormData.value = { ...item }

      editModeEnabled.value = true
      modalEnabled.value = true
    }

    const handleCreateGiftCardVoucher = async (createdForm) => {
      loadingButton.value = true
      try {
        const body = {
          voucher: createdForm.codeGiftCardValue
        }
        await createGiftCardVoucher(createdForm.clientId, body)
        modalEnabled.value = false
        resetGiftCards()
        fetchGiftCard()
        toast('Gift Cards enviado com sucesso', {
          theme: 'auto',
          type: 'success',
          dangerouslyHTMLString: true
        })
      } catch (e) {
        handleGlobalError(e)
      } finally {
        initialFormData.value = {}
        loadingButton.value = false
        editModeEnabled.value = false
        setNoScroll(false)
      }
    }

    const loadingModal = ref(false)

    const handleFormClose = () => {
      initialFormData.value = {}
      editModeEnabled.value = false
      modalInfoEnabled.value = false
      modalEnabled.value = false
    }

    return {
      tableHeaders,
      giftCards,
      loading,

      modalFormIsShown,
      modalEnabled,
      modalInfoEnabled,
      modalConfig,
      title,
      formSchema,

      handleFormClose,
      initialFormData,
      loadingButton,

      handleCreateGiftCardVoucher,
      handleEdit,

      loadingModal
    }
  }
}
</script>

<template>
  <div>
    <form-modal
      v-if="modalEnabled"
      @onClose="handleFormClose"
      :title="title"
      :formSchema="formSchema"
      :loading="loadingButton"
      :initialFormData="initialFormData"
      @onSubmitted="handleCreateGiftCardVoucher"
    />

    <div class="row title">
      <h2 class="col">Gerenciar Gift Cards</h2>
    </div>

    <app-table-section
      :items="giftCards"
      :headers="tableHeaders"
      :loading="loading"
      :eventHandlers="{
        edit: handleEdit
      }"
    />
  </div>
</template>
