<template>
  <div class="discounts-container">
    <div v-for="(config, type) in filteredConfigs" :key="type" class="col-12 table-discounts">
      <app-title :label="config.label" />
      <table>
        <thead>
          <tr>
            <th v-for="column in config.columns" :key="column.field">{{ column.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="discount in getDiscountsByType(type)" :key="discount.clientId">
            <td v-for="column in config.columns" :key="column.field">
              {{ getDiscountValue(discount, column.field) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'

import appTitle from '@/components/shared/title.vue'

export default {
  name: 'tableDiscounts',
  components: { appTitle },

  props: {
    discounts: {
      type: Array,
      default: () => []
    }
  },

  setup(props) {
    const discountsConfig = {
      PROMOCODE: {
        label: 'PROMOCODE',
        columns: [
          { label: 'CLIENTE', field: 'clientId' },
          { label: 'PROMOCODE', field: 'promoCode' },
          { label: 'DESC. SPREAD', field: 'spreadDiscountPercentage' },
          { label: 'DESC. TARIFA', field: 'clientTaxDiscountPercentage' }
        ]
      },
      INDICATE_AND_WIN: {
        label: 'INDIQUE E GANHE',
        columns: [
          { label: 'CLIENTE', field: 'clientId' },
          { label: 'DESC. SPREAD', field: 'spreadDiscountPercentage' },
          { label: 'DESC. TARIFA', field: 'clientTaxDiscountPercentage' },
          { label: 'QUANT. UTILIZADA', field: 'accumulation' }
        ]
      },
      INDICATE_AND_WIN_FIRST_TIME_OPERATION: {
        label: 'INDIQUE E GANHE NA PRIMEIRA OPERAÇÃO',
        columns: [
          { label: 'CLIENTE', field: 'clientId' },
          { label: 'DESC. SPREAD', field: 'spreadDiscountPercentage' },
          { label: 'DESC. TARIFA', field: 'clientTaxDiscountPercentage' }
        ]
      },
      PROMOCODE_GIFT_CARD: {
        label: 'GIFT CARD',
        columns: [
          { label: 'CLIENTE', field: 'clientId' },
          { label: 'PROMOCODE', field: 'promoCode' }
        ]
      }
    }

    const getDiscountsByType = (type) =>
      props.discounts.filter((discount) => discount.type === type)

    const filteredConfigs = computed(() => {
      return Object.keys(discountsConfig)
        .filter((type) => getDiscountsByType(type).length > 0)
        .reduce((obj, key) => {
          obj[key] = discountsConfig[key]
          return obj
        }, {})
    })

    const getDiscountValue = (discount, field) => {
      if (field === 'spreadDiscountPercentage' && discount.spreadConstantValueEnabled) {
        return discount.spreadType === 'MONETARY'
          ? `FIXO: USD ${discount.spreadConstantValue.toFixed(2)}`
          : `FIXO: ${discount.spreadConstantValue}%`
      }

      if (field === 'clientTaxDiscountPercentage' && discount.clientTaxConstantValueEnabled) {
        return `FIXO: USD ${discount.clientTaxConstantValue.toFixed(2)}`
      }

      if (field === 'spreadDiscountPercentage' || field === 'clientTaxDiscountPercentage') {
        return discount[field] != null ? `${discount[field]}%` : 'N/A'
      }

      return discount[field] != null ? discount[field] : 'N/A'
    }

    return {
      filteredConfigs,
      getDiscountsByType,
      getDiscountValue
    }
  }
}
</script>

<style scoped lang="scss">
.table-discounts {
  margin-bottom: 20px;
  table {
    tbody {
      tr {
        td {
          color: var(--label-color);
        }
      }
    }
  }
}
</style>
