import { rolePermissions } from './permissions'

import { useAuthToken } from '@/stores/authToken'

export const usePermissions = () => {
  const userRolesString = useAuthToken().getRoles || ''
  const userRoles = userRolesString.split(',')

  const hasPermission = (permission) => {
    return userRoles.some((role) => rolePermissions[role]?.[permission])
  }

  const canOpenMarket = () => hasPermission('canOpenMarket')

  //OPERATION
  const canCreatedData = () => hasPermission('canCreatedData')
  const canDeleteData = () => hasPermission('canDeleteData')
  const canEditData = () => hasPermission('canEditData')
  const canManageOperation = () => hasPermission('canManageOperation')
  const canEditSensitiveInput = () => hasPermission('canEditSensitiveInput')

  //MENU
  const canViewDashboard = () => hasPermission('canViewDashboard')
  const canViewTargetTaxes = () => hasPermission('canViewTargetTaxes')
  const canViewPreOperations = () => hasPermission('canViewPreOperations')
  const canViewPostOperations = () => hasPermission('canViewPostOperations')
  const canViewCompletedOperations = () => hasPermission('canViewCompletedOperations')
  const canViewClients = () => hasPermission('canViewClients')
  const canViewHolidays = () => hasPermission('canViewHolidays')
  const canViewParameters = () => hasPermission('canViewParameters')
  const canViewNewsletter = () => hasPermission('canViewNewsletter')
  const canViewGiftCard = () => hasPermission('canViewGiftCard')
  const canViewPlatformLogs = () => hasPermission('canViewPlatformLogs')
  const canViewPromoCodes = () => hasPermission('canViewPromoCodes')
  const canViewPushNotification = () => hasPermission('canViewPushNotification')
  const canViewPlatformAlert = () => hasPermission('canViewPlatformAlert')
  const canManageUsers = () => hasPermission('canManageUsers')
  const canManageBrokers = () => hasPermission('canManageBrokers')
  const canViewBankerPromoCode = () => hasPermission('canViewBankerPromoCode')

  const hasInputAllowed = () => {
    return canManageOperation()
  }

  const hasSensitiveInputAllowed = () => {
    return canEditSensitiveInput()
  }

  return {
    canOpenMarket,
    canCreatedData,
    canDeleteData,
    canEditData,
    canManageOperation,

    hasInputAllowed,
    hasSensitiveInputAllowed,

    canViewDashboard,
    canViewTargetTaxes,
    canViewPreOperations,
    canViewPostOperations,
    canViewCompletedOperations,
    canViewClients,
    canViewHolidays,
    canViewParameters,
    canViewNewsletter,
    canViewGiftCard,
    canViewPlatformLogs,
    canViewPromoCodes,
    canViewPushNotification,
    canViewPlatformAlert,
    canManageUsers,
    canManageBrokers,
    canViewBankerPromoCode
  }
}
