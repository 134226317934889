import { getPlatformFromUrl } from '@composables/utils/useWhitelabelService'
import router from '@router'
import * as Sentry from '@sentry/vue'

export const setupSentry = (app) => {
  if (process.env.SENTRY_URL_DSN != null && process.env.VITE_APP_ENVIRONMENT === 'prod') {
    const configSentry = {
      app,
      dsn: process.env.SENTRY_URL_DSN,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        }),
        new Sentry.Replay()
      ],
      ignoreErrors: ['FirebaseError'],
      tracesSampleRate: 0.4,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0.1,
      environment: getPlatformFromUrl(),
      release: process.env.VITE_APP_VERSION
    }

    Sentry.init(configSentry)
  }
}
