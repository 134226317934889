<script>
import {
  hasInputAllowed,
  isTransferbankAdminUser
} from '@composables/utils/useWhitelabelService.js'
import { ref, computed } from 'vue'
import { useMeta } from 'vue-meta'
import { toast } from 'vue3-toastify'
import * as yup from 'yup'

import appFilterSection from '@/components/shared/filterSection.vue'
import formModal from '@/components/shared/formModal.vue'
import appModal from '@/components/shared/modal.vue'
import appTableSection from '@/components/shared/tableSection.vue'
import { useDynamicFilters, getUsersFilters } from '@/composables/utils/useFilters'
import { useNoScroll } from '@/composables/utils/useNoScroll'
import { usePaginatedResult } from '@/composables/utils/usePaginatedResult'
import { usePermissions } from '@/composables/utils/usePermissions'
import { useSearchThrottle } from '@/composables/utils/useSearch'
import router from '@/router'
import { fetchUser, createAdminUser, updateAdminUser, deleteAdminUser } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'

export default {
  name: 'appUsers',

  components: {
    formModal,
    appFilterSection,
    appTableSection,
    appModal
  },

  props: {
    search: String
  },

  setup(props) {
    useMeta({ title: 'Administradores' })

    const modalFormIsShown = ref(false)
    const modalConfig = ref({})

    const searchValue = ref(props.search)

    const permissions = usePermissions()

    const { setNoScroll } = useNoScroll()

    useSearchThrottle(searchValue, () => {
      resetAndUpdateResult(true)
    })

    const tableHeaders = [
      { key: 'userName', label: 'Nome', type: 'text' },
      { key: 'email', label: 'E-mail', type: 'text' },
      { key: 'status', label: 'Status', type: 'userStatusType' },
      { key: 'lastLoginAt', label: 'Último acesso', type: 'date', format: 'dd/MM/yyyy HH:mm' },
      { key: 'roleName', label: 'Permissão', type: 'text' },
      {
        key: 'actions',
        label: 'Ações',
        type: 'actions',
        options: [
          {
            action: 'edit',
            label: 'Editar',
            class: 'edit',
            emitEvent: 'edit',
            isVisible: permissions.canEditData()
          },
          {
            action: 'delete',
            label: 'Deletar',
            class: 'delete',
            emitEvent: 'delete',
            isVisible: permissions.canDeleteData()
          }
        ]
      }
    ]

    const fetchFunction = (config) => fetchUser(config)

    const resetAndUpdateResult = (shouldReset) => {
      if (shouldReset) resetResult()

      const config = {
        page: currentPage.value,
        search: searchValue.value
      }
      router.push({ name: 'Users', query: { ...config, page: undefined } })
      fetchResult(config)
    }

    const {
      resultItems: users,
      loading,
      isLastPage,
      fetchResult,
      resetResult,
      currentPage
    } = usePaginatedResult(fetchFunction, resetAndUpdateResult)

    const title = ref('')
    const initialFormData = ref({
      isActive: true,
      canOperate: true
    })
    const modalEnabled = ref(false)
    const editModeEnabled = ref(false)
    const loadingButton = ref(false)

    const rolesOptions = computed(() => {
      if (isTransferbankAdminUser()) {
        return [
          { key: 'SUPER_ADMIN', name: 'Super Admin' },
          { key: 'ADMIN', name: 'Admin' }
        ]
      } else {
        return [
          { key: 'SUPER_ADMIN_WHITELABEL', name: 'Super Admin' },
          { key: 'ADMIN_WHITELABEL', name: 'Admin' },
          { key: 'ADMIN_BANKER_WHITELABEL', name: 'Assessor' },
          { key: 'ADMIN_BROKER_WHITELABEL', name: 'Corretora' }
        ]
      }
    })

    const formSchema = [
      {
        component: 'formInput',
        name: 'userName',
        label: 'Nome',
        class: 'col-12 form-input',
        value: 'userName',
        rules: yup.string().required()
      },
      {
        component: 'formInput',
        name: 'email',
        label: 'E-mail',
        class: 'col-12 form-input',
        value: 'email',
        rules: yup.string().required()
      },
      {
        component: 'formSelect',
        name: 'roles',
        label: 'Permissão',
        class: 'col-12 form-input',
        value: 'roles',
        items: rolesOptions.value,
        rules: yup.string().required()
      },
      {
        component: 'formSwitch',
        name: 'isActive',
        description: 'Admin ativo?',
        class: 'col-12 form-input',
        value: 'isActive',
        rules: yup.string().required()
      },
      {
        component: 'formSwitch',
        name: 'canOperate',
        description: 'Pode operar câmbio?',
        class: 'col-12 form-input',
        value: 'canOperate',
        rules: yup.string().required()
      },
      {
        component: 'formSwitch',
        name: 'isBackOffice',
        description: 'Back Office ativo?',
        class: 'col-12 form-input',
        value: 'isBackOffice',
        rules: yup.string().required()
      }
    ]

    const isAdminRole = (role) => {
      const adminKeys = rolesOptions.value
        .filter((option) => option.key.includes('ADMIN'))
        .map((option) => option.key)
      return adminKeys.includes(role)
    }

    const newUser = () => {
      title.value = 'Adicionar novo administrador'
      initialFormData.value = {
        isActive: true,
        canOperate: true,
        isBackOffice: false
      }
      modalEnabled.value = true
    }

    const handleEdit = (item) => {
      title.value = 'Editar administrador'

      if (Array.isArray(item.roles) && item.roles.length > 0) {
        const nonAdminRoles = item.roles.filter((role) => !isAdminRole(role))
        const adminRole = item.roles.find((role) => isAdminRole(role))
        item.roles = adminRole ? adminRole : item.roles[0]
        item.nonAdminRoles = nonAdminRoles
      }
      initialFormData.value = { ...item }

      editModeEnabled.value = true
      modalEnabled.value = true
    }

    const handleFormCreation = async (formData) => {
      if (!editModeEnabled.value) {
        handleSubmitUser(formData)
      } else {
        handleUpdateUser(formData)
      }
    }

    const handleSubmitUser = async (formData) => {
      loadingButton.value = true
      try {
        const body = {
          userName: formData.userName.trim(),
          email: formData.email.trim(),
          roles: [formData.roles],
          isActive: formData.isActive,
          canOperate: formData.canOperate
        }
        await createAdminUser(body)
        modalEnabled.value = false
        resetAndUpdateResult(true)
        toast('Administrador cadastrado', {
          theme: 'auto',
          type: 'success',
          dangerouslyHTMLString: true
        })
      } catch (e) {
        handleGlobalError(e)
      } finally {
        initialFormData.value = {}
        loadingButton.value = false
        setNoScroll(false)
      }
    }

    const handleUpdateUser = async (formData) => {
      if (typeof formData.roles === 'string') {
        formData.roles = [formData.roles]
      }

      if (formData.nonAdminRoles) {
        formData.roles = [
          ...formData.nonAdminRoles,
          ...formData.roles.filter((role) => isAdminRole(role))
        ]
        delete formData.nonAdminRoles
      }

      loadingButton.value = true
      try {
        await updateAdminUser(formData)
        modalEnabled.value = false
        resetAndUpdateResult(true)
        toast('Administrador editado', {
          theme: 'auto',
          type: 'success',
          dangerouslyHTMLString: true
        })
      } catch (e) {
        handleGlobalError(e)
      } finally {
        initialFormData.value = {}
        loadingButton.value = false
        setNoScroll(false)
      }
    }

    const handleDelete = async (item) => {
      modalConfig.value = {
        origin: 'delete-target-tax',
        title: 'Você tem certeza?',
        icon: 'bi bi-exclamation-square-fill',
        description: 'Se você remover este administrador ele perderá todos os acessos ao admin',
        showButtons: true,
        type: 'warning',
        submitButtonText: 'Confirmar',
        backButtonText: 'Cancelar',
        id: item.id
      }
      modalFormIsShown.value = true
    }

    const loadingModal = ref(false)

    const handleDeleteUser = async (item) => {
      try {
        loadingModal.value = true
        await deleteAdminUser(item.id)
        resetAndUpdateResult(true)
        toast('Usuário removido com sucesso!', {
          theme: 'auto',
          type: 'success',
          dangerouslyHTMLString: true
        })
      } catch (e) {
        handleGlobalError(e)
      } finally {
        modalFormIsShown.value = false
        loadingModal.value = false
      }
    }

    const handleConfirm = (item) => {
      if (item.origin === 'delete-target-tax') {
        handleDeleteUser(item)
      }
    }

    const handleFormClose = () => {
      initialFormData.value = {}
      modalEnabled.value = false
    }

    const filterSchema = getUsersFilters()
    const externalRefs = { searchValue }
    const { formValues, updateFormValues } = useDynamicFilters(filterSchema, externalRefs)

    return {
      permissions,
      tableHeaders,
      users,
      loading,
      isLastPage,

      hasInputAllowed,

      newUser,

      modalFormIsShown,
      modalEnabled,
      modalConfig,
      title,
      formSchema,

      handleFormClose,
      handleEdit,
      initialFormData,
      loadingButton,

      handleFormCreation,
      handleDelete,

      handleConfirm,
      loadingModal,

      filterSchema,
      formValues,
      updateFormValues
    }
  }
}
</script>

<template>
  <div>
    <form-modal
      v-if="modalEnabled"
      @onClose="handleFormClose"
      :title="title"
      :formSchema="formSchema"
      :loading="loadingButton"
      :initialFormData="initialFormData"
      @onSubmitted="handleFormCreation"
    />

    <app-modal
      v-if="modalFormIsShown"
      :modalConfig="modalConfig"
      :loading="loadingModal"
      @submit="handleConfirm"
      @onClose="modalFormIsShown = false"
    />

    <div class="row title">
      <h2 class="col">Administradores</h2>
      <div class="col-12 col-md-3 form-input">
        <button class="secondary" @click="newUser()">
          <i class="bi bi-node-plus-fill"></i>
          Adicionar novo administrador
        </button>
      </div>
    </div>

    <app-filter-section
      :filterSchema="filterSchema"
      :formValues="formValues"
      @update:formValues="updateFormValues"
    />

    <app-table-section
      :items="users"
      :headers="tableHeaders"
      :loading="loading"
      :isLastPage="isLastPage && !loading"
      :eventHandlers="{
        edit: handleEdit,
        delete: handleDelete
      }"
    />
  </div>
</template>
