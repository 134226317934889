import { Howl } from 'howler'
import { ref, watch } from 'vue'
import { toast } from 'vue3-toastify'

import router from '@/router'
import { useConfig } from '@/stores/config.js'
import { useWss } from '@/stores/wss'

export function useOperationMonitor() {
  const wssStore = useWss()

  const operationTypes = {
    PRE_OPERATION: 'preOperation',
    POS_OPERATION: 'posOperation',
    NEW_CLIENT: 'newClient'
  }

  const formatType = ref({
    BUYING: { name: 'COMPRA', color: '#6195e1' },
    SELLING: { name: 'VENDA', color: '#71cc98' }
  })

  const formatCurrency = (amount, currencyCode = 'BRL') => {
    const number = parseFloat(amount)
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
    return `${currencyCode} ${formatter.format(number)}`
  }

  const onToastClick = (origin, operationId) => {
    const config = {
      id: operationId
    }

    const queryParams = { ...config }
    let routeName = ''

    if (origin === 'preOperation') {
      routeName = 'PreOperations'
    } else if (origin === 'posOperation') {
      routeName = 'PosOperations'
    } else if (origin === 'newClient') {
      routeName = 'Clients'
    }

    router.push({
      name: routeName,
      query: queryParams
    })
  }

  const soundMap = {
    [operationTypes.PRE_OPERATION]: process.env.VITE_S3_URL + '/sfxs/newPreOperationAlert.mp3',
    [operationTypes.POS_OPERATION]: process.env.VITE_S3_URL + '/sfxs/newPosOperationAlert.mp3',
    [operationTypes.NEW_CLIENT]: process.env.VITE_S3_URL + '/sfxs/newClientAlert.mp3'
  }

  const playSoundAndToast = (origin, operation, message) => {
    let alert = ''
    if (origin === 'preOperation' || origin === 'posOperation') {
      const formattedAmount = formatCurrency(operation.principalAmount, operation.currencyType)
      const typeInfo = formatType.value[operation.type]
      alert = `${message}\n${operation.clientName}\n${formattedAmount} - <b style="color: ${typeInfo.color};">${typeInfo.name}</b>`
    } else {
      alert = `${message}\n${operation.clientName}</b>`
    }

    toast(alert, {
      autoClose: 10000,
      theme: 'auto',
      type: 'success',
      dangerouslyHTMLString: true,
      onClick: () => onToastClick(origin, operation.id)
    })

    const config = useConfig()

    if (config.sound) {
      const audioSource = soundMap[origin]
      const sound = new Howl({
        src: [audioSource],
        autoplay: true,
        volume: 1.0
      })

      sound.play()
    }
  }

  watch(
    () => wssStore.getNewPreOperation.id,
    (newVal, oldVal) => {
      if (newVal !== oldVal) {
        playSoundAndToast(
          operationTypes.PRE_OPERATION,
          wssStore.getNewPreOperation,
          'Nova operação criada'
        )
      }
    }
  )

  watch(
    () => wssStore.getNewPosOperation.id,
    (newVal, oldVal) => {
      if (newVal !== oldVal) {
        playSoundAndToast(
          operationTypes.POS_OPERATION,
          wssStore.getNewPosOperation,
          'Nova operação fechada'
        )
      }
    }
  )

  watch(
    () => wssStore.getNewClient.id,
    (newVal, oldVal) => {
      if (newVal !== oldVal) {
        playSoundAndToast(operationTypes.NEW_CLIENT, wssStore.getNewClient, 'Novo cliente')
      }
    }
  )
}
