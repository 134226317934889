<template>
  <div class="box-content d-flex justify-content-end">
    <div :class="['back', { active: contentActive }]" @click="close()"></div>
    <div :class="['content', { active: contentActive }]">
      <h4>{{ title }}</h4>

      <div class="col-12">
        <div class="row">
          <template v-for="item in formSchema" :key="item.name">
            <component
              :is="item.component"
              :class="item.class"
              :label="item.label"
              :items="item.items"
              v-model="vModelRefs[item.name].value"
              @update:v-model="(newValue) => (vModelRefs[item.name].value = newValue)"
              @fileSelected="handleFileSelected"
              :id="item.name"
              :name="item.name"
              :type="item.type"
              :placeholder="item.placeholder"
              :config="item.configMoney"
              :isDisabled="item.isDisabled"
              :description="item.description"
            />
          </template>

          <form-button
            class="col-12 form-input"
            title="Enviar"
            :loading="loading"
            @clicked="onSubmitAction"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useForm } from 'vee-validate'
import { onMounted, ref, provide, watch } from 'vue'
import { useMeta } from 'vue-meta'
import * as yup from 'yup'

import formButton from '@/components/form/button.vue'
import formDate from '@/components/form/date.vue'
import formInput from '@/components/form/input.vue'
import formMoney from '@/components/form/money.vue'
import formSelect from '@/components/form/select.vue'
import formSwitch from '@/components/form/switch.vue'
import formTextarea from '@/components/form/textarea.vue'
import appBankList from '@/components/shared/bankList.vue'
import appImportDocument from '@/components/shared/importDocument.vue'
import appTitle from '@/components/shared/title.vue'
import { useNoScroll } from '@/composables/utils/useNoScroll'

export default {
  name: 'formModal',

  components: {
    appTitle,
    formInput,
    formSelect,
    formTextarea,
    formMoney,
    formDate,
    formSwitch,
    formButton,
    appImportDocument,
    appBankList
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    formSchema: {
      type: Array,
      required: true
    },
    initialFormData: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    useMeta({ title: props.title })

    const contentActive = ref(false)
    const importDocument = ref('')
    const initialFormData = { ...props.initialFormData }
    const scroll = ref(false)
    const { setNoScroll } = useNoScroll()

    watch(scroll, (newValue) => {
      setNoScroll(newValue)
    })

    const schemaObject = {}
    const vModelRefs = {}
    props.formSchema.forEach((item) => {
      schemaObject[item.name] = item.rules
      vModelRefs[item.name] = ref(initialFormData[item.name])
    })

    const { handleSubmit } = useForm({
      validationSchema: yup.object({
        ...schemaObject
      }),
      initialValues: { ...initialFormData }
    })

    const alreadySubmittedOnce = ref(false)
    provide('alreadySubmittedOnce', alreadySubmittedOnce)

    const onSubmitAction = () => {
      alreadySubmittedOnce.value = true
      return onSubmit()
    }

    const onSubmit = handleSubmit(async (values) => {
      const body = {
        ...values
      }
      if (importDocument.value) {
        body.importDocument = importDocument
      }
      emit('onSubmitted', body)
    })

    const handleFileSelected = async (files) => {
      for (const file of files) {
        importDocument.value = file
      }
    }

    const close = () => {
      contentActive.value = false
      scroll.value = false
      setTimeout(() => {
        emit('onClose', false)
      }, 300)
    }

    const openModal = () => {
      scroll.value = true
      setTimeout(() => {
        contentActive.value = true
      }, 100)
    }

    onMounted(() => {
      openModal()
    })

    return {
      vModelRefs,
      close,
      onSubmitAction,
      handleFileSelected,
      contentActive
    }
  }
}
</script>
