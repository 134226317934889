<script>
import { ref } from 'vue'
import { useMeta } from 'vue-meta'

import appFilterSection from '@/components/shared/filterSection.vue'
import appModal from '@/components/shared/modal.vue'
import appTableSection from '@/components/shared/tableSection.vue'
import { useDynamicFilters, getTargetTaxFilters } from '@/composables/utils/useFilters'
import { usePaginatedResult } from '@/composables/utils/usePaginatedResult'
import { usePermissions } from '@/composables/utils/usePermissions'
import { useSearchThrottle } from '@/composables/utils/useSearch'
import router from '@/router'
import { fetchTargetTax, deleteTargetTax } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'

export default {
  name: 'TargetTax',

  components: {
    appFilterSection,
    appTableSection,
    appModal
  },

  props: {
    search: String,
    expiresAt: String,
    type: String,
    status: String
  },

  setup(props) {
    useMeta({ title: 'Taxa Alvo' })

    const modalFormIsShown = ref(false)
    const modalConfig = ref({})

    const searchValue = ref(props.search)
    const expiresAtValue = ref(props.expiresAt)
    const natureTypeValue = ref(props.type)
    const statusValue = ref(props.status ? props.status : 'OPEN')

    const valuesToWatch = [natureTypeValue, expiresAtValue, statusValue]

    const permissions = usePermissions()

    useSearchThrottle(searchValue, () => {
      resetAndUpdateResult(true)
    })

    const tableHeaders = [
      { key: 'clientName', label: 'Cliente', type: 'text' },
      { key: 'principalAmount', label: 'Valor', type: 'currency', locale: 'pt-BR' },
      { key: 'type', label: 'Tipo', type: 'operationType' },
      {
        key: 'finalQuote',
        label: 'Alvo',
        type: 'quotation',
        locale: 'pt-BR',
        maximumFractionDigits: 4
      },
      { key: 'status', label: 'Status', type: 'statusType' },
      { key: 'expiresAt', label: 'Validade', type: 'date', format: 'dd/MM/yyyy HH:mm' },
      {
        key: 'actions',
        label: 'Ações',
        type: 'actions',
        options: [
          {
            action: 'delete',
            label: 'Deletar',
            class: 'delete',
            emitEvent: 'delete',
            isVisible: permissions.canDeleteData()
          }
        ]
      }
    ]

    const fetchFunction = (config) => fetchTargetTax(config)

    const resetAndUpdateResult = (shouldReset) => {
      if (shouldReset) resetResult()

      const config = {
        page: currentPage.value,
        search: searchValue.value,
        type: natureTypeValue.value,
        expiresAt: expiresAtValue.value,
        status: statusValue.value
      }
      router.push({ name: 'TargetTaxes', query: { ...config, page: undefined } })
      fetchResult(config)
    }

    const {
      resultItems: operations,
      loading,
      isLastPage,
      fetchResult,
      resetResult,
      currentPage
    } = usePaginatedResult(fetchFunction, resetAndUpdateResult, valuesToWatch)

    const handleDelete = async (item) => {
      modalConfig.value = {
        origin: 'delete-target-tax',
        title: 'Você tem certeza?',
        icon: 'bi bi-exclamation-square-fill',
        description: 'Se você remover esta oferta, ela ficará como taxa alvo para o cliente',
        showButtons: true,
        type: 'warning',
        submitButtonText: 'Confirmar',
        backButtonText: 'Cancelar',
        id: item.id
      }
      modalFormIsShown.value = true
    }

    const loadingModal = ref(false)

    const handleDeleteTargetTax = async (item) => {
      try {
        loadingModal.value = true
        await deleteTargetTax(item.id)
        operations.value = operations.value.filter((op) => op.id !== item.id)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        modalFormIsShown.value = false
        loadingModal.value = false
      }
    }

    const handleConfirm = (item) => {
      if (item.origin === 'delete-target-tax') {
        handleDeleteTargetTax(item)
      }
    }

    const filterSchema = getTargetTaxFilters()
    const externalRefs = { searchValue, expiresAtValue, natureTypeValue, statusValue }
    const { formValues, updateFormValues } = useDynamicFilters(filterSchema, externalRefs)

    return {
      permissions,

      modalFormIsShown,
      loadingModal,
      modalConfig,
      handleDelete,
      handleConfirm,
      tableHeaders,
      operations,
      loading,
      isLastPage,

      filterSchema,
      formValues,
      updateFormValues
    }
  }
}
</script>

<template>
  <div>
    <app-modal
      v-if="modalFormIsShown"
      :modalConfig="modalConfig"
      :loading="loadingModal"
      @submit="handleConfirm"
      @onClose="modalFormIsShown = false"
    />

    <div class="row title">
      <h2 class="col">Taxa Alvo</h2>
    </div>

    <app-filter-section
      :filterSchema="filterSchema"
      :formValues="formValues"
      @update:formValues="updateFormValues"
    />

    <app-table-section
      :items="operations"
      :headers="tableHeaders"
      :loading="loading"
      :isLastPage="isLastPage && !loading"
      :eventHandlers="{
        delete: handleDelete
      }"
    />
  </div>
</template>
