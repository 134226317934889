import { ref } from 'vue'

import { operationTypes } from '@/composables/utils/useGlobalConfig'

export const useOperationType = () => {
  const operationType = ref({
    '': 'Todos',
    [operationTypes.BUYING]: 'COMPRA',
    [operationTypes.SELLING]: 'VENDA'
  })

  const fetchOperationTypeOptions = () => {
    return Object.keys(operationType.value).map((key) => ({
      name: operationType.value[key],
      key: key
    }))
  }

  return {
    operationType,
    fetchOperationTypeOptions
  }
}
