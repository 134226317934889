<script>
import { hasInputAllowed } from '@composables/utils/useWhitelabelService.js'
import { parseISO, format, formatISO } from 'date-fns'
import { useForm } from 'vee-validate'
import { ref, provide, watch, computed, nextTick } from 'vue'
import { useMeta } from 'vue-meta'
import { toast } from 'vue3-toastify'
import * as yup from 'yup'

import appBankDetails from './_bankDetails.vue'
import appCustomerTedTransfer from './_customerTedTransfer.vue'
import appCustomerTransfer from './_customerTransfer.vue'
import appDocumentation from './_documentation.vue'
import appOperationDetails from './_operationDetails.vue'
import appRecipientTransfer from './_recipientTransfer.vue'

import formButton from '@/components/form/button.vue'
import formInput from '@/components/form/input.vue'
import formMoney from '@/components/form/money.vue'
import formSearch from '@/components/form/search.vue'
import formSelect from '@/components/form/select.vue'
import appTitle from '@/components/shared/title.vue'
import {
  configClientMoney,
  configCommercialMoney,
  configMoney,
  configIof,
  configIr
} from '@/composables/utils/moneyConfig'
import { useCurrencyOptions } from '@/composables/utils/useCurrencyOptions'
import {
  clientTypes,
  transactionTypes,
  liquidationTypeOptions,
  operationNatureTypes,
  operationTypes,
  spreadTypes,
  emptyPlaceholder,
  posStatusOptions,
  typeFile
} from '@/composables/utils/useGlobalConfig'
import useModal from '@/composables/utils/useModal'
import { useNumberFormatter } from '@/composables/utils/useNumberFormatter'
import { useSearchThrottle } from '@/composables/utils/useSearch'
import { greatherThan } from '@/composables/utils/validationUtils'
import {
  fetchClients,
  fetchClientBank,
  fetchTedClientBank,
  uploadDocument,
  uploadDarfAdmin,
  uploadDarf,
  uploadExchangeContract,
  uploadSwift,
  uploadTed,
  createPosDocsSignature
} from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'
import { useCache } from '@/stores/cache.js'

export default {
  name: 'formModalPos',

  components: {
    appTitle,
    formSearch,
    formInput,
    formMoney,
    formSelect,
    formButton,

    appCustomerTransfer,
    appRecipientTransfer,
    appCustomerTedTransfer,
    appOperationDetails,
    appBankDetails,
    appDocumentation
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    initialFormData: {
      type: Object,
      default: () => ({})
    }
  },

  setup(props, { emit }) {
    useMeta({ title: props.title })
    const { contentActive, closeModal } = useModal(emit)

    const { numberFormat } = useNumberFormatter()

    const loadingSubmitDocusign = ref(false)

    const cacheNatureType = useCache().getNatureType
    const cacheResponsible = useCache().getOperators

    const clientNameValue = ref('')
    const clientApiResult = ref([])
    const clientId = ref('')
    const documentId = ref('')
    const clientType = ref('')

    const exchangeRecipient = ref({})

    const responsibleValue = ref('')
    const responsibleOptions = ref([])

    const natureTypeValue = ref('')
    const operationOptions = ref([])

    const operationType = ref('')
    const docusignExchangeContract = ref({})

    const operationNatureValue = ref('')
    const operationNatureOptions = ref([])

    const operationSubnatureValue = ref('')
    const operationSubnatureOptions = ref([])

    const invoiceNumberValue = ref('')
    const principalAmountValue = ref('')

    const clientTaxRSValue = ref('')
    const commercialQuoteValue = ref('')
    const systemCommercialQuote = ref('')
    const clientQuoteValue = ref('')
    const spreadType = ref('')
    const spreadValue = ref('')
    const liquidationValue = ref('')
    const iofBaseValue = ref('')
    const irBaseValue = ref('')
    const vetValue = ref('')
    const finalPriceRSValue = ref('')

    const sellOffRealValue = ref('')

    const bankChangeValue = ref('')

    const financialInstitutionValue = ref('')
    const financialInstitutionOptions = ref([])
    const payToValue = ref({})

    const clientQuoteUSDValue = ref('')

    const siscomexProtocolValue = ref('')
    const numberDIOrDUEValue = ref('')

    const statusValue = ref('')

    const tedBankValue = ref([])
    const tedFinancialInstitutionValue = ref('')
    const tedFinancialInstitutionOptions = ref([])

    const editModeEnabled = ref(false)
    const closeDateValue = ref('')
    const createAtDateValue = ref('')
    const operationDetail = ref({})
    const tedClientDetail = ref({})
    const beneficiaryAbroadDetail = ref({})
    const payerNameValue = ref('')
    const liquidationTypeValue = ref('')
    const isLegacyOperation = ref(false)

    const documents = ref([])
    const documentsUpdated = ref(false)

    const loadingUpload = ref(false)
    const uploadedFileUrls = ref([])
    const uploadedDocusignFileUrls = ref([])

    const currencyTypeValue = ref('')
    const { fetchCurrencyOptions } = useCurrencyOptions()
    const currencyOptions = fetchCurrencyOptions()

    const bankOptions = ref([])
    const discountOnTheNextOperation = ref([])

    const documentNameValue = ref('')
    const emailSubjectValue = ref('')
    const signerEmailValue = ref('')
    const signerNameValue = ref('')
    const idContractValue = ref('')

    const isTpay = ref(false)

    const fileBinaries = ref(new Map())

    const alreadySubmittedOnce = ref(false)
    provide('alreadySubmittedOnce', alreadySubmittedOnce)

    useSearchThrottle(clientNameValue, () => {
      const config = {
        search: clientNameValue.value
      }
      searchClientByName(config)
    })

    const searchClientByName = async (searchTerm) => {
      try {
        const response = await fetchClients(searchTerm)
        clientApiResult.value = response.data.payload
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const selectedClient = async (data) => {
      clientNameValue.value = data.clientName
      clientId.value = data.clientId
      documentId.value = data.documentId
      clientType.value = data.clientType
      spreadType.value = data.spreadType
      operationType.value = ''
      natureTypeValue.value = ''
      operationOptions.value = []
      operationNatureValue.value = ''
      operationNatureOptions.value = []
      operationSubnatureValue.value = ''
      operationSubnatureOptions.value = []
      tedBankValue.value = data.banks || []

      financialInstitutionOptions.value = (data.allowedFinancialInstitutions || [])
        .filter((item) => item.allowed)
        .map((item) => ({
          ...item,
          key: item.institution,
          name: item.institution
        }))

      fetchOperationOptions()
    }

    const fetchOperationOptions = async () => {
      const filteredData = cacheNatureType.filter((item) => item.clientType === clientType.value)
      operationOptions.value = filteredData.map((item) => ({
        ...item,
        key: item.subType,
        name: formatOptionName(item)
      }))
      responsibleOptions.value = cacheResponsible.map((item) => ({
        key: item.id,
        name: item.userName
      }))
    }

    const formatOptionName = (item) => {
      if (item.clientType === clientTypes.PF) {
        if (
          item.subType === transactionTypes.TRANSFERBUY ||
          item.subType === transactionTypes.TRADEBUY
        ) {
          return `${item.name} - Envio`
        } else if (
          item.subType === transactionTypes.TRADESELL ||
          item.subType === transactionTypes.TRANSFERSELL
        ) {
          return `${item.name} - Recebimento`
        }
      }
      return item.name
    }

    const formatDoc = (type, operation) => {
      if (!operation || typeof operation !== 'object' || !(type in operation)) {
        return []
      }

      const item = operation[type]

      if (Array.isArray(item)) {
        return item.map((doc) => ({
          name: doc.name,
          url: doc.url,
          fileCode: type
        }))
      } else if (item && typeof item === 'object') {
        return [
          {
            name: item.name,
            url: item.url,
            fileCode: type
          }
        ]
      } else {
        return []
      }
    }

    watch(natureTypeValue, (newVal) => {
      const selectedOperation = operationOptions.value.find((item) => item.key === newVal)

      if (selectedOperation && selectedOperation.natures.length > 0) {
        operationNatureOptions.value = selectedOperation.natures.map((nature) => ({
          ...nature,
          key: nature.code,
          name: nature.name
        }))
      } else {
        operationNatureOptions.value = []
      }

      operationType.value = selectedOperation?.type || props.initialFormData.type

      if (operationType.value === operationTypes.SELLING) {
        tedFinancialInstitutionOptions.value = (tedBankValue.value || []).map((item) => ({
          ...item,
          key: item.account,
          name: item.name
        }))
      } else {
        tedFinancialInstitutionOptions.value = []
      }

      bankOptions.value = []
      if (operationType.value === operationTypes.BUYING) fetchClientBanks()
    })

    watch(financialInstitutionValue, async (newVal) => {
      if (operationType.value === operationTypes.BUYING) {
        try {
          const response = await fetchTedClientBank(newVal)
          tedFinancialInstitutionOptions.value = (response.data.payload.natBankAccounts || []).map(
            (item) => ({
              ...item,
              key: item.code,
              name: item.name
            })
          )
          if (tedFinancialInstitutionOptions.value.length === 1) {
            tedFinancialInstitutionValue.value = tedFinancialInstitutionOptions.value[0].key
            await nextTick()
          }
        } catch (e) {
          handleGlobalError(e)
        }
      }
    })

    watch(operationNatureValue, (newVal) => {
      const selectedNature = operationNatureOptions.value.find((item) => item.key === newVal)
      if (selectedNature && selectedNature.subNatures.length > 0) {
        operationSubnatureOptions.value = selectedNature.subNatures.map((subNature) => ({
          ...subNature,
          key: subNature.code,
          name: subNature.name
        }))
      } else {
        operationSubnatureOptions.value = []
      }
    })

    watch(tedFinancialInstitutionValue, (newVal) => {
      const foundItem = tedFinancialInstitutionOptions.value.find((item) => item.key === newVal)

      if (foundItem != null) {
        payToValue.value = {
          name: foundItem.name,
          agency: foundItem.agency,
          account: foundItem.account,
          company: foundItem.company,
          cnpj: foundItem.cnpj,
          pix: foundItem.pix,
          code: foundItem.code,
          defaultAccount: foundItem.defaultAccount
        }
      }
    })

    watch(bankChangeValue, (newVal) => {
      exchangeRecipient.value = bankOptions.value.find((item) => item.key === newVal)
    })

    watch(
      () => props.initialFormData,
      async (newFormData) => {
        if (Object.keys(newFormData).length === 0) return
        editModeEnabled.value = true
        if (newFormData != null) {
          clientType.value = newFormData.clientType

          fetchOperationOptions()
          natureTypeValue.value = newFormData.subType

          clientNameValue.value = newFormData.clientName
          clientId.value = newFormData.clientId
          documentId.value = newFormData.documentId
          financialInstitutionValue.value = newFormData.financialInstitution
          operationNatureValue.value = newFormData?.operation?.operationNature || ''
          operationSubnatureValue.value = newFormData?.operation?.operationSubNature || ''

          signerNameValue.value = newFormData.userDefaultSignerName
            ? newFormData.userDefaultSignerName
            : newFormData.mainUserName
          signerEmailValue.value = newFormData.userDefaultSignerEmail
            ? newFormData.userDefaultSignerEmail
            : newFormData.mainUserEmail

          const date = parseISO(newFormData.createdAt)
          closeDateValue.value = format(date, 'dd/MM/yyyy HH:mm')

          const createdAt = parseISO(newFormData.createdAt)
          createAtDateValue.value = format(createdAt, 'dd/MM/yyyy HH:mm')

          if (newFormData?.type === operationTypes.SELLING) {
            const openOption = posStatusOptions.value.find((option) => option.key === 'OPEN')
            if (openOption) {
              openOption.name = 'Em liquidação'
            }
            payerNameValue.value = newFormData?.operation?.payerName
          } else if (newFormData?.type === operationTypes.BUYING) {
            beneficiaryAbroadDetail.value =
              newFormData?.operation?.exchangeRecipient || newFormData?.tpayPayTo
          }

          tedClientDetail.value = newFormData.payTo
          spreadType.value = newFormData.spreadType

          operationDetail.value = {
            id: newFormData.id,
            principalAmount: newFormData.principalAmount,
            commercialQuote: newFormData.commercialQuote,
            clientQuote: newFormData.clientQuote,
            spread:
              (newFormData.spreadType == spreadTypes.PERCENTAGE ? '% ' : 'R$ ') +
              newFormData.spread,
            liquidation: newFormData.liquidation,
            clientTaxRS: newFormData.clientTaxRS,
            iofValue: newFormData.iofValue,
            irValue: newFormData.irValue,
            vet: newFormData.vet,
            finalPriceRS: newFormData.finalPriceRS
          }

          discountOnTheNextOperation.value = newFormData.discountOnTheNextOperation || []
          currencyTypeValue.value = newFormData.currencyType
          principalAmountValue.value = newFormData.principalAmount
          invoiceNumberValue.value = newFormData?.invoiceNumber
          commercialQuoteValue.value = newFormData.commercialQuote
          systemCommercialQuote.value = newFormData.systemCommercialQuote
          clientQuoteValue.value = newFormData.clientQuote
          spreadValue.value = newFormData.spread
          liquidationTypeValue.value =
            newFormData.liquidationType === 'NONE' ? '-- --' : newFormData.liquidationType
          liquidationValue.value = newFormData.liquidation
          irBaseValue.value = newFormData.ir * 100
          iofBaseValue.value = newFormData.iof * 100
          clientTaxRSValue.value = newFormData.clientTaxRS

          clientQuoteUSDValue.value = newFormData.clientQuoteUSD
          numberDIOrDUEValue.value = newFormData.numberDIOrDUE
          siscomexProtocolValue.value = newFormData?.operation?.siscomexProtocol

          vetValue.value = newFormData.vet
          finalPriceRSValue.value = newFormData.finalPriceRS
          statusValue.value = newFormData.status

          isLegacyOperation.value = newFormData.isLegacyOperation

          docusignExchangeContract.value = newFormData?.docusignExchangeContract

          const docs = formatDoc('documents', newFormData?.operation || [])

          const docsfileDIOrDUE = formatDoc('fileDIOrDUE', newFormData)
          const docsAdminDarf = formatDoc('adminDarf', newFormData)
          const docseExchangeContract = formatDoc('exchangeContract', newFormData)
          const clientTeds = formatDoc('clientTeds', newFormData)
          const swiftTeds = formatDoc('swiftTeds', newFormData)
          const fileDarf = formatDoc('fileDarf', newFormData)

          uploadedFileUrls.value.push(
            ...docs,
            ...docsfileDIOrDUE,
            ...docsAdminDarf,
            ...docseExchangeContract,
            ...clientTeds,
            ...swiftTeds,
            ...fileDarf
          )

          responsibleValue.value = newFormData.backOfficeUserId
          isTpay.value = newFormData?.institution === 'TPAY'
        }
      },
      { immediate: true }
    )

    const fetchClientBanks = async () => {
      try {
        const response = await fetchClientBank(clientId.value)
        bankOptions.value = (response.data.payload || []).map((item) => ({
          ...item,
          key: item.id,
          name: item.name
        }))
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const handleFileSelected = async (files) => {
      for (const file of files) {
        loadingUpload.value = true

        const fileInfo = {
          name: file.name,
          url: '',
          fileCode: ''
        }

        fileBinaries.value.set(file.name, file)
        uploadedFileUrls.value.push(fileInfo)
        loadingUpload.value = false
      }
    }

    const groupDocumentsByFileCode = (documents) => {
      const grouped = {}
      documents.forEach((doc) => {
        if (!grouped[doc.fileCode]) {
          grouped[doc.fileCode] = []
        }
        grouped[doc.fileCode].push(doc)
      })
      return grouped
    }

    const handleDocumentUpdate = (updatedDocuments) => {
      documents.value = groupDocumentsByFileCode(updatedDocuments)
      documentsUpdated.value = true
    }

    const removeFile = (index) => {
      if (index >= 0 && index < uploadedFileUrls.value.length) {
        uploadedFileUrls.value.splice(index, 1)
      }
    }

    const updateBank = async (id) => {
      exchangeRecipient.value = bankOptions.value.find((item) => item.key === id)
    }

    const formatOperationType = (value) => {
      return value ? (value === operationTypes.SELLING ? 'VENDA' : 'COMPRA') : ''
    }

    let validationSchema = yup.object({})
    let operationSubnatureValidation =
      operationSubnatureOptions.value.length > 0 ? yup.string().required() : yup.string()

    if (editModeEnabled.value !== true) {
      validationSchema = validationSchema.shape({
        search: yup.string().required(),
        natureType: yup.string().required(),
        operationNature: yup.string().required(),

        operationSubnature: operationSubnatureValidation,
        currencyType: yup.string().required(),

        principalAmount: greatherThan(),
        commercialQuote: greatherThan(),
        clientQuote: greatherThan(),
        spread: greatherThan(),

        clientQuoteUSD: yup.number().test(function (value) {
          if (currencyTypeValue.value !== 'USD') {
            return greatherThan().isValidSync(value)
          }
          return true
        }),

        vet: greatherThan(),
        finalPriceRS: greatherThan()
      })
    } else {
      validationSchema = validationSchema.shape({
        commercialQuote: greatherThan(),
        clientQuoteUSD: yup.number().test(function (value) {
          if (currencyTypeValue.value !== 'USD') {
            return greatherThan().isValidSync(value)
          }
          return true
        }),
        principalAmount: greatherThan(),
        finalPriceRS: greatherThan(),
        clientQuote: greatherThan(),
        vet: yup.string().required(),
        status: yup.string().required()
      })
    }

    const { handleSubmit } = useForm({
      validationSchema: validationSchema
    })

    const onSubmitAction = () => {
      alreadySubmittedOnce.value = true
      return onSubmit()
    }

    const uploadFilesToS3 = async (files, uploadApiFunction) => {
      const uploadPromises = files.map(async (fileObj) => {
        const fileBinary = fileBinaries.value.get(fileObj.name)

        if (fileBinary) {
          const formData = new FormData()
          formData.append('file', fileBinary)

          try {
            const response = await uploadApiFunction(formData)
            return {
              ...fileObj,
              url: response.data.payload.url
            }
          } catch (e) {
            handleGlobalError(e)
            throw e
          }
        } else {
          throw new Error(`Arquivo binário não encontrado: ${fileObj.name}`)
        }
      })
      return Promise.all(uploadPromises)
    }

    const handleUpload = async (fileType, uploadApiFunction) => {
      if (documents.value[fileType] === undefined || documents.value[fileType].length === 0) {
        console.debug(`Nenhum arquivo para upload em ${fileType}`)
        return
      }

      const filesToUpload = documents.value[fileType].filter(
        (file) => file.fileCode === fileType && !file.url
      )

      if (filesToUpload.length === 0) {
        console.debug(`Nenhum arquivo novo para upload em ${fileType}`)
        return
      }

      try {
        const updatedFiles = await uploadFilesToS3(filesToUpload, uploadApiFunction)

        updatedFiles.forEach((updatedFile) => {
          const index = documents.value[fileType].findIndex(
            (file) => file.name === updatedFile.name
          )
          if (index !== -1) {
            documents.value[fileType][index] = updatedFile
          }
        })
        console.debug(`Upload concluído para ${fileType}`)
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const onSubmit = handleSubmit(async () => {
      if (editModeEnabled.value !== true) {
        const body = {
          posOperation: {
            clientName: clientNameValue.value,
            clientId: clientId.value.toString(),
            documentId: documentId.value,
            clientType: clientType.value,
            currencyType: currencyTypeValue.value,
            principalAmount: parseFloat(principalAmountValue.value),
            commercialQuote: parseFloat(commercialQuoteValue.value),
            clientQuote: parseFloat(clientQuoteValue.value),
            spread: parseFloat(spreadValue.value),
            clientTaxRS: parseFloat(clientTaxRSValue.value),
            liquidation: parseFloat(liquidationValue.value),

            iof: parseFloat(iofBaseValue.value / 100),
            ir: parseFloat(irBaseValue.value / 100),

            vet: parseFloat(vetValue.value),
            finalPriceRS: parseFloat(finalPriceRSValue.value),
            financialInstitution: financialInstitutionValue.value,
            payTo: payToValue.value,
            spreadType: 'PERCENTAGE',
            status: 'OPEN',
            operationType: operationType.value,
            clientQuoteUSD: clientQuoteUSDValue.value,
            liquidationType: liquidationTypeValue.value,

            numberDIOrDUE: numberDIOrDUEValue.value,
            invoiceNumber: invoiceNumberValue.value,

            operationSubType: natureTypeValue.value
          },
          siscomexProtocol: siscomexProtocolValue.value,
          operationNature: operationNatureValue.value,
          operationSubnature: operationSubnatureValue.value,
          exchangeRecipient: exchangeRecipient.value,
          backOfficeUserId: responsibleValue.value
        }
        emit('onSubmitted', body)
      } else {
        await handleUpload('documents', uploadDocument)
        await handleUpload('adminDarf', uploadDarfAdmin)
        await handleUpload('fileDIOrDUE', uploadDocument)
        await handleUpload('exchangeContract', uploadExchangeContract)
        await handleUpload('swiftTeds', uploadSwift)
        await handleUpload('clientTeds', uploadTed)
        await handleUpload('fileDarf', uploadDarf)

        const body = {
          ...props.initialFormData,
          principalAmount: parseFloat(principalAmountValue.value),
          invoiceNumber: invoiceNumberValue.value,

          operation: {
            ...props.initialFormData.operation,
            siscomexProtocol: siscomexProtocolValue.value,
            ...(documentsUpdated.value && {
              documents: documents.value?.documents || undefined
            })
          },

          ...(documentsUpdated.value && {
            clientTeds: documents.value?.clientTeds || undefined,
            swiftTeds: documents.value?.swiftTeds || undefined,
            fileDIOrDUE:
              Array.isArray(documents.value.fileDIOrDUE) && documents.value.fileDIOrDUE.length > 0
                ? documents.value.fileDIOrDUE[0]
                : undefined,
            adminDarf:
              Array.isArray(documents.value.adminDarf) && documents.value.adminDarf.length > 0
                ? documents.value.adminDarf[0]
                : undefined,
            exchangeContract:
              Array.isArray(documents.value.exchangeContract) &&
              documents.value.exchangeContract.length > 0
                ? documents.value.exchangeContract[0]
                : undefined,
            fileDarf:
              Array.isArray(documents.value.fileDarf) && documents.value.fileDarf.length > 0
                ? documents.value.fileDarf[0]
                : undefined
          }),

          numberDIOrDUE: numberDIOrDUEValue.value,
          docusignExchangeContract: docusignExchangeContract.value || undefined,
          ir: parseFloat(irBaseValue.value / 100),
          iof: parseFloat(iofBaseValue.value / 100),
          commercialQuote: parseFloat(commercialQuoteValue.value),
          clientQuote: parseFloat(clientQuoteValue.value),
          spread: parseFloat(spreadValue.value),
          clientTaxRS: parseFloat(clientTaxRSValue.value),
          liquidation: parseFloat(liquidationValue.value),
          vet: parseFloat(vetValue.value),
          finalPriceRS: parseFloat(finalPriceRSValue.value),
          status: statusValue.value,
          backOfficeUserId: responsibleValue.value
        }

        emit('onSubmitted', body)
      }
    })

    const numberDIOrDUELabel = computed(() => {
      return operationNatureValue.value === operationNatureTypes.EXPMERC
        ? 'Número DU-E'
        : 'Número DI'
    })

    const tedFinancialInstitutionLabel = computed(() => {
      return (
        'TED para transferência ' +
        (natureTypeValue.value === transactionTypes.TRADESELL ? 'venda' : 'compra')
      )
    })

    const handleUploadDocusign = async (fileType, uploadApiFunction) => {
      if (!uploadedDocusignFileUrls.value || uploadedDocusignFileUrls.value.length === 0) {
        console.debug(`Nenhum arquivo para upload em ${fileType}`)
        return
      }

      const filesToUpload = uploadedDocusignFileUrls.value.filter(
        (file) => file.fileCode === fileType && !file.url
      )

      if (filesToUpload.length === 0) {
        console.debug(`Nenhum arquivo novo para upload em ${fileType}`)
        return
      }

      try {
        const updatedFiles = await uploadFilesToS3(filesToUpload, uploadApiFunction)

        updatedFiles.forEach((updatedFile) => {
          const index = uploadedDocusignFileUrls.value.findIndex(
            (file) => file.name === updatedFile.name
          )
          if (index !== -1) {
            uploadedDocusignFileUrls.value[index] = updatedFile
          }
        })
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const handleDocusign = async (files) => {
      for (const file of files) {
        loadingUpload.value = true

        const fileInfo = {
          name: file.name,
          url: '',
          fileCode: 'docusign'
        }

        fileBinaries.value.set(file.name, file)
        uploadedDocusignFileUrls.value.push(fileInfo)
        loadingUpload.value = false
      }
    }

    const onSubmitDocusign = async () => {
      try {
        loadingSubmitDocusign.value = true
        await handleUploadDocusign('docusign', uploadDocument)
        const contract = uploadedDocusignFileUrls.value[0].url || undefined

        const body = {
          fileUrl: contract,
          documentName: documentNameValue.value,
          emailSubject: emailSubjectValue.value,
          signerEmail: signerEmailValue.value,
          signerName: signerNameValue.value,
          bs2ExchangeContractId: idContractValue.value,
          metadata: {
            financialInstitution: financialInstitutionValue.value,
            date: formatISO(new Date())
          }
        }
        const response = await createPosDocsSignature(body, props.initialFormData.id)
        toast('Documento enviado para assinatura', {
          theme: 'auto',
          type: 'success',
          dangerouslyHTMLString: true
        })

        docusignExchangeContract.value = response.data.payload
        uploadedDocusignFileUrls.value = []
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loadingSubmitDocusign.value = false
      }
    }

    return {
      clientNameValue,
      clientType,
      clientApiResult,
      clientId,
      documentId,
      operationType,
      natureTypeValue,
      operationOptions,
      operationNatureValue,
      operationNatureOptions,
      operationSubnatureValue,
      operationSubnatureOptions,
      closeDateValue,
      createAtDateValue,
      invoiceNumberValue,

      clientTaxRSValue,
      commercialQuoteValue,
      systemCommercialQuote,
      clientQuoteValue,
      spreadType,
      spreadValue,
      liquidationValue,
      iofBaseValue,
      vetValue,
      finalPriceRSValue,

      principalAmountValue,
      configMoney,
      configClientMoney,
      configCommercialMoney,
      configIof,
      irBaseValue,
      configIr,
      liquidationTypeOptions,
      sellOffRealValue,

      currencyTypeValue,
      currencyOptions,

      responsibleValue,
      responsibleOptions,

      financialInstitutionValue,
      financialInstitutionOptions,

      statusValue,
      posStatusOptions,

      closeModal,
      onSubmitAction,
      contentActive,

      handleFileSelected,
      handleDocusign,
      uploadedDocusignFileUrls,
      handleDocumentUpdate,
      uploadedFileUrls,
      loadingUpload,
      removeFile,
      typeFile,

      bankChangeValue,
      bankOptions,
      exchangeRecipient,
      updateBank,

      tedFinancialInstitutionValue,
      tedFinancialInstitutionOptions,

      selectedClient,

      clientQuoteUSDValue,
      siscomexProtocolValue,
      numberDIOrDUEValue,

      editModeEnabled,
      numberFormat,
      formatOperationType,
      operationDetail,
      tedClientDetail,
      beneficiaryAbroadDetail,
      payerNameValue,
      liquidationTypeValue,
      discountOnTheNextOperation,
      operationTypes,
      numberDIOrDUELabel,
      tedFinancialInstitutionLabel,

      spreadTypes,
      emptyPlaceholder,
      hasInputAllowed,
      isLegacyOperation,
      transactionTypes,

      loadingSubmitDocusign,
      documentNameValue,
      emailSubjectValue,
      signerEmailValue,
      signerNameValue,
      idContractValue,
      onSubmitDocusign,

      docusignExchangeContract,
      isTpay
    }
  }
}
</script>

<template>
  <div class="box-content d-flex justify-content-end">
    <div :class="['back', { active: contentActive }]" @click="closeModal()"></div>
    <div :class="['content', { active: contentActive }]">
      <div class="row">
        <h4 class="col-8">{{ title }}</h4>
        <div v-if="editModeEnabled" class="col-4 text-end">
          <h5>
            <span :class="operationType === operationTypes.SELLING ? 'color-sell' : 'color-buy'">
              <b>{{ formatOperationType(operationType) }}</b>
            </span>
            {{ currencyTypeValue }} {{ numberFormat(principalAmountValue) }}
          </h5>
          <p>{{ createAtDateValue }}</p>
        </div>
      </div>

      <div class="col-12">
        <div class="row">
          <div class="col-12 form-input">
            <div v-if="isLegacyOperation" class="alert alert-warning" role="alert">
              <i class="bi bi-exclamation-triangle-fill"></i>
              Esta é uma operação legada, e pode não apresentar todas as informações na integra.
              Consulte a equipe de desenvolvimento para mais informações.
            </div>
          </div>

          <form-input
            v-if="editModeEnabled && !isTpay"
            className="col-12 form-input"
            v-model="documentId"
            :label="clientType === 'PJ' ? 'CNPJ' : 'CPF'"
            id="documentId"
            name="documentId"
            type="text"
            :isDisabled="!hasInputAllowed() || editModeEnabled"
          />

          <form-search
            className="col-12 form-input"
            label="Cliente"
            v-model="clientNameValue"
            id="search"
            name="search"
            type="search"
            icon="bi bi-search"
            placeholder="Nome do cliente"
            :results="clientApiResult"
            @onSelect="selectedClient"
            :isDisabled="!hasInputAllowed() || editModeEnabled"
          />

          <form-input
            v-if="editModeEnabled"
            className="col-12 form-input"
            v-model="financialInstitutionValue"
            label="Instituição financeira"
            id="financialInstitutionValue"
            name="financialInstitutionValue"
            type="text"
            :isDisabled="!hasInputAllowed() || editModeEnabled"
          />

          <form-select
            v-if="!isTpay"
            class="col-12 form-input"
            label="Tipo de operação"
            v-model="natureTypeValue"
            :items="operationOptions"
            id="natureType"
            name="natureType"
            :isDisabled="!hasInputAllowed() || operationOptions.length === 0 || editModeEnabled"
          />

          <form-select
            v-if="!isTpay"
            class="col-12 form-input"
            label="Natureza"
            v-model="operationNatureValue"
            :items="operationNatureOptions"
            id="operationNature"
            name="operationNature"
            :isDisabled="
              !hasInputAllowed() || operationNatureOptions.length === 0 || editModeEnabled
            "
          />

          <form-select
            v-if="operationSubnatureOptions.length > 0 && !isTpay"
            class="col-12 form-input"
            label="Sub natureza"
            v-model="operationSubnatureValue"
            :items="operationSubnatureOptions"
            id="operationSubnature"
            name="operationSubnature"
            :isDisabled="
              !hasInputAllowed() || operationSubnatureOptions.length === 0 || editModeEnabled
            "
          />

          <form-input
            v-if="editModeEnabled"
            className="col-12 form-input"
            v-model="closeDateValue"
            label="Data de fechamento"
            id="closeDate"
            name="closeDate"
            type="text"
            :isDisabled="!hasInputAllowed() || editModeEnabled"
          />

          <app-operation-details
            v-if="editModeEnabled"
            :edit-mode-enabled="editModeEnabled"
            :operation-detail="operationDetail"
            :operation-type="operationType"
            :operation-types="operationTypes"
            :currency-type-value="currencyTypeValue"
            :principal-amount-value="principalAmountValue"
            :invoice-number-value="invoiceNumberValue"
            :empty-placeholder="emptyPlaceholder"
            :number-format="numberFormat"
          />

          <app-title
            v-if="editModeEnabled && discountOnTheNextOperation.length > 0"
            label="DESCONTOS DESTA OPERAÇÃO:"
          />

          <div v-if="editModeEnabled && discountOnTheNextOperation.length > 0" class="col-12">
            <table class="discount-detail">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>CNPJ</th>
                  <th>Código Promocional</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in discountOnTheNextOperation" :key="item.promotionalCode">
                  <td>{{ item.name }}</td>
                  <td>{{ item.cnpj }}</td>
                  <td>{{ item.promotionalCode }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <app-customer-ted-transfer
            v-if="editModeEnabled && operationType === operationTypes.BUYING && !isTpay"
            :edit-mode-enabled="editModeEnabled"
            :operation-type="operationType"
            :operation-types="operationTypes"
            :ted-client-detail="tedClientDetail"
            :empty-placeholder="emptyPlaceholder"
          />

          <app-recipient-transfer
            v-if="editModeEnabled && operationType === operationTypes.BUYING"
            :edit-mode-enabled="editModeEnabled"
            :operation-type="operationType"
            :operation-types="operationTypes"
            :beneficiary-abroad-detail="beneficiaryAbroadDetail"
            :empty-placeholder="emptyPlaceholder"
            :liquidation-type-value="liquidationTypeValue"
          />

          <app-customer-transfer
            :editModeEnabled="editModeEnabled"
            :operationType="operationType"
            :operationTypes="operationTypes"
            :tedClientDetail="tedClientDetail"
            :emptyPlaceholder="emptyPlaceholder"
            :currencyTypeValue="currencyTypeValue"
            :principalAmountValue="principalAmountValue"
            :invoiceNumberValue="invoiceNumberValue"
            :payerNameValue="payerNameValue"
            :operationDetail="operationDetail"
            :liquidationTypeValue="liquidationTypeValue"
            :numberFormat="numberFormat"
          />

          <app-title label="DETALHES DA OPERAÇÃO" />
          <form-select
            v-if="!editModeEnabled"
            class="col-12 form-input"
            label="Moeda selecionada"
            v-model="currencyTypeValue"
            :items="currencyOptions"
            id="currencyType"
            name="currencyType"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            v-if="currencyTypeValue && currencyTypeValue !== 'USD'"
            class="col-12 form-input"
            v-model="clientQuoteUSDValue"
            label="Cotação do dolar"
            id="clientQuoteUSD"
            name="clientQuoteUSD"
            :config="configClientMoney"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="principalAmountValue"
            label="Cliente vendeu"
            id="principalAmount"
            name="principalAmount"
            :config="configClientMoney"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            className="col-12 form-input"
            v-model="invoiceNumberValue"
            label="Invoice"
            id="invoiceNumber"
            name="invoiceNumber"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="systemCommercialQuote"
            label="Cotação comercial do sistema"
            id="systemCommercialQuote"
            name="systemCommercialQuote"
            :config="configCommercialMoney"
            :isDisabled="true"
          />

          <form-money
            class="col-12 form-input"
            v-model="commercialQuoteValue"
            label="Comercial"
            id="commercialQuote"
            name="commercialQuote"
            :config="configCommercialMoney"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="clientQuoteValue"
            label="Cotação"
            id="clientQuote"
            name="clientQuote"
            :config="configCommercialMoney"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="spreadValue"
            label="Spread"
            id="spread"
            name="spread"
            :config="spreadType == spreadTypes.PERCENTAGE ? configIr : configCommercialMoney"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="clientTaxRSValue"
            label="Tarifa"
            id="clientTaxRS"
            name="clientTaxRS"
            :config="configMoney"
            :isDisabled="!hasInputAllowed()"
          />

          <form-select
            v-if="!editModeEnabled"
            class="col-12 form-input"
            label="Liquidação ME"
            v-model="liquidationTypeValue"
            :items="liquidationTypeOptions"
            id="liquidationType"
            name="liquidationType"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="liquidationValue"
            label="Valor liquidação"
            id="liquidation"
            name="liquidation"
            :config="configCommercialMoney"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="iofBaseValue"
            label="Base IOF"
            id="iofBase"
            name="iofBase"
            :config="configIof"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="irBaseValue"
            label="Base IR"
            id="irBase"
            name="irBase"
            :config="configIr"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="vetValue"
            label="Vet dólar"
            id="vet"
            name="vet"
            :config="configCommercialMoney"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="finalPriceRSValue"
            label="Vet operação"
            id="finalPriceRS"
            name="finalPriceRS"
            :config="configMoney"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            v-if="editModeEnabled && natureTypeValue === operationTypes.BUYING"
            class="col-12 form-input"
            v-model="principalAmountValue"
            label="Valor USD:"
            id="principalAmount"
            name="principalAmount"
            :config="configMoney"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            v-if="natureTypeValue === operationTypes.BUYING || natureTypeValue === 'TRADEBUY'"
            className="col-12 form-input"
            label="Protocolo SISCOMEX"
            v-model="siscomexProtocolValue"
            id="siscomexProtocol"
            name="siscomexProtocol"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            v-if="
              natureTypeValue !== null &&
              operationNatureValue !== null &&
              (operationNatureValue === 'EXPMERC' || operationNatureValue === 'IMPORMERC')
            "
            className="col-12 form-input"
            :label="numberDIOrDUELabel"
            v-model="numberDIOrDUEValue"
            id="numberDIOrDUE"
            name="numberDIOrDUE"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <app-documentation
            :edit-mode-enabled="editModeEnabled"
            :has-input-allowed="hasInputAllowed"
            :loading-upload="loadingUpload"
            :uploaded-file-urls="uploadedFileUrls"
            :type-file="typeFile"
            :principal-amount-value="principalAmountValue"
            :docusignExchangeContract="docusignExchangeContract"
            @file-selected="handleFileSelected"
            @document-updated="handleDocumentUpdate"
            @file-removed="removeFile"
            @docusign-selected="handleDocusign"
          />

          <div class="col-12 mt-1" v-if="uploadedDocusignFileUrls.length > 0">
            <div class="status-docusign">
              <div>
                <i class="bi bi-file-earmark-text-fill"></i> Contrato para envio:
                {{ uploadedDocusignFileUrls[0].name }}
              </div>
            </div>
          </div>

          <div v-if="uploadedDocusignFileUrls.length > 0" class="docusignFiles">
            <form-input
              className="col-12 form-input"
              label="Nome do responsável"
              v-model="documentNameValue"
              id="documentName"
              name="documentName"
              type="text"
              :isDisabled="!hasInputAllowed()"
            />

            <form-input
              className="col-12 form-input"
              label="E-mail de retorno"
              v-model="emailSubjectValue"
              id="emailSubject"
              name="emailSubject"
              type="text"
              :isDisabled="!hasInputAllowed()"
            />

            <form-input
              className="col-12 form-input"
              label="Nome de que irá assinar"
              v-model="signerNameValue"
              id="signerName"
              name="signerName"
              type="text"
              :isDisabled="!hasInputAllowed()"
            />

            <form-input
              className="col-12 form-input"
              label="Email do cliente"
              v-model="signerEmailValue"
              id="signerEmail"
              name="signerEmail"
              type="text"
              :isDisabled="!hasInputAllowed()"
            />

            <form-input
              v-if="financialInstitutionValue === 'BS2'"
              className="col-12 form-input"
              label="ID do contrato"
              v-model="idContractValue"
              id="idContract"
              name="idContract"
              type="text"
              :isDisabled="!hasInputAllowed()"
            />

            <form-button
              class="col-12 form-input"
              title="Enviar contrato para assinatura"
              :loading="loadingSubmitDocusign"
              :isDisabled="!hasInputAllowed()"
              @clicked="onSubmitDocusign"
            />
          </div>

          <app-bank-details
            :bank-options="bankOptions"
            :bank-change-value="bankChangeValue"
            :client-id="clientId"
            :exchange-recipient="exchangeRecipient"
            :empty-placeholder="emptyPlaceholder"
            :edit-mode-enabled="editModeEnabled"
            @input="bankChangeValue = $event.target.value"
          />

          <form-select
            v-if="!editModeEnabled"
            class="col-12 form-input"
            label="Instituição Financeira"
            v-model="financialInstitutionValue"
            :items="financialInstitutionOptions"
            :isDisabled="!hasInputAllowed() || financialInstitutionOptions.length === 0"
            id="financialInstitution"
            name="financialInstitution"
          />

          <form-select
            v-if="!editModeEnabled"
            class="col-12 form-input"
            :label="tedFinancialInstitutionLabel"
            v-model="tedFinancialInstitutionValue"
            :items="tedFinancialInstitutionOptions"
            id="tedFinancialInstitution"
            name="tedFinancialInstitution"
            :isDisabled="!hasInputAllowed() || tedFinancialInstitutionOptions.length === 0"
          />

          <form-select
            class="col-12 form-input"
            label="Operador responsável"
            v-model="responsibleValue"
            :items="responsibleOptions"
            id="responsible"
            name="responsible"
            :isDisabled="!hasInputAllowed()"
          />

          <form-select
            v-if="editModeEnabled"
            class="col-12 form-input"
            label="Status"
            v-model="statusValue"
            :items="posStatusOptions"
            id="status"
            name="status"
            :isDisabled="!hasInputAllowed()"
          />

          <form-button
            class="col-12 form-input"
            title="Enviar"
            :loading="loading"
            :isDisabled="!hasInputAllowed() || isLegacyOperation"
            @clicked="onSubmitAction"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.status-docusign {
  color: var(--sixth-color);
  background-color: rgba(225, 225, 225, 0.2);
  border-radius: 9px;
  padding: 15px;
  font-size: 14px;
  line-height: 28px;

  p {
    color: var(--sixth-color);
    margin-bottom: 0;
    font-size: 14px;
  }
}
.discount-detail {
  color: var(--sixth-color);
  background-color: rgb(0 0 0 / 7%);
  border: solid 1px rgb(0 0 0 / 15%);
  border-radius: 9px;
  font-size: 12px;
  width: 100%;
  border-collapse: separate;
  border: solid #ccc 1px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;

  th {
    padding: 26px 26px 5px 26px;
  }

  td {
    padding: 10px 26px;
  }

  tbody {
    tr:last-child {
      td {
        padding: 10px 26px 26px 26px;
      }
    }
  }
}
.operation {
  color: var(--sixth-color);
  background-color: rgb(0 0 0 / 7%);
  border: solid 1px rgb(0 0 0 / 15%);
  border-radius: 9px;
  padding: 26px 20px 10px 25px;
  font-size: 14px;
  line-height: 28px;

  p {
    padding: 0;
    font-weight: 600;
  }
}
</style>
