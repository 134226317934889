<template>
  <div v-if="editModeEnabled" class="col-12 mt-4">
    <div class="operation">
      <div class="row">
        <i class="bi bi-copy" @click="copyOperation" title="Copiar detalhes da operação"></i>

        <div class="col-6">
          <label>Operação:</label>
          <p>{{ operationDetail.id }}</p>
        </div>

        <div class="col-6">
          <label>Cliente {{ formatOperationType(operationType) }}:</label>
          <p>{{ currencyTypeValue }} {{ numberFormat(principalAmountValue, 'pt-BR') }}</p>
        </div>

        <div class="col-6">
          <label>{{ currencyTypeValue }} comercial:</label>
          <p>
            R$
            {{ numberFormat(operationDetail.commercialQuote, { minimumFractionDigits: 4 }) }}
          </p>
        </div>

        <div class="col-6">
          <label>Cotação:</label>
          <p>
            R$
            {{ numberFormat(operationDetail.clientQuote, { minimumFractionDigits: 4 }) }}
          </p>
        </div>

        <div class="col-6">
          <label>Spread:</label>
          <p>{{ operationDetail.spread }}</p>
        </div>

        <div class="col-6">
          <label>Liquidação:</label>
          <p>R$ {{ numberFormat(operationDetail.liquidation) || emptyPlaceholder }}</p>
        </div>

        <div class="col-6">
          <label>Tarifa total:</label>
          <p>R$ {{ numberFormat(operationDetail.clientTaxRS) || emptyPlaceholder }}</p>
        </div>

        <div class="col-6">
          <label>IOF:</label>
          <p>R$ {{ numberFormat(operationDetail.iofValue) || emptyPlaceholder }}</p>
        </div>

        <div class="col-6">
          <label>IR:</label>
          <p>
            R$
            {{
              numberFormat(operationDetail.irValue, { maximumFractionDigits: 2 }) ||
              emptyPlaceholder
            }}
          </p>
        </div>

        <div class="col-6">
          <label>Invoice:</label>
          <p>{{ invoiceNumberValue || emptyPlaceholder }}</p>
        </div>

        <div class="col-6">
          <label>VET dólar:</label>
          <p :class="operationType === operationTypes.SELLING ? 'color-sell' : 'color-buy'">
            R$ {{ numberFormat(operationDetail.vet, { minimumFractionDigits: 4 }) }}
          </p>
        </div>

        <div class="col-6">
          <label>VET operação:</label>
          <p :class="operationType === operationTypes.SELLING ? 'color-sell' : 'color-buy'">
            R$ {{ numberFormat(operationDetail.finalPriceRS) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from 'vue3-toastify'

export default {
  name: 'DetalhesOperacao',

  props: {
    editModeEnabled: {
      type: Boolean,
      default: false
    },
    operationDetail: {
      type: Object,
      required: true
    },
    operationType: {
      type: String,
      required: true
    },
    operationTypes: {
      type: Object,
      required: true
    },
    currencyTypeValue: {
      type: String,
      default: ''
    },
    principalAmountValue: {
      type: [Number, String],
      default: 0
    },
    invoiceNumberValue: {
      type: String,
      default: ''
    },
    emptyPlaceholder: {
      type: String,
      default: '-'
    },
    numberFormat: {
      type: Function,
      required: true
    }
  },

  methods: {
    formatOperationType(type) {
      return type === this.operationTypes.BUYING ? 'comprou' : 'vendeu'
    },

    copyOperation() {
      const fmtBRL = (value, options = {}) => {
        return value ? `R$ ${this.numberFormat(value, 'pt-BR', options)}` : this.emptyPlaceholder
      }

      const fmtText = (title, value) => `${title}: ${value}\n`

      const items = [
        fmtText('Operação', this.operationDetail.id),
        fmtText(
          `Cliente ${this.formatOperationType(this.operationType)}`,
          `${this.currencyTypeValue} ${fmtBRL(this.principalAmountValue)}`
        ),
        fmtText(
          `${this.currencyTypeValue} comercial`,
          fmtBRL(this.operationDetail.commercialQuote, { minimumFractionDigits: 4 })
        ),
        fmtText('Cotação', fmtBRL(this.operationDetail.clientQuote, { minimumFractionDigits: 4 })),
        fmtText('Spread', this.operationDetail.spread || this.emptyPlaceholder),
        fmtText('Liquidação', fmtBRL(this.operationDetail.liquidation)),
        fmtText('Tarifa total', fmtBRL(this.operationDetail.clientTaxRS)),
        fmtText('IOF', fmtBRL(this.operationDetail.iofValue)),
        fmtText('IR', fmtBRL(this.operationDetail.irValue)),
        fmtText('Invoice', this.invoiceNumberValue || this.emptyPlaceholder),
        fmtText('VET dólar', fmtBRL(this.operationDetail.vet, { minimumFractionDigits: 4 })),
        fmtText('VET operação', fmtBRL(this.operationDetail.finalPriceRS))
      ]

      const formattedText = items.join('')

      navigator.clipboard
        .writeText(formattedText)
        .then(() => {
          toast('Operação copiada com sucesso!', {
            theme: 'auto',
            type: 'success'
          })
        })
        .catch((e) => {
          console.error(e)
          toast('Erro ao copiar a operação.', {
            theme: 'auto',
            type: 'error'
          })
        })
    }
  }
}
</script>
