<script>
import { onMounted, ref } from 'vue'
import { useMeta } from 'vue-meta'

import formTextarea from '@/components/form/textarea.vue'
import appLoading from '@/components/shared/loading.vue'
import appModal from '@/components/shared/modal.vue'
import { fetchAlertMessage, updateAlertMessage } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'

export default {
  name: 'appPlatformAlert',

  components: {
    appLoading,
    formTextarea,
    appModal
  },

  setup() {
    useMeta({ title: 'Alerta da plataforma' })

    const alert = ref('')
    const loading = ref(false)

    const modalFormIsShown = ref(false)
    const modalConfig = ref({})
    const isAlertDisable = ref(true)

    const fetchAlert = async () => {
      loading.value = true
      try {
        const response = await fetchAlertMessage()
        alert.value = response.data.payload
        isAlertDisable.value = response.data.payload.isEnabled
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loading.value = false
      }
    }

    onMounted(() => {
      fetchAlert()
    })

    const handleEdit = async () => {
      modalConfig.value = {
        title: 'Você tem certeza?',
        icon: 'bi bi-exclamation-square-fill',
        description: 'Deseja mesmo atualizar a mensagem de alerta da plataforma?',
        showButtons: true,
        submitButtonText: 'Confirmar',
        backButtonText: 'Cancelar'
      }
      modalFormIsShown.value = true
    }

    const loadingModal = ref(false)

    const handleUpdateAlert = async () => {
      try {
        loadingModal.value = true
        alert.value
        const payload = {
          isEnabled: isAlertDisable.value,
          message: alert.value.message
        }

        await updateAlertMessage(payload)
        fetchAlert()
      } catch (e) {
        handleGlobalError(e)
      } finally {
        modalFormIsShown.value = false
        loadingModal.value = false
      }
    }

    return {
      alert,
      isAlertDisable,
      loading,

      modalFormIsShown,
      modalConfig,
      loadingModal,
      handleUpdateAlert,
      handleEdit
    }
  }
}
</script>

<template>
  <div>
    <app-modal
      v-if="modalFormIsShown"
      :modalConfig="modalConfig"
      :loading="loadingModal"
      @submit="handleUpdateAlert"
      @onClose="modalFormIsShown = false"
    />

    <div class="row title">
      <h2 class="col">Alerta da plataforma</h2>
      <div class="col-12 col-md-2 form-input">
        <button
          :class="['secondary', { 'close-market': isAlertDisable }]"
          @click="handleEdit(), (isAlertDisable = !isAlertDisable)"
        >
          {{ isAlertDisable ? 'Desativar' : 'Ativar' }}
        </button>
      </div>
      <div class="col-12 col-md-3 form-input">
        <button class="secondary" @click="handleEdit()">
          <i class="bi bi-cloud-download-fill"></i>
          Salvar
        </button>
      </div>
    </div>

    <div class="row pb-5 mt-3">
      <div class="col-12 form-table">
        <form-textarea
          v-if="!loading"
          class="col-12 form-input"
          :rows="15"
          v-model="alert.message"
        />

        <app-loading v-if="loading" class="pt-5 mt-5 mx-auto" :duration="'2.3s'" />
      </div>
    </div>
  </div>
</template>
