import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'v-calendar/style.css'
import 'vue3-toastify/dist/index.css'
import '@composables/utils/setupDatadog'

import { registerServiceWorker } from '@composables/utils/setupRegisterServiceWorker'
import { setupSentry } from '@composables/utils/setupSentry'
import { getPlatformFromUrl } from '@composables/utils/useWhitelabelService'
import router from '@router'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VCalendar from 'v-calendar'
import money from 'v-money3'
import { createApp } from 'vue'
import { createMetaManager } from 'vue-meta'
import Vue3Toasity from 'vue3-toastify'

import App from '@/App.vue'

registerServiceWorker()

const platform = getPlatformFromUrl()

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App)
app.config.globalProperties.platform = platform
app.use(router)
app.use(pinia)
app.use(VCalendar, {})
app.use(money)
app.use(Vue3Toasity)
app.use(createMetaManager())
setupSentry(app)
app.mount('#app')
