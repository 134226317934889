<template>
  <div>
    <div class="row g-1" v-if="!isAddingBank">
      <form-select
        :class="selectedBank ? 'col-6 form-input' : 'col-8 form-input'"
        label="Dados Bancários do Beneficiário"
        v-model="selectedBank"
        :items="bankOptions"
        id="banks"
        name="banks"
      />

      <form-button
        v-if="selectedBank"
        class="col-2 form-input"
        title="Editar"
        @click="editBankAccount"
      />

      <form-button
        v-if="!isAddingBank"
        class="col-4 form-input"
        title="Adicionar Banco"
        @click="addBank"
      />
    </div>

    <div v-else class="row g-1 mb-5">
      <app-title label="DADOS BANCÁRIOS DO BENEFICIÁRIO" />
      <form-input
        class="col-12 form-input"
        label="Nome de quem irá receber"
        id="name"
        v-model="newBank.name"
        name="name"
        type="text"
      />
      <form-select
        class="col-6 form-input"
        label="País"
        v-model="selectedCountry"
        :items="countries"
        id="countries"
        name="countries"
      />
      <form-input
        class="col-6 form-input"
        label="Código SWIFT/BIC"
        id="swift"
        v-model="newBank.swift"
        name="swift"
        type="text"
      />
      <form-input
        class="col-6 form-input"
        label="Banco"
        id="codeAccount"
        v-model="newBank.bank"
        name="codeAccount"
        type="text"
      />
      <form-input
        class="col-6 form-input"
        label="Número da Conta"
        id="numberAccount"
        v-model="newBank.account"
        name="numberAccount"
        type="text"
      />
      <form-input
        class="col-12 form-input"
        label="Banco Intermediário"
        id="intermediaryName"
        v-model="newBank.intermediaryBankAccount.name"
        name="intermediaryName"
        type="text"
      />

      <form-input
        class="col-6 form-input"
        label="Conta"
        id="intermediaryAccount"
        v-model="newBank.intermediaryBankAccount.account"
        name="intermediaryAccount"
        type="text"
      />

      <form-input
        class="col-6 form-input"
        label="Código Swift"
        id="intermediarySwift"
        v-model="newBank.intermediaryBankAccount.swift"
        name="intermediarySwift"
        type="text"
      />
      <form-input
        class="col-12 form-input"
        label="For Further Credit ou demais referências"
        id="forFurtherCredit"
        v-model="newBank.forFurtherCredit"
        name="forFurtherCredit"
        type="text"
      />
      <form-button class="col-6 form-input" title="Cancelar" @click="close" />
      <form-button class="col-6 form-input" title="Salvar" :loading="loading" @click="saveBank" />
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'

import formButton from '@/components/form/button.vue'
import formInput from '@/components/form/input.vue'
import formSelect from '@/components/form/select.vue'
import appTitle from '@/components/shared/title.vue'
import { createNewBank, editBank } from '@/services/api'
import { useCache } from '@/stores/cache.js'

export default {
  name: 'appBankOptions',

  components: {
    formSelect,
    formInput,
    formButton,
    appTitle
  },

  props: {
    initialBanks: {
      type: Array,
      default: () => []
    },
    clientId: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [String, Number],
      default: ''
    }
  },

  setup(props, { emit }) {
    const bankOptions = ref(props.initialBanks)
    const selectedBank = ref('')
    const selectedCountry = ref('')
    const isAddingBank = ref(false)
    const countries = ref([])
    const loading = ref(false)
    const editingBankId = ref('')

    const cacheCountriesBanks = useCache().getCountriesBanks
    countries.value = cacheCountriesBanks

    const newBank = ref({
      name: '',
      country: '',
      bank: '',
      account: '',
      swift: '',
      intermediaryBankAccount: {
        name: '',
        account: '',
        swift: ''
      },
      forFurtherCredit: ''
    })

    watch(
      () => props.initialBanks,
      (newVal) => {
        bankOptions.value = newVal
      },
      { deep: true }
    )

    watch(
      () => props.modelValue,
      (newVal) => {
        selectedBank.value = newVal
      },
      { immediate: true }
    )

    watch(selectedBank, (newValue) => {
      emit('update:modelValue', newValue)
    })

    const addBank = () => {
      isAddingBank.value = true
    }

    const close = () => {
      isAddingBank.value = false
    }

    const editBankAccount = async () => {
      const bankToEdit = bankOptions.value.find((bank) => bank.id === selectedBank.value)
      const country = countries.value.find((country) => country.country === bankToEdit.country)

      if (bankToEdit) {
        newBank.value = {
          ...bankToEdit,
          intermediaryBankAccount: bankToEdit.intermediaryBankAccount || {
            name: '',
            account: '',
            swift: ''
          }
        }
        editingBankId.value = bankToEdit.id
        selectedCountry.value = country.id
        isAddingBank.value = true
      }
    }

    const createBankAccountBody = () => ({
      name: newBank.value.name,
      country:
        countries.value.find((country) => country.id === selectedCountry.value)?.country || '',
      bank: newBank.value.bank,
      account: newBank.value.account,
      swift: newBank.value.swift,
      intermediaryBankAccount: {
        name: newBank.value.intermediaryBankAccount.name,
        account: newBank.value.intermediaryBankAccount.account,
        swift: newBank.value.intermediaryBankAccount.swift
      },
      forFurtherCredit: newBank.value.forFurtherCredit,
      active: true,
      clientId: props.clientId
    })

    const resetNewBankForm = () => {
      newBank.value = {
        name: '',
        country: '',
        bank: '',
        account: '',
        swift: '',
        intermediaryBankAccount: {
          name: '',
          account: '',
          swift: ''
        },
        forFurtherCredit: ''
      }
    }

    const updateBankListAfterEdit = async (index, body) => {
      const updateBankResponse = await editBank(body.id, body)
      bankOptions.value[index] = {
        ...updateBankResponse.data,
        key: updateBankResponse.data.id,
        name: updateBankResponse.data.name
      }
      emit('edit', updateBankResponse.data.id)
    }

    const addBankToList = async (body) => {
      const newBankResponse = await createNewBank(body)
      bankOptions.value.push({
        ...newBankResponse.data,
        key: newBankResponse.data.id,
        name: newBankResponse.data.name
      })
      selectedBank.value = newBankResponse.data.id
    }

    const saveBank = async () => {
      const bankAccountBody = createBankAccountBody()

      if (editingBankId.value != '') {
        const bankToEditIndex = bankOptions.value.findIndex(
          (bank) => bank.id === selectedBank.value
        )

        if (bankToEditIndex !== -1) {
          const bankToEdit = bankOptions.value[bankToEditIndex]
          const body = {
            ...bankToEdit,
            ...bankAccountBody
          }
          await updateBankListAfterEdit(bankToEditIndex, body)
        }
      } else {
        const body = {
          ...bankAccountBody,
          clientId: props.clientId
        }
        await addBankToList(body)
      }

      resetNewBankForm()
      isAddingBank.value = false
    }

    return {
      loading,
      bankOptions,
      isAddingBank,
      newBank,
      addBank,
      close,
      saveBank,
      editBankAccount,
      countries,
      selectedBank,
      selectedCountry
    }
  }
}
</script>

<style></style>
