import axios from 'axios'

import {
  authorizationHeaderInterceptor,
  forbiddenOrTokenExpiredInterceptor
} from './configureInterceptors'

const ApiName = {
  NOTIFICATIONS: 'notifications',
  NEWS: 'newsletters',
  MARKET_STATUSES: 'market_statuses',
  EXCHANGES: 'exchanges',
  DOCUSIGN: 'docusign',
  EXATO: 'exato'
}

class MsApiClient {
  constructor() {
    const { VITE_APP_URL_MS } = import.meta.env

    this.apiClient = axios.create({
      baseURL: `${VITE_APP_URL_MS}`
    })

    authorizationHeaderInterceptor(this.apiClient.interceptors.request)
    forbiddenOrTokenExpiredInterceptor(this.apiClient.interceptors.response)
  }

  get(apiName, resource, params = {}, additionalHeaders = {}, responseType = 'json') {
    const endpoint = resource ? `/${apiName}/${resource}` : `/${apiName}`
    return this.apiClient.get(endpoint, {
      params: params,
      headers: { ...additionalHeaders },
      responseType: responseType
    })
  }

  post(apiName, resource, data, additionalHeaders = {}) {
    const endpoint = resource ? `/${apiName}/${resource}` : `/${apiName}`
    return this.apiClient.post(endpoint, data, {
      headers: { ...additionalHeaders }
    })
  }

  patch(apiName, resource, data, additionalHeaders = {}) {
    const endpoint = resource ? `/${apiName}/${resource}` : `/${apiName}`
    return this.apiClient.patch(endpoint, data, {
      headers: { ...additionalHeaders }
    })
  }

  put(apiName, id, data) {
    const endpoint = id ? `/${apiName}/${id}` : `/${apiName}`
    return this.apiClient.put(endpoint, data)
  }

  delete(apiName, id) {
    const endpoint = `/${apiName}/${id}`
    return this.apiClient.delete(endpoint)
  }
}

const msApiClient = new MsApiClient()

export const createNotifications = (data) => {
  return msApiClient.post(ApiName.NOTIFICATIONS, 'push', data)
}

export const fetchNews = (config) => {
  return msApiClient.get(ApiName.NEWS, '', config)
}

export const createNews = (data) => {
  return msApiClient.post(ApiName.NEWS, '', data)
}

export const putUpdateNews = (id, data) => {
  return msApiClient.put(ApiName.NEWS, id, data)
}

export const deleteNews = (id) => {
  return msApiClient.delete(ApiName.NEWS, id)
}

export const updateAlterMarket = (status) => {
  return msApiClient.post(ApiName.MARKET_STATUSES, status)
}

export const uploadNewsletters = (formData) => {
  return msApiClient.post(ApiName.NEWS, 'upload?extension=.jpg', formData, {
    'Content-Type': 'application/octet-stream'
  })
}

export const updateMask = (currency, data) => {
  return msApiClient.patch(ApiName.MARKET_STATUSES, `masks/${currency}`, data)
}

export const updateVariation = (currency, data) => {
  return msApiClient.patch(ApiName.MARKET_STATUSES, `open_changes/${currency}`, data)
}

export const fetchExchangeRate = (currency) => {
  return msApiClient.get(ApiName.EXCHANGES, `${currency}`)
}

export const fetchEnvelopesDocusign = (id) => {
  return msApiClient.get(
    ApiName.DOCUSIGN,
    `envelopes/${id}`,
    {},
    {
      Accept: 'application/pdf'
    },
    'arraybuffer'
  )
}

export const fetchCertificateDocusign = (id) => {
  return msApiClient.get(
    ApiName.DOCUSIGN,
    `envelopes/${id}/certificate`,
    {},
    {
      Accept: 'application/pdf'
    },
    'arraybuffer'
  )
}

export const fetchExtractExatoCompany = (cnpj) => {
  return msApiClient.get(ApiName.EXATO, `company?cnpj=${cnpj}`)
}

export const fetchExtractExatoPerson = (cnpj) => {
  return msApiClient.get(ApiName.EXATO, `person?cpf=${cnpj}`)
}
