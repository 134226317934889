import { ref } from 'vue'

export const userStatus = () => {
  const userStatusType = ref({
    VALID: { name: 'Verificada', color: '#48cf93' },
    WAITING_VERIFICATION: { name: 'Em verificação', color: '#fd7132' },
    DISABLED: { name: 'Desativada', color: '#e24949' }
  })

  return {
    userStatusType
  }
}
