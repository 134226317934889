<script>
import { loadAssetWhiteLabel } from '@composables/utils/loadAsset.ts'
import { platformUrlForRole, isIncorrectUrlForRole } from '@composables/utils/useWhitelabelService'
import { useForm } from 'vee-validate'
import { onMounted, ref, provide } from 'vue'
import { useMeta } from 'vue-meta'
import { useRoute } from 'vue-router'
import { toast } from 'vue3-toastify'
import * as yup from 'yup'

import formInput from '@/components/form/input.vue'
import appImg from '@/components/shared/img.vue'
import appLoading from '@/components/shared/loading.vue'
import router from '@/router'
import { login, checkEmailStatus } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'
import { useAuthToken } from '@/stores/authToken.js'

export default {
  name: 'AppLogin',

  components: {
    formInput,
    appLoading,
    appImg
  },

  setup() {
    useMeta({ title: 'Login' })

    const authentication = useAuthToken()
    const route = useRoute()

    const { handleSubmit } = useForm({
      validationSchema: yup.object({
        email: yup.string().required().email().label('Email Invalido'),
        password: yup.string().required().min(6)
      })
    })

    const emailValue = ref('')
    const passwordValue = ref('')

    // Start Load images
    const images = Object.values(
      import.meta.glob('@assets/img/login/\*.jpg', {
        eager: true,
        query: '?url',
        import: 'default'
      })
    )

    const currentImageIndex = ref(0)
    const bgImg = ref('')
    const loading = ref(false)

    const changeBackgroundImage = () => {
      bgImg.value = images[currentImageIndex.value]
      currentImageIndex.value = (currentImageIndex.value + 1) % images.length
    }
    // End Load images

    const alreadySubmittedOnce = ref(false)
    provide('alreadySubmittedOnce', alreadySubmittedOnce)

    const doLoginAction = () => {
      alreadySubmittedOnce.value = true
      return doLogin()
    }

    const doLogin = handleSubmit(async () => {
      loading.value = true
      sessionStorage.clear()
      try {
        const emailValidation = await checkEmailStatus(emailValue.value.toLowerCase())
        const userRoles = emailValidation.data.payload.userRole
        const userInstitution = emailValidation.data.payload.institution
        const userIsActive = emailValidation.data.payload.isActive

        if (isIncorrectUrlForRole(userInstitution)) {
          toast(`Seu login pertence à plataforma ${platformUrlForRole(userInstitution)}`, {
            autoClose: 3000,
            theme: 'auto',
            type: 'warning'
          })

          setTimeout(() => {
            window.location.href = platformUrlForRole(userInstitution)
          }, 3000)
          return
        } else if (!userIsActive) {
          toast(
            `No momento você não tem acesso à plataforma ${platformUrlForRole(userInstitution)}`,
            {
              autoClose: 3000,
              theme: 'auto',
              type: 'warning'
            }
          )
          return
        }

        const body = {
          username: emailValue.value.toLowerCase(),
          password: passwordValue.value
        }
        const response = await login(body)

        const { name, email, institution, canOperate = false } = emailValidation.data.payload

        authentication.$patch({
          name: name,
          email: email,
          institution: institution,
          canOperate: canOperate,
          role: userRoles.find((v) => v.includes('ADMIN')),
          roles: userRoles.join(','),
          token: response.data.payload.token
        })

        const redirect = route.query.redirect || { name: 'Dashboard' }
        router.push(redirect)
      } catch (e) {
        handleGlobalError(e, 'ERR_LOGIN:')
      } finally {
        loading.value = false
      }
    })

    onMounted(() => {
      changeBackgroundImage()
      currentImageIndex.value + 1
      setInterval(changeBackgroundImage, 10000)

      if (process.env.VITE_APP_LOGIN) {
        emailValue.value = process.env.VITE_APP_LOGIN
        passwordValue.value = process.env.VITE_APP_PASSWORD
      }
    })

    return {
      emailValue,
      passwordValue,
      loadAssetWhiteLabel,
      bgImg,
      loading,
      doLoginAction
    }
  }
}
</script>

<template>
  <section class="container-fluid">
    <div class="row vh-100">
      <div
        class="d-none d-md-block col-md-7 banner"
        :style="{ backgroundImage: `url(${bgImg})` }"
      ></div>
      <div class="col-12 col-md-5 d-flex align-items-center">
        <div class="w-100">
          <div class="row">
            <div class="col-11 col-md-8 login">
              <div class="row">
                <app-img
                  class="img-responsive logo mb-3"
                  :src="loadAssetWhiteLabel('logo.svg')"
                  alt="logo"
                />
              </div>
              <div class="text-center">
                <h5><b>ADMIN</b></h5>
              </div>
              <form @submit.prevent="doLoginAction">
                <form-input
                  class="col-12 form-input mb-3"
                  label="E-mail"
                  v-model="emailValue"
                  id="email"
                  name="email"
                  type="email"
                  icon="bi bi-envelope-fill"
                />
                <form-input
                  class="col-12 form-input mb-3"
                  label="Senha"
                  id="password"
                  name="password"
                  type="password"
                  icon="bi bi-lock-fill"
                  v-model="passwordValue"
                />
                <div class="form-input">
                  <button data-test-id="login-button" class="secondary" :disabled="loading">
                    {{ !loading ? 'ACESSAR' : '' }}
                    <app-loading v-if="loading" :color="'#fff'" :duration="'2.3s'" />
                  </button>
                </div>
              </form>

              <router-link v-slot="{ navigate }" to="/login/recover" custom>
                <div
                  @click="navigate"
                  @keypress.enter="navigate"
                  class="col-12 text-center mt-4 mb-5"
                >
                  <p class="recovery-password">
                    <u>Esqueceu a senha?</u>
                  </p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.banner {
  background-color: #000000;
  background-image: url(@/assets/img/login/banner-1.jpg);
  @include background-responsive();

  transition: background-image 0.5s ease;
}

.login {
  margin-left: auto;
  margin-right: auto;

  img {
    max-height: 120px;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  .recovery-password {
    cursor: pointer;
    font-size: 12px;
  }
}
</style>
@/composables/utils/loadAsset
