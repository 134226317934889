import { ref, reactive } from 'vue'

import {
  taxesStatusOptions,
  clientTypesOptions,
  orderOptions
} from '@/composables/utils/useGlobalConfig'
import { useInstitution } from '@/composables/utils/useInstitution'
import { useOnboardingStatus } from '@/composables/utils/useOnboardingStatus'
import { useOperationPendency } from '@/composables/utils/useOperationPendency'
import { useOperationStatus } from '@/composables/utils/useOperationStatus'
import { useOperationType } from '@/composables/utils/useOperationType'
import { usePlatformLogs } from '@/composables/utils/usePlatformLogs'

export const useDynamicFilters = (filterSchema, externalRefs = {}) => {
  const models = [
    ...(filterSchema.inputFields?.map((field) => field.model) || []),
    ...(filterSchema.dateFields?.map((field) => field.model) || []),
    ...(filterSchema.selectFields?.map((field) => field.model) || [])
  ]

  const keys = Object.fromEntries(models.map((model) => [model, externalRefs[model] || ref('')]))

  const formValues = reactive(Object.fromEntries(models.map((model) => [model, keys[model]])))

  const updateFormValues = ({ model, value }) => {
    if (keys[model]) {
      keys[model].value = value
    }
  }

  return { keys, formValues, updateFormValues }
}

export const getTargetTaxFilters = () => {
  const { fetchOperationTypeOptions } = useOperationType()
  const operationOptions = fetchOperationTypeOptions()
  const statusOptions = taxesStatusOptions.value

  return {
    inputFields: [
      {
        model: 'searchValue',
        props: {
          label: 'Cliente',
          id: 'search',
          name: 'search',
          type: 'search',
          icon: 'bi bi-search',
          placeholder: 'Nome do cliente',
          autocomplete: 'off'
        }
      }
    ],
    selectFields: [
      {
        model: 'natureTypeValue',
        props: {
          label: 'Tipo de operação',
          id: 'operation',
          name: 'operation'
        },
        items: operationOptions
      },
      {
        model: 'statusValue',
        props: {
          label: 'Status',
          id: 'status',
          name: 'status'
        },
        items: statusOptions
      }
    ],
    dateFields: [
      {
        model: 'expiresAtValue',
        props: {
          label: 'Expiração',
          id: 'expiresAt',
          name: 'expiresAt',
          icon: 'bi bi-calendar-week'
        }
      }
    ]
  }
}

export const getPreOperationFilters = () => {
  const { fetchOperationTypeOptions } = useOperationType()
  const { fetchStatusOptions } = useOperationStatus()

  const operationOptions = fetchOperationTypeOptions()
  const statusOptions = fetchStatusOptions()

  return {
    inputFields: [
      {
        model: 'searchValue',
        props: {
          label: 'Cliente',
          id: 'search',
          name: 'search',
          type: 'search',
          icon: 'bi bi-search',
          placeholder: 'Nome do cliente',
          autocomplete: 'off'
        }
      }
    ],
    selectFields: [
      {
        model: 'natureTypeValue',
        props: {
          label: 'Tipo de operação',
          id: 'operation',
          name: 'operation'
        },
        items: operationOptions
      },
      {
        model: 'statusValue',
        props: {
          label: 'Status',
          id: 'status',
          name: 'status'
        },
        items: statusOptions
      }
    ],
    dateFields: [
      {
        model: 'createdValue',
        props: {
          label: 'Criação',
          id: 'created',
          name: 'created',
          icon: 'bi bi-calendar-week'
        }
      }
    ]
  }
}

export const getPosOperationFilters = () => {
  const { fetchOperationTypeOptions } = useOperationType()
  const { fetchPendencyOptions } = useOperationPendency()

  const operationOptions = fetchOperationTypeOptions()
  const pendencyOptions = fetchPendencyOptions()

  return {
    inputFields: [
      {
        model: 'searchValue',
        props: {
          label: 'Cliente',
          id: 'search',
          name: 'search',
          type: 'search',
          icon: 'bi bi-search',
          placeholder: 'Nome do cliente',
          autocomplete: 'off'
        }
      }
    ],
    selectFields: [
      {
        model: 'natureTypeValue',
        props: {
          label: 'Tipo de operação',
          id: 'operation',
          name: 'operation'
        },
        items: operationOptions
      },
      {
        model: 'pendencyValue',
        props: {
          label: 'Pendência',
          id: 'status',
          name: 'status'
        },
        items: pendencyOptions
      }
    ],
    dateFields: [
      {
        model: 'closingValue',
        props: {
          label: 'Data de fechamento',
          id: 'closing',
          name: 'closing',
          icon: 'bi bi-calendar-week'
        }
      }
    ]
  }
}

export const getCompletedOperationFilters = () => {
  const { fetchOperationTypeOptions } = useOperationType()

  const operationOptions = fetchOperationTypeOptions()

  return {
    inputFields: [
      {
        model: 'searchValue',
        props: {
          label: 'Cliente',
          id: 'search',
          name: 'search',
          type: 'search',
          icon: 'bi bi-search',
          placeholder: 'Nome do cliente',
          autocomplete: 'off'
        }
      }
    ],
    selectFields: [
      {
        model: 'natureTypeValue',
        props: {
          label: 'Tipo de operação',
          id: 'operation',
          name: 'operation'
        },
        items: operationOptions
      }
    ],
    dateFields: [
      {
        model: 'closingValue',
        props: {
          label: 'Data de fechamento',
          id: 'closing',
          name: 'closing',
          icon: 'bi bi-calendar-week'
        }
      }
    ]
  }
}

export const getClientFilters = () => {
  const { fetchInstitutionOptions } = useInstitution()
  const institutionOptions = fetchInstitutionOptions({ includeAll: true })
  const { fetchOnboardingStatusOptions } = useOnboardingStatus()
  const onboardingStatusOptions = fetchOnboardingStatusOptions()

  return {
    inputFields: [
      {
        model: 'searchValue',
        props: {
          label: 'Cliente',
          id: 'search',
          name: 'search',
          type: 'search',
          icon: 'bi bi-search',
          placeholder: 'Nome do cliente',
          autocomplete: 'off'
        }
      },
      {
        model: 'documentIdValue',
        props: {
          label: 'CNPJ ou CPF',
          id: 'documentId',
          name: 'documentId',
          type: 'search',
          icon: 'bi bi-search',
          autocomplete: 'off'
        }
      }
    ],
    dateFields: [
      {
        model: 'openingValue',
        props: {
          label: 'Data de abertura',
          id: 'opening',
          name: 'opening',
          icon: 'bi bi-calendar-week'
        }
      },
      {
        model: 'lastClosedOperationNewerThanValue',
        props: {
          label: 'Último fechamento',
          id: 'lastClosedOperationNewerThan',
          name: 'lastClosedOperationNewerThan',
          icon: 'bi bi-calendar-week'
        }
      }
    ],
    selectFields: [
      {
        model: 'clientTypeValue',
        props: {
          label: 'Tipo',
          id: 'clientType',
          name: 'clientType'
        },
        items: clientTypesOptions.value
      },
      {
        model: 'institutionValue',
        props: {
          label: 'Instituição',
          id: 'institution',
          name: 'institution'
        },
        items: institutionOptions
      },
      {
        model: 'onboardingStatusValue',
        props: {
          label: 'Status',
          id: 'onboardingStatus',
          name: 'onboardingStatus'
        },
        items: onboardingStatusOptions
      },
      {
        model: 'orderValue',
        props: {
          label: 'Ordenar',
          id: 'orderBy',
          name: 'orderBy'
        },
        items: orderOptions.value
      }
    ]
  }
}

export const getPlatformLogsFilters = () => {
  const { fetchLogsOptions } = usePlatformLogs()
  const logsOptions = fetchLogsOptions()

  return {
    inputFields: [
      {
        model: 'searchValue',
        props: {
          label: 'Cliente',
          id: 'search',
          name: 'search',
          type: 'search',
          icon: 'bi bi-search',
          placeholder: 'Nome do cliente',
          autocomplete: 'off'
        }
      }
    ],
    dateFields: [
      {
        model: 'createdValue',
        props: {
          label: 'Data de criação',
          id: 'created',
          name: 'created',
          icon: 'bi bi-calendar-week'
        }
      }
    ],
    selectFields: [
      {
        model: 'requestValue',
        props: {
          label: 'Ação',
          id: 'request',
          name: 'request'
        },
        items: logsOptions
      }
    ]
  }
}

export const getUsersFilters = () => {
  return {
    inputFields: [
      {
        model: 'searchValue',
        props: {
          label: 'Administrador',
          id: 'search',
          name: 'search',
          type: 'search',
          icon: 'bi bi-search',
          placeholder: 'Nome do administrador',
          autocomplete: 'off'
        }
      }
    ]
  }
}
