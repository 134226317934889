import { ref, onMounted, watch } from 'vue'

import { useNoScroll } from '@/composables/utils/useNoScroll'

export default function useModal(emit) {
  const contentActive = ref(false)
  const scroll = ref(false)
  const { setNoScroll } = useNoScroll()

  const openModal = () => {
    scroll.value = true
    setTimeout(() => {
      contentActive.value = true
    }, 100)
  }

  const closeModal = () => {
    contentActive.value = false
    scroll.value = false
    setTimeout(() => {
      emit('onClose', false)
    }, 300)
  }

  watch(scroll, (newValue) => {
    setNoScroll(newValue)
  })

  onMounted(() => {
    openModal()
  })

  return {
    contentActive,
    openModal,
    closeModal
  }
}
