export const configClientMoney = {
  thousands: '.',
  decimal: ',',
  precision: 2
}

export const configCommercialMoney = {
  prefix: 'R$ ',
  thousands: '.',
  decimal: ',',
  precision: 4
}

export const configMoney = {
  prefix: 'R$ ',
  thousands: '.',
  decimal: ',',
  precision: 2
}

export const configIof = {
  suffix: '%',
  thousands: '.',
  decimal: ',',
  precision: 2
}

export const configIr = {
  suffix: '%',
  thousands: '.',
  decimal: ',',
  precision: 4
}

export const configPromo = {
  suffix: '%',
  thousands: '.',
  decimal: ',',
  precision: 0
}

export const moneyFormat = (suffix, thousands, decimal, precision) => ({
  prefix: suffix,
  thousands: thousands,
  decimal: decimal,
  precision: precision
})
