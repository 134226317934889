<script>
import { useForm } from 'vee-validate'
import { ref } from 'vue'
import { useMeta } from 'vue-meta'
import * as yup from 'yup'

import formInput from '@/components/form/input.vue'
import formSelect from '@/components/form/select.vue'
import formTextarea from '@/components/form/textarea.vue'
import appLoading from '@/components/shared/loading.vue'
import { useTopicNotifications } from '@/composables/utils/useTopicNotifications'
import { handleGlobalError } from '@/services/errorHandler'
import { createNotifications } from '@/services/ms'

export default {
  name: 'PushNotification',

  components: {
    formInput,
    formTextarea,
    formSelect,
    appLoading
  },

  setup() {
    useMeta({ title: 'Push notification' })

    const titleValue = ref('')
    const contentValue = ref('')
    const topicValue = ref('')
    const loading = ref(false)
    const alreadySubmittedOnce = ref(false)

    const { fetchTopicNotificationsOptions } = useTopicNotifications()

    const topicOptions = fetchTopicNotificationsOptions()

    const { handleSubmit } = useForm({
      validationSchema: yup.object({
        titulo: yup.string().required().max(26),
        message: yup.string().required(),
        topic: yup.string().required()
      })
    })

    const doSendNotifications = () => {
      alreadySubmittedOnce.value = true
      return doNotifications()
    }

    const doNotifications = handleSubmit(async () => {
      loading.value = true
      try {
        const data = {
          title: titleValue.value,
          message: contentValue.value,
          topic: topicValue.value
        }
        await createNotifications(data)
      } catch (e) {
        handleGlobalError('Erro Send Notifications:', e)
      } finally {
        loading.value = false
      }
    })

    return {
      titleValue,
      contentValue,
      topicValue,
      topicOptions,
      loading,
      doSendNotifications,

      alreadySubmittedOnce
    }
  }
}
</script>

<template>
  <div>
    <div class="row title">
      <h2 class="col">Push notification</h2>
    </div>

    <div class="row pb-5 mt-3">
      <div class="col-12">
        <form-input
          class="col form-input"
          label="Titulo da notificação"
          v-model="titleValue"
          id="text"
          name="titulo"
          type="text"
          autocomplete="off"
          :alreadySubmittedOnce="alreadySubmittedOnce"
        />
        <form-textarea
          class="col-12 form-input"
          label="Texto de sua notificação"
          v-model="contentValue"
          name="message"
          :alreadySubmittedOnce="alreadySubmittedOnce"
        />
        <form-select
          class="col form-input"
          label="Canal a ser enviado"
          v-model="topicValue"
          :items="topicOptions"
          id="topic"
          name="topic"
          :alreadySubmittedOnce="alreadySubmittedOnce"
        />

        <div class="col-12 col-md-3 form-input">
          <button class="secondary" :isDisabled="loading" @click="doSendNotifications">
            {{ !loading ? 'Enviar' : '' }}
            <app-loading v-if="loading" :color="'#fff'" :duration="'2.3s'" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
