import { onMounted, onUnmounted } from 'vue'

let isEventRegistered = false
const callbacks = []

const globalHandleScroll = () => {
  callbacks.forEach((callback) => callback())
}

export const useInfiniteScroll = (callback) => {
  const isScrolledToBottom = () => {
    const scrollY = window.scrollY
    const viewportHeight = window.innerHeight
    const scrollHeight = document.documentElement.scrollHeight

    return scrollY + viewportHeight >= scrollHeight - 100
  }

  const handleScroll = () => {
    if (isScrolledToBottom()) {
      callback()
    }
  }

  if (!callbacks.includes(handleScroll)) {
    callbacks.push(handleScroll)
  }

  onMounted(() => {
    if (typeof window !== 'undefined' && !isEventRegistered) {
      window.addEventListener('scroll', globalHandleScroll)
      isEventRegistered = true
    }
  })

  onUnmounted(() => {
    const index = callbacks.indexOf(handleScroll)
    if (index !== -1) {
      callbacks.splice(index, 1)
    }
    if (callbacks.length === 0) {
      window.removeEventListener('scroll', globalHandleScroll)
      isEventRegistered = false
    }
  })
}
