<script>
import { ref } from 'vue'
import { useMeta } from 'vue-meta'

import appFilterSection from '@/components/shared/filterSection.vue'
import appTableSection from '@/components/shared/tableSection.vue'
import { useDynamicFilters, getPlatformLogsFilters } from '@/composables/utils/useFilters'
import { usePaginatedResult } from '@/composables/utils/usePaginatedResult'
import { useSearchThrottle } from '@/composables/utils/useSearch'
import router from '@/router'
import { fetchPlatformLogs } from '@/services/api'

export default {
  name: 'PlatformLogs',

  components: {
    appFilterSection,
    appTableSection
  },

  props: {
    search: String,
    createdAt: String,
    request: String
  },

  setup(props) {
    useMeta({ title: 'PlatformLogs' })

    const searchValue = ref(props.search)
    const createdValue = ref(props.createdAt)
    const requestValue = ref(props.request)

    const valuesToWatch = [createdValue, requestValue]

    useSearchThrottle(searchValue, () => {
      resetAndUpdateResult(true)
    })

    const tableHeaders = [
      { key: 'clientName', label: 'Empresa', type: 'text' },
      {
        key: 'createdAt',
        label: 'Efetuado em',
        type: 'date',
        format: 'dd/MM/yyyy HH:mm',
        timeZoneOffset: -3
      },
      { key: 'userName', label: 'Usuário', type: 'text' },
      { key: 'request', label: 'Ação', type: 'logsType' }
    ]

    const fetchFunction = (config) => fetchPlatformLogs(config)

    const resetAndUpdateResult = (shouldReset) => {
      if (shouldReset) resetResult()

      const config = {
        page: currentPage.value,
        search: searchValue.value,
        createdAt: createdValue.value,
        request: requestValue.value
      }
      router.push({ name: 'PlatformLogs', query: { ...config, page: undefined } })
      fetchResult(config)
    }

    const {
      resultItems: platformLogs,
      loading,
      isLastPage,
      fetchResult,
      resetResult,
      currentPage
    } = usePaginatedResult(fetchFunction, resetAndUpdateResult, valuesToWatch)

    const filterSchema = getPlatformLogsFilters()
    const externalRefs = { searchValue, createdValue, requestValue }
    const { formValues, updateFormValues } = useDynamicFilters(filterSchema, externalRefs)

    return {
      tableHeaders,
      platformLogs,
      loading,

      isLastPage,

      filterSchema,
      formValues,
      updateFormValues
    }
  }
}
</script>

<template>
  <div>
    <div class="row title">
      <h2 class="col">PlatformLogs</h2>
    </div>

    <app-filter-section
      :filterSchema="filterSchema"
      :formValues="formValues"
      @update:formValues="updateFormValues"
    />

    <app-table-section
      :items="platformLogs"
      :headers="tableHeaders"
      :loading="loading"
      :isLastPage="isLastPage && !loading"
    />
  </div>
</template>
