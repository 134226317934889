<script>
import { ref, watch } from 'vue'

import formInput from '@/components/form/input.vue'

export default {
  name: 'appSearch',

  components: {
    formInput
  },
  props: {
    results: {
      type: Array,
      default: () => []
    },
    className: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'off'
    }
  },
  setup(props, { emit }) {
    const internalValue = ref(props.modelValue)
    const searchResults = ref([])
    const isInputFocused = ref(false)

    watch(
      () => props.results,
      (newResults) => {
        searchResults.value = newResults
      }
    )

    watch(
      () => props.modelValue,
      (newValue) => {
        internalValue.value = newValue
      }
    )

    watch(internalValue, (newValue) => {
      if (newValue.length >= 3) {
        emit('update:modelValue', newValue)
      } else {
        searchResults.value = []
        if (newValue.length > 0) {
          emit('update:modelValue', newValue)
        }
      }
    })

    const submitValue = (data) => {
      isInputFocused.value = false
      searchResults.value = []
      emit('onSelect', data)
    }

    return {
      isInputFocused,
      internalValue,
      searchResults,
      submitValue
    }
  }
}
</script>

<template>
  <form-input
    :className="className"
    :label="label"
    v-model="internalValue"
    :id="id"
    :name="name"
    :type="type"
    :icon="icon"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    :is-invalid="isInvalid"
    @focus="isInputFocused = true"
    @blur="isInputFocused = false"
    :isDisabled="isDisabled"
  >
    <div
      v-if="searchResults && searchResults.length && isInputFocused && !isDisabled"
      class="search-results"
    >
      <div v-for="item in searchResults" :key="item.id" @click="submitValue(item)">
        <div class="col-12">
          <p class="name mb-0">
            {{ item.clientName }}
            <span class="badge bg-secondary float-end">
              {{ item.clientType }}
            </span>
          </p>
          <p class="phone mb-0">
            {{ item.phone }}
          </p>
        </div>
      </div>
    </div>
  </form-input>
</template>

<style lang="scss">
.search-results {
  background-color: #fff;
  color: black;
  border-radius: 9px;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;

  > div {
    padding: 10px 10px;
    cursor: pointer;

    &:nth-child(even) {
      background-color: #e7e7e780;
    }
  }

  .name {
    color: black;
    font-size: 16px;
    font-weight: 600;
  }

  .phone {
    color: rgb(43, 43, 43);
    font-size: 12px;
    font-weight: 500;
    margin-top: -6px;
  }
}
</style>
