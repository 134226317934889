<script>
import { Tooltip } from 'bootstrap'
import { useField } from 'vee-validate'
import { ref, onMounted, inject } from 'vue'

export default {
  name: 'appInput',
  props: {
    className: {
      type: String,
      default: 'form-input'
    },
    label: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    rules: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const showPassword = ref(false)
    const inputType = ref(props.type)

    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value
      inputType.value = showPassword.value ? 'text' : 'password'
    }

    const { value, errorMessage, handleChange } = useField(() => props.name, props.rules, {
      syncVModel: true,
      validateOnValueUpdate: false
    })

    const alreadySubmittedOnce = inject('alreadySubmittedOnce', ref(false))

    const handleChangeValidation = (value) => {
      handleChange(value, alreadySubmittedOnce.value)
    }

    const handleFocus = () => {
      emit('focus')
    }

    const handleBlur = () => {
      emit('blur')
    }

    onMounted(() => {
      new Tooltip(document.body, {
        selector: '[data-bs-toggle="tooltip"]'
      })
    })

    return {
      showPassword,
      value,
      inputType,
      togglePasswordVisibility,
      handleChangeValidation,
      errorMessage,
      handleFocus,
      handleBlur
    }
  }
}
</script>

<template>
  <div @mouseover="handleFocus" @mouseleave="handleBlur" :class="className">
    <label :for="id">
      {{ label }}
      <span v-if="tooltip" data-bs-toggle="tooltip" data-bs-placement="left" :title="tooltip">
        <i class="bi bi-info-square-fill"></i>
      </span>
    </label>
    <i v-if="icon" :class="icon"></i>
    <input
      :id="id"
      :name="name"
      :type="inputType"
      v-model="value"
      @input="handleChangeValidation"
      :placeholder="placeholder"
      :disabled="isDisabled"
      :class="{ invalid: isInvalid || !!errorMessage }"
      :autocomplete="autocomplete"
    />
    <i
      v-if="type === 'password'"
      class="toggle-icon-password bi bi-eye-fill"
      :class="showPassword ? `bi-eye-fill` : `bi-eye-slash-fill`"
      @click="togglePasswordVisibility"
    ></i>
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.toggle-icon-password {
  &.bi {
    cursor: pointer;
    float: right;
    position: relative;
    font-size: 17px;
    padding-right: 12px;
    margin-top: -52px;
  }
}
</style>
